import { Event } from "../Event";

export class AsoControlEvents extends Event {
    public onLoad = () => {
        const functionary = this.values?.aso_control_fk_user_id

        if (functionary) {
            this.setState("loadings", ["aso_control_fk_user_id"])

            this.setField("aso_control_fk_user_id", {
                readonly: false
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }
    }
}