import { Event } from "../Event";

export class BaseFixedHourExtraEvents extends Event {

public onChange = (value: any, setField: any, setState: any, formValues: any) => { 
    
    this.setState("loadings", ["base_amount_hour"])

    if (value) {
        this.setField("base_amount_hour", {hidden: false})
        this.setFieldValue("base_amount_hour", "30:00:00");
    } else {
        this.setField("base_amount_hour", {hidden: true})
        this.setFieldValue("base_amount_hour", "00:00:00");
    }
    
    setTimeout(() => {
        this.setState("loadings", [])
      }, 100)
};    
    
}
