import { Event } from "../Event";

export class ParameterImportEvents extends Event {
    public onLoad = () => {
        const url = this.globalProps.match.url

        //Caso não seja add
        if (!url.includes("/add")) {
            this.setState("loadings", ["parameter_import_fk_base_id"])

            this.setField("parameter_import_fk_base_id", {
                readonly: false
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }
    }
}