import { Event } from "../Event";

export class JobRequestFkBaseIdEvents extends Event {
    // public onChange = (value: any, setField: any, setState: any, formValues: any) => {

    //     const companyValue = this.values?.job_request_fk_company_id

    //     if(companyValue) {
    //         if(value) {
    //             this.setState("loadings", ["job_request_fk_user_id"])

    //             this.setField("job_request_fk_user_id", {
    //                 readonly: false
    //             })

    //             this.setFieldValue("job_request_fk_user_id", "")

    //             setTimeout(() => {
    //                 this.setState("loadings", [])
    //             }, 250)
    //         }
    //     }
    // }
}