import axios from "./../../utilities/axios";
import axioscep from "axios";
import { Event } from "../Event";

export class CompanyZipCodeEvents extends Event {
    public onBlur = async (value: any, setField: any, setState: any) => {

        function RemoveChars(string: string): String {
            let normalizedString = string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            const resultado: String = normalizedString.replace(/'/g, ' ');
            return resultado;
        }

        if (value) {
            setState("loadings", ["company_street", "company_district", "company_fk_state_id", "company_fk_city_id"])

            let addressData: any;
            let labelValue: any;

            await axioscep.get("https://viacep.com.br/ws/" + value + "/json/")
                .then((data: any) => {
                    addressData = data.data;
                    this.setFieldValue("company_street", addressData.logradouro)
                    this.setFieldValue("company_district", addressData.bairro)
                    return addressData;
                }).catch((err: any) => {
                    console.log("Valor do erro :::>> ", err);
                    throw err; // Propagar o erro para o próximo bloco de captura
                }).then(async () => {
                    if (addressData) {
                        await axios.get("/api/v1/states/select", {
                            params: {
                                state: addressData.uf
                            }
                        })
                            .then((res: any) => {

                                const { items } = res.data;
                                const { label, value } = items[0];

                                this.setFieldValue("company_fk_state_id", { label: label, value: value })

                                labelValue = {
                                    label: label,
                                    value: value
                                };
                            }).catch((err: any) => {
                                console.log("Erro ao buscar estado :::>> ", err);
                            });
                    }
                }).catch((err: any) => {
                    // Tratamento de erro para a segunda chamada
                    console.log("Erro no processamento final :::>> ", err);
                }).finally(async () => {
                    await axios.get("/api/v1/cities/select", {
                        params: {
                            search: labelValue.label,
                            state: labelValue.value
                        }
                    }).then((res: any) => {
                        const { items } = res.data
                        const { label, value } = items[0]

                        this.setFieldValue("company_fk_city_id", { label: label, value: value })
                    }).finally(() => {
                        setState("loadings", [])
                    })

                    setTimeout(() => {
                        this.setState("loadings", [])
                    }, 100)
                });


        }
    };
}
