import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Event } from "../Event";

// Configuração do dayjs com os plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export class EntryExpenseReleasedFinalDateEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings', [
            'entry_expense_released_quantity_days'
        ]);

        const initialDate = this.values.entry_expense_released_initial_date;
        const finalDate = value;

        // Verifica se as datas são válidas
        if (!dayjs(initialDate).isValid() || !dayjs(finalDate).isValid()) {
            this.setState('loadings', []);
            return;
        }

        // Converter as datas para o fuso horário do Brasil e calcular a diferença em dias
        const initialDay = dayjs(initialDate).tz('America/Sao_Paulo').startOf('day');
        const finalDay = dayjs(finalDate).tz('America/Sao_Paulo').startOf('day');

        const diffDays = finalDay.diff(initialDay, 'day');

        if (diffDays == 1) {
            this.setFieldValue('entry_expense_released_quantity_days', `${diffDays} dia`);
        } else {
            this.setFieldValue('entry_expense_released_quantity_days', `${diffDays} dias`);
        }

        this.setState('loadings', []);
    }
}
