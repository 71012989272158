import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import ModulesPage from "../ModulesPage";
import DefaultDashboardPage from "../DefaultDashboardPage";
import ChecklistPage from "../checklist";
import ListChecklistsPage from "../checklist/pages/listChecklistsPage";
import RegisterChecklistPage from "../checklist/pages/registerChecklistPage";
import AnswerQuestions from "../checklist/pages/answerQuestions";

export default function ChecklistRoutes(props: any) {
    let { path } = useRouteMatch();
    // let { module }: any = useParams();
    let location = useLocation();

    return (
        <div>
            <Switch location={location}>
                <Route exact path={path} component={() => <ChecklistPage />}></Route>
                <Route exact path={path + "/list"} component={ListChecklistsPage}></Route>
                <Route exact path={path + "/register"} component={RegisterChecklistPage}></Route>
                <Route exact path={path + "/answer"} component={AnswerQuestions}></Route>
            </Switch>
        </div>
    );
}
