import React from "react";
import { withRouter } from "react-router-dom";

import { Button, Dropdown, Loader, Panel } from "rsuite";
import axios from "../../utilities/axios";
import { ModuleContext } from "../authProvider/main";
import { Widget } from "./Widget";
// import Favorites from "./widgets/Favorites";
import { WidgetsModal } from "./WidgetsModal";
import * as DashboardCustomView from './dashboardsViews/DashboardHolder';
import _ from "lodash";

class DashboardCustomization extends React.Component<any, {}> {
    static contextType = ModuleContext;

    public state: any = {
        widgets: [],
        module: this.props.module,
        selectedWidget: -1,
        currentWidget: null,
        editingWidgets: false,
        loading: true,
    };

    onToggleEditing = () => {
        var editingWidgets = !this.state.editingWidgets;
        if (editingWidgets === false) {
            var moduleName = this.props.module;
            var widgets = this.state.widgets.map((item: any, index: any) => {
                item.id = index;
                return item;
            });
            axios
                .post("/api/v1/me/" + moduleName + "-widgets", {
                    value: widgets,
                })
                .then(function (res) { })
                .finally(() => { })
                .catch((e) => { });
        }
        this.setState({ editingWidgets });
    };

    getWidgets() {
        var moduleName = this.props.module;
        this.setState({ loading: true });
        console.log("moduleName :>", moduleName)
        if (moduleName !== null) {
            axios
                .get("/api/v1/me/" + moduleName + "-widgets")
                .then((res) => {
                    if (res.data.status === 200) {
                        this.setState({ loading: false, widgets: res.data.value.user_setting_value });
                    }
                })
                .catch(() => {
                    this.setState({ loading: false, widgets: [] });
                });
        }
    }

    componentDidMount() {
        this.getWidgets();
    }

    shouldComponentUpdate(prevProps: any) {
        if (this.props.module !== prevProps.module) {
            // //
            this.setState({ module: prevProps.module }, () => {
                this.getWidgets();
            });
        }
        return true;
    }

    onEdit = (index: any) => {
        // alert(index);
        this.setState({ selectedWidget: index, currentWidget: this.state.widgets[index] });
    };

    onDelete = (index: any) => {
        var widgets = this.state.widgets;
        widgets.splice(index, 1);
        this.setState({ widgets });
    };

    addWidget = (widgetName: string) => {
        var widgets = this.state.widgets;
        switch (widgetName) {
            case "Favoritos":
                var widget = { type: "Favorites", items: [] };
                widgets.push(widget);
                this.setState({ widgets });
                break;
            case "ClockInPsm":
                var widget = { type: "ClockInPsm", items: [] };
                widgets.push(widget);
                this.setState({ widgets });
                break;
        }
    };

    onConfirmModal = (values: any) => {
        const widgets = this.state.widgets;
        const widget = { ...widgets[this.state.selectedWidget], ...values };
        widgets[this.state.selectedWidget] = widget;
        var moduleName = this.context.name;
        axios
            .post("/api/v1/me/" + moduleName + "-widgets", {
                value: widgets,
            })
            .then(function (res) { })
            .finally(() => { })
            .catch((e) => { });
        this.setState({ selectedWidget: -1, widgets, currentWidget: null });
    };

    onCancelModal = () => {
        this.setState({ selectedWidget: -1, currentWidget: null });
    };

    renderCustomView = () => {
        const customNameComponent = `${_.upperFirst(this.props.module)}Dashboard`;
        const viewHolder: any = DashboardCustomView;
        const view = viewHolder[customNameComponent];
        if(view) {
            return (
                <Panel bordered style={{backgroundColor: '#fff'}}>
                    {React.createElement(view, {
                        ...this.props
                    })}
                </Panel>
            )
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="row">
                    <div className="col-md-12">
                        <Panel bordered style={{ backgroundColor: "#fff", height: 135 }} className="rs-no-body d-flex justify-content-center allow-overflow" expanded>
                            <Loader size={"lg"} content="Carregando..." vertical></Loader>
                        </Panel>
                    </div>
                </div>
            );
        }
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <Panel bordered style={{ backgroundColor: "#fff" }} className="rs-no-body allow-overflow" expanded>
                            {/* Dashboard */}
                            <div className="d-flex justify-contents-between align-items-center">
                                <div>
                                    <h5>Dashboard</h5>
                                </div>
                                <div>{this.props.children}</div>
                                <div>
                                    {this.state.editingWidgets === true && (
                                        <Dropdown color={"green"} appearance="default" className="mr-4" icon={<i className="fas fa-plus"></i>}>
                                            <Dropdown.Item onSelect={() => this.addWidget("Favoritos")}>Favoritos</Dropdown.Item>
                                            {/* <Dropdown.Item onSelect={() => this.addWidget("ClockInPsm")}>Controle de ponto</Dropdown.Item> */}
                                        </Dropdown>
                                    )}



                                    <Button onClick={this.onToggleEditing} color={this.state.editingWidgets ? "yellow" : undefined}>
                                        <i className="fas fa-cog"></i>
                                    </Button>
                                </div>
                            </div>
                        </Panel>
                    </div>
                </div>
                <div className="row no-margin">
                    {this.state.widgets.map((item: any, index: any) => {
                        return (
                            <Widget
                                active={this.state.selectedWidget === index}
                                key={index}
                                id={index}
                                onEdit={this.onEdit}
                                onDelete={() => this.onDelete(index)}
                                isEditing={this.state.editingWidgets}
                                widget={item}
                            />
                        );
                    })}
                </div>
                
                {this.renderCustomView()}
                <WidgetsModal
                    module={this.props.module}
                    values={this.state.currentWidget}
                    type={this.state.currentWidget?.type ?? undefined}
                    onCancel={this.onCancelModal}
                    onConfirm={this.onConfirmModal}
                    show={this.state.currentWidget !== null}
                />
            </>
        );
    }
}

export default withRouter(DashboardCustomization);
