import { Event } from "../Event";

export class UserHistoricalTimeBanksEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState("loadings", ["user_total_balance_hour"])

        let totalMinutos = 0;

        value.forEach((horaStr: any) => {
            const horas = parseInt(horaStr?.user_historical_time_bank_hour.slice(0, 2), 10);
            const minutos = parseInt(horaStr?.user_historical_time_bank_hour.slice(2, 4), 10);

            totalMinutos += horas * 60 + minutos;
        });

        const totalHoras = Math.floor(totalMinutos / 60);
        const totalMinutosRestantes = totalMinutos % 60;

        const resultado = `${totalHoras.toString().padStart(3, '0')}:${totalMinutosRestantes.toString().padStart(2, '0')}`;

        this.setFieldValue("user_total_balance_hour", resultado)

        setTimeout(() => {
            this.setState("loadings", [])
        }, 250)
    }
}