import * as axiosRoot from 'axios';
import config from '../globals';


let axiosExternal = axiosRoot.default.create({
    baseURL: config.url,
    timeout: 10000,
    headers: { "x-requested-with": "XMLHttpRequest" },
})

axiosExternal.interceptors.request.use((val: axiosRoot.AxiosRequestConfig) => {
    const token = localStorage.getItem('admissionCode');
    if (token) {
        val.headers.authorization = `bearer ${token}`;
        return val;
    } else {
        throw new axiosRoot.default.Cancel("Operation canceled by the user.");
    }
}, (err) => {
    return Promise.reject(err)
})



axiosExternal.interceptors.response.use((res: axiosRoot.AxiosResponse) => {
    return res
})


export default axiosExternal;