import React from "react";
import styles from "./styles.module.scss";
import { withRouter } from "react-router";
import classNames from "classnames";
import { Button, CheckPicker, DateRangePicker, Divider, Panel, PanelGroup, Notification } from "rsuite";
import axios from "../../utilities/axios";
import dayjs from "dayjs";

class DataInsightsPage extends React.Component<any, {}> {
    public state: any = {
        employeeListings: [],
        platformListings: [],
        storeListings: [],
        loadingButton: false,
        period: [
            dayjs().startOf('month').toDate(),
            dayjs().endOf('month').toDate(),
        ],
        selectedEmployees: null,
        selectedPlatforms: null,
        selectedStores: null
    }

    async componentDidMount(): Promise<void> {
        try {
            const response = await axios.get("/api/v1/dashboard/get_informations");

            if (response?.data) {
                const formattedUsers = Array.isArray(response.data.users)
                    ? response.data.users.map((user: any) => ({
                        value: user?.user_id || '',
                        label: user?.user_name || 'Unknown User',
                    }))
                    : [];

                const formattedPlatforms = Array.isArray(response.data.platforms)
                    ? response.data.platforms.map((platform: any) => ({
                        value: platform?.platform_id || '',
                        label: platform?.platform_name || 'Unknown Platform',
                    }))
                    : [];

                const formattedStores = Array.isArray(response.data.stores)
                    ? response.data.stores.map((store: any) => ({
                        value: store?.store_id || '',
                        label: store?.store_name || 'Unknown Store',
                    }))
                    : [];

                this.setState({
                    employeeListings: formattedUsers,
                    platformListings: formattedPlatforms,
                    storeListings: formattedStores,
                });
            } else {
                console.warn("Response data is empty or undefined.");
                this.setState({
                    employeeListings: [],
                    platformListings: [],
                    storeListings: [],
                });
            }
        } catch (error) {
            console.error("Error fetching dashboard information:", error);
            this.setState({
                employeeListings: [],
                platformListings: [],
                storeListings: [],
            });
        }
    }


    async handleGenerateReport(type: string) {
        this.setState({
            loadingButton: true
        })

        const { period, selectedEmployees, selectedPlatforms, selectedStores } = this.state;

        if (period.length === 0) {
            Notification.error({
                title: "Erro!",
                description: "Preencha um período para a geração do relatório",
                duration: 3500
            })

            this.setState({
                loadingButton: false,
            });

            return;
        }

        if (type === 'employee') {
            const response = await axios.post("/api/v1/reports/generate_report_analytics", {
                type: 'employee',
                period: period,
                employees: selectedEmployees,
                stores: null,
                platform: null
            })

            window.open('https://' + window.location.host.split('25565').join('25567') + response.data)
        }

        if (type === 'store') {
            const response = await axios.post("/api/v1/reports/generate_report_analytics", {
                type: 'store',
                period: period,
                employees: null,
                stores: selectedStores,
                platform: null
            })

            window.open('https://' + window.location.host.split('25565').join('25567') + response.data)
        }

        if (type === 'platform') {
            const response = await axios.post("/api/v1/reports/generate_report_analytics", {
                type: 'platform',
                period: period,
                employees: null,
                stores: null,
                platform: selectedPlatforms
            })

            window.open('https://' + window.location.host.split('25565').join('25567') + response.data)
        }

        this.setState({
            loadingButton: false
        })
    }

    handleClearFilters = () => {
        this.setState({
            period: [
                dayjs().startOf('month').toDate(),
                dayjs().endOf('month').toDate(),
            ],
            selectedEmployees: null,
            selectedPlatforms: null,
            selectedStores: null
        })
    }

    render() {
        return (
            <>
                <div style={{ marginLeft: localStorage.getItem("hiddenSidebar") == "true" ? "0px" : "203px" }} className={classNames("container")}>
                    <div className={styles.mainContainer}>
                        <Divider>Filtros Gerais</Divider>
                        <div className={styles.globalsFilters}>
                            <div className={styles.cardTopFilter}>
                                <span>Período</span>
                                <DateRangePicker
                                    placeholder="Data para geração dos relatórios"
                                    format="DD/MM/YYYY"
                                    ranges={[]}
                                    value={this.state.period}
                                    onChange={(value) => {
                                        this.setState({
                                            period: value
                                        })
                                    }}
                                />
                            </div>
                            <div>
                                <Button
                                    appearance="primary"
                                    color="cyan"
                                    onClick={this.handleClearFilters}
                                >
                                    Limpar Filtros
                                </Button>
                            </div>
                        </div>

                        <Divider>Relatórios</Divider>

                        <div className={styles.reportsContainer}>
                            <PanelGroup accordion defaultActiveKey={1} bordered>
                                <Panel header="Resultados por Atendente" eventKey={1}>
                                    <div className={styles.generateReport}>
                                        <div className={styles.selectComponent}>
                                            <CheckPicker
                                                data={this.state.employeeListings}
                                                style={{ width: '450px' }}
                                                placeholder="Selecione os funcionários para gerar o relatório"
                                                value={this.state.selectedEmployees}
                                                onChange={(value) => {
                                                    this.setState({
                                                        selectedEmployees: value
                                                    })
                                                }}
                                            />

                                            <span className={styles.informationText}>
                                                *Se nenhum funcionário for selecionado, o relatório incluirá todos os funcionários
                                            </span>
                                        </div>

                                        <Button
                                            appearance="primary"
                                            color="green"
                                            loading={this.state.loadingButton}
                                            onClick={() => this.handleGenerateReport("employee")}
                                            style={{ height: '40px' }}
                                        >
                                            Gerar Relatório
                                        </Button>
                                    </div>
                                </Panel>
                                <Panel header="Resultados por Loja" eventKey={2}>
                                    <div className={styles.generateReport}>
                                        <div className={styles.selectComponent}>
                                            <CheckPicker
                                                data={this.state.storeListings}
                                                style={{ width: '450px' }}
                                                placeholder="Selecione lojas para gerar o relatório"
                                                value={this.state.selectedStores}
                                                onChange={(value) => {
                                                    this.setState({
                                                        selectedStores: value
                                                    })
                                                }}
                                            />

                                            <span className={styles.informationText}>
                                                *Se nenhuma loja for selecionada, o relatório incluirá todas as lojas
                                            </span>
                                        </div>

                                        <Button
                                            appearance="primary"
                                            color="green"
                                            loading={this.state.loadingButton}
                                            onClick={() => this.handleGenerateReport('store')}
                                            style={{ height: '40px' }}
                                        >
                                            Gerar Relatório
                                        </Button>
                                    </div>
                                </Panel>
                                <Panel header="Resultados por Plataforma" eventKey={3}>
                                    <div className={styles.generateReport}>
                                        <div className={styles.selectComponent}>
                                            <CheckPicker
                                                data={this.state.platformListings}
                                                style={{ width: '450px' }}
                                                placeholder="Selecione as plataformas para gerar o relatório"
                                                value={this.state.selectedPlatforms}
                                                onChange={(value) => {
                                                    this.setState({
                                                        selectedPlatforms: value
                                                    })
                                                }}
                                            />

                                            <span className={styles.informationText}>
                                                *Se nenhuma plataforma for selecionada, o relatório incluirá todas as plataformas
                                            </span>
                                        </div>

                                        <Button
                                            appearance="primary"
                                            color="green"
                                            loading={this.state.loadingButton}
                                            onClick={() => this.handleGenerateReport('platform')}
                                            style={{ height: '40px' }}
                                        >
                                            Gerar Relatório
                                        </Button>
                                    </div>
                                </Panel>
                            </PanelGroup>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(DataInsightsPage);
