import classNames from "classnames";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Modal, Table } from "rsuite";
import { isInPath } from "../../utilities/isInPath";
import { AuthContext, ModuleContext, UserContext } from "../authProvider/main";
import { ModuleShortcuts } from "./moduleShortcut";
import { NotificationsIcon } from "../notifications/main";
import { BreadCrumbs } from "../breadcrumbs/main";
import _ from "lodash";
import axios from "../../utilities/axios";

const { Column, HeaderCell, Cell } = Table;

class InnerTopbar extends React.Component<any, { isDesenv: boolean, currentCompany: string | null }> {

    public state = {
        isDesenv: false, // Inicializa isDesenv como false
        currentCompany: localStorage.getItem("currentCompany") // Pega a empresa atual do localStorage
    }


    logout = () => {
        this.props.auth.setAuth(null);
        localStorage.removeItem("currentCompany");
        this.props.history.push("/login");
    };


    goBack = () => {
        this.props.history.push("/dashboard");
    };

    onModuleClick = (moduleName: string) => {
        if (moduleName === "dashboard") {
            this.props.history.push("/dashboard");
            return
        }
        this.props.history.push("/dashboard/" + moduleName);
    };


    async verify(): Promise<void> {
        try {
            const res = await axios.get("/api/v1/myprof");
            if (res.data) {
                this.setState({ isDesenv: res.data.database.includes("-desenv") });
            }
        } catch (error) {
            console.error("Erro ao verificar o perfil:", error);
        }
    }

    componentDidMount() {
        this.verify();
    }

    renderProfileButton() {
        const { isDesenv } = this.state;

        if (this.props.user.data) {
            return (
                <div className="clickable" style={{ textTransform: "capitalize", fontSize: 18, fontWeight: "bold", textAlign: "left", minWidth: 100 }}>
                    <div style={{ fontSize: 16 }}>
                        <i onClick={this.goBack} className="fas fa-fw fa-cog mr-2"></i>
                        {this.props.user.data.user_name}
                    </div>
                    {isDesenv && (
                        <>
                            <i className="fas fa-sm fa-solid fa-bug mr-2" style={{ color: "red" }}></i>
                            <span style={{ color: "red" }}>DESENV.</span>
                        </>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }

    // Função que renderiza o botão de módulos
    renderModulesButton() {
        return (
            <div onClick={this.goBack} className="clickable" style={{ textTransform: "capitalize", fontSize: 18, fontWeight: "bold", minWidth: 200, textAlign: "right" }}>
                <i className="fas fa-fw fa-th-large mr-2"></i>
                {this.props.module.name ? this.props.module.name.split("-").join(" ") : ""}
            </div>
        );
    }

    // Função que seleciona a empresa atual e atualiza o estado
    onSelectCompany(id: any) {
        localStorage.setItem("currentCompany", id);
        this.setState({ currentCompany: id });
        this.props.history.push("/dashboard");
    }

    render() {
        // Verifica se o token de autenticação ou o nome do módulo não existem
        if (!this.props.auth.token && !this.props.module.name) {
            return null;
        }

        return (
            <div className={classNames("topbar", this.props.fulld ? "topbar-fulld" : this.props.full ? "topbar-full" : "")} style={{ backgroundColor: this.props.module.color }}>
                <div style={{ width: "33%", display: "flex", alignItems: "center" }}>
                    {!isInPath(this.props.location.pathname, "/dashboard") && <i onClick={() => this.props.changeSidebar()} className={"fas fa-bars"}></i>}
                    <NotificationsIcon />
                    <BreadCrumbs />
                </div>

                {!isInPath(this.props.location.pathname, "/dashboard") && <ModuleShortcuts onClick={this.onModuleClick} />}

                <div style={{ maxWidth: "50%", textAlign: "right", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Dropdown placement="bottomEnd" renderTitle={() => this.renderProfileButton()}>
                        {/* <Dropdown.Item onSelect={() => this.props.history.push("/dashboard/configuracoes/profile")}>
                            <i className="fas fa-fw fa-user icon"></i> Meu perfil
                        </Dropdown.Item> */}
                        <Dropdown.Item onSelect={this.logout}>
                            <i className="fas fa-fw fa-sign-out-alt icon"></i> Logout
                        </Dropdown.Item>
                    </Dropdown>
                </div>
            </div>
        );
    }
}

class Topbar extends React.Component<any, {}> {
    render() {
        if (this.props.location.pathname.includes("/process_admission/")) {
            return null; // Se estiver no caminho específico, não renderiza a navbar
        }

        return (
            <ModuleContext.Consumer>
                {(moduleContext) => {
                    return (
                        <AuthContext.Consumer>
                            {(auth) => {
                                let moduleVar: any = moduleContext;
                                if (isInPath(this.props.location.pathname, "/dashboard")) {
                                    moduleVar = { name: "", setModule: () => { } };
                                }
                                return (
                                    <UserContext.Consumer>
                                        {(user: any) => {
                                            if (user.data?.user_name) {
                                                return (
                                                    <InnerTopbar
                                                        changeSidebar={() => this.props.changeSidebar()}
                                                        fulld={isInPath(this.props.location.pathname, "/dashboard")}
                                                        full={this.props.hiddenSidebar}
                                                        location={this.props.location}
                                                        history={this.props.history}
                                                        module={moduleVar}
                                                        auth={auth}
                                                        user={user}
                                                    />
                                                );
                                            } else {
                                                return <></>;
                                            }
                                        }}
                                    </UserContext.Consumer>
                                );
                            }}
                        </AuthContext.Consumer>
                    );
                }}
            </ModuleContext.Consumer>
        );
    }
}

export default withRouter(Topbar);