import React from "react";
import { withRouter } from "react-router-dom";
import { Event } from "../Event";
import { Button } from "rsuite";
import axios from "../../utilities/axios";
import { AxiosError, AxiosResponse } from "axios";


type State = {
    loading: boolean;
    formValues: Record<string, any>;
}


class DisciplinaryMeasureFooterComponent extends React.Component<any> {

    state = {
        loading: false,
        formValues: this.props.values.formValues
    }

    printArticle = async () => {
        this.setState({ loading: true });

        axios
            .get('/api/v1/disciplinary_measure/print/article', {
                params: {
                    disciplinary_measure_id: this.state.formValues.disciplinary_measure_id
                }
            })
            .then((res: AxiosResponse) => {
                if (res.status === 200) {
                    this.setState({ loading: false });
                    window.open('https://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                }
            })
            .catch((e: AxiosError) => {
                // console.log("Deu erro no printArticle ::::>> ", e)
            })
            .finally(() => {

            })
    }

    printSuspension = async () => {
        this.setState({ loading: true });

        axios
            .get('/api/v1/disciplinary_measure/print/suspension', {
                params: {
                    disciplinary_measure_id: this.state.formValues.disciplinary_measure_id
                }
            })
            .then((res: AxiosResponse) => {
                if (res.status === 200) {
                    this.setState({ loading: false });
                    window.open('https://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                }
            })
            .catch((e: AxiosError) => {

            })
            .finally(() => {
            })
    }

    printAdvertence = async () => {
        this.setState({ loading: true });

        axios
            .get('/api/v1/disciplinary_measure/print/advertence', {
                params: {
                    disciplinary_measure_id: this.state.formValues.disciplinary_measure_id
                }
            })
            .then((res: AxiosResponse) => {
                if (res.status === 200) {
                    window.open('https://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                }
            })
            .catch((e: AxiosError) => {

            })
            .finally(() => {
                this.setState({ loading: false });
            })
    }

    render(): React.ReactNode {
        const selectTypeValue = Number(this.props.values.formValues?.disciplinary_measure_fk_disciplinary_measure_type_id)


        return (
            <div
                style={
                    {
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 8,
                        marginLeft: 8
                    }
                }
            >
                {selectTypeValue === 1 && (
                    <Button
                        color="violet"
                        style={
                            {
                                display: 'flex',
                                flexDirection: "row",
                                gap: 10,
                                alignContent: "center",
                                justifyContent: "center",
                                alignItems: "center",
                            }
                        }
                        loading={this.state.loading}
                        onClick={this.printArticle}
                    >
                        <i className="fas fa-print"></i>
                        Imprimir Artigo
                    </Button>
                )}

                {selectTypeValue === 2 && (
                    <Button
                        color="violet"
                        style={
                            {
                                display: 'flex',
                                flexDirection: "row",
                                gap: 10,
                                alignContent: "center",
                                justifyContent: "center",
                                alignItems: "center",
                            }
                        }
                        loading={this.state.loading}
                        onClick={this.printSuspension}
                    >
                        <i className="fas fa-print"></i>
                        Imprimir Suspensão
                    </Button>
                )}

                {selectTypeValue === 3 && (
                    <Button
                        color="violet"
                        style={
                            {
                                display: 'flex',
                                flexDirection: "row",
                                gap: 10,
                                alignContent: "center",
                                justifyContent: "center",
                                alignItems: "center",
                            }
                        }
                        loading={this.state.loading}
                        onClick={this.printAdvertence}
                    >
                        <i className="fas fa-print"></i>
                        Imprimir Advertência
                    </Button>
                )}
            </div>
        )
    }
}

// class DisciplinaryMeasureFooterAddComponent extends React.Component<any> {

//     test = () => {
//         // console.log("Valor do this :::>> ", this)
//     }

//     render(): React.ReactNode {
//         const selectTypeValue = Number(this.props.values.formValues?.disciplinary_measure_fk_disciplinary_measure_type_id)

//         return (
//             <div
//                 style={
//                     {
//                         display: 'flex',
//                         flexDirection: 'row',
//                         gap: 8,
//                         marginLeft: 8
//                     }
//                 }
//             >
//                 {selectTypeValue === 1 && (
//                     <Button
//                         color="violet"
//                         style={
//                             {
//                                 display: 'flex',
//                                 flexDirection: "row",
//                                 gap: 10,
//                                 alignContent: "center",
//                                 justifyContent: "center",
//                                 alignItems: "center",
//                             }
//                         }
//                         // loading={this.state.loading}
//                         onClick={this.test}
//                     >
//                         <i className="fas fa-print"></i>
//                         Imprimir Artigo
//                     </Button>
//                 )}

//                 {selectTypeValue === 2 && (
//                     <Button
//                         color="violet"
//                         style={
//                             {
//                                 display: 'flex',
//                                 flexDirection: "row",
//                                 gap: 10,
//                                 alignContent: "center",
//                                 justifyContent: "center",
//                                 alignItems: "center",
//                             }
//                         }
//                         // loading={this.state.loading}
//                         onClick={this.test}
//                     >
//                         <i className="fas fa-print"></i>
//                         Imprimir Suspensão
//                     </Button>
//                 )}

//                 {selectTypeValue === 3 && (
//                     <Button
//                         color="violet"
//                         style={
//                             {
//                                 display: 'flex',
//                                 flexDirection: "row",
//                                 gap: 10,
//                                 alignContent: "center",
//                                 justifyContent: "center",
//                                 alignItems: "center",
//                             }
//                         }
//                         // loading={this.state.loading}
//                         onClick={this.test}
//                     >
//                         <i className="fas fa-print"></i>
//                         Imprimir Advertência
//                     </Button>
//                 )}
//             </div>
//         )
//     }
// }


const DisciplinaryMeasureFooterWithRouter = withRouter(DisciplinaryMeasureFooterComponent);
// const DisciplinaryMeasureFooterAddWithRouter = withRouter(DisciplinaryMeasureFooterAddComponent);

export class DisciplinaryMeasureFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <DisciplinaryMeasureFooterWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    };

    // public onRenderAddFooter = (onSubmit: any) => {
    //     return (
    //         <>
    //             <DisciplinaryMeasureFooterAddWithRouter onSubmit={onSubmit} values={this.globalState} />
    //         </>
    //     );
    // };
}