import { Event } from "../Event";

export class DisciplinaryMeasureInitialDateEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState("loadings", ["disciplinary_measure_final_date", "disciplinary_measure_quantity_day"])

        this.setField("disciplinary_measure_final_date", {
            readonly: false
        })

        this.setFieldValue("disciplinary_measure_final_date", null)
        this.setFieldValue("disciplinary_measure_quantity_day", '')

        setTimeout(() => {
            this.setState("loadings", [])
        }, 300)
     }

}