import { Event } from "../Event";
import { MethodsUtils } from "./utils/methodsUtils";

export class InfringementControlDateEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState("loadings", ["infringement_control_period", "infringement_control_day_of_the_week"])

        if (!value || value === 'Invalid Date') {
            this.setFieldValue("infringement_control_period", "")
            this.setFieldValue("infringement_control_day_of_the_week", "")

            setTimeout(() => {
                this.setState("loadings", [])
            }, 100)

            return
        }

        const dateInformations = MethodsUtils.getDateInformations(value)

        if (dateInformations.status === 200) {
            this.setFieldValue("infringement_control_period", dateInformations.period)
            this.setFieldValue("infringement_control_day_of_the_week", dateInformations.dayOfWeek)
        }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 100)
    }
}