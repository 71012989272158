import { ParametersGlobals } from "../../../../core/parametersGlobals/ParametersGlobals";
import { MethodsUtils } from "../../../../core/utils/MethodsUtils";
import { Event } from "../../../Event";
import { Notification } from "rsuite";

export class UserHourIntervalEvents extends Event {

     public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {
        
        value = value.slice(0, 2) + ':' + value.slice(2);


        // DA PARA DEIXAR ESSA VALIDAÇÃO DINÂMICA, SEM A NECESSIDADE DE REPETIR CÓDIGO,
        // MAS EU QUIS DEIXAR ASSIM: 30/09/2024 - ELIAS

        if (await MethodsUtils.isValidHour(value)) {
           this.calculatedInterval(setField, setState, formValues); 
            
        } else {
            Notification.warning({
                title: "ATENÇÃO",
                description: "Horário do Intervalo Inválido!",
                duration: 2500
            })
            value = ParametersGlobals.defaultHourInterval
            this.setFieldValue("user_hour_interval", value);
            this.calculatedInterval(setField, setState, formValues); 
        }

     }

     async calculatedInterval(setField: any, setState: any, formValues: any) {
            const intervalCalculated: string = await MethodsUtils.intervalCalculated(formValues.user_hour_end ,formValues.user_hour_start, formValues.user_hour_interval);
            this.setState("loadings", "user_amount_hour_day");
            this.setFieldValue("user_amount_hour_day", MethodsUtils.removeCharString(intervalCalculated, ":",""));
            this.setState("loadings", []);
     }

}