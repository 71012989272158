import { Event } from "../../Event";


type TData = {
    entry_expense_released_fk_vehicle_id: number;
    entry_expense_released_fk_variable_id: number;
    entry_expense_released_initial_date: string;
    entry_expense_released_final_date: string;
    entry_expense_released_quantity_days: string;
    entry_expense_released_unity_value: number;
    entry_expense_released_amount_value: number;
    entry_expense_released_origin_fk_state_id: number;
    entry_expense_released_origin_fk_city_id: number;
    entry_expense_released_destiny_fk_state_id: number;
    entry_expense_released_destiny_fk_city_id: number;
}


interface IEntryExpenseReleasedHelpers {
    getValueAmount({data}: { data: TData[] }): number;
    getOutstandingBalance({data, valueGrid}: { data: Record<string,any>, valueGrid: TData[]}): number;
}


export class EntryExpenseReleasedHelpers extends Event implements IEntryExpenseReleasedHelpers{
    getValueAmount ({data}: { data: TData[] }) {
        let amount = 0;
        if(data.length > 0) {
            for( const item of data) {
                amount = amount + item.entry_expense_released_amount_value;
            }
        }

        // console.log("Valor do amount no getValueAmount :::>> ", amount)
        return amount
    }
    getOutstandingBalance({data, valueGrid}: { data: Record<string,any>, valueGrid: TData[]}): number {

        let amount = 0;
        if(![null,undefined,''].includes(data.entry_expense_have_to_pay)) {
            // const lastObject = valueGrid[valueGrid.length - 1];
            // console.log(lastObject.entry_expense_released_amount_value)
            // amount = data.entry_expense_have_to_pay + lastObject.entry_expense_released_amount_value

            valueGrid.forEach((item: any) => {
                amount += item.entry_expense_released_amount_value
            })
        } else {
            amount = this.getValueAmount({data: valueGrid})
        }

        // console.log("Valor do maount no getOurstandingBalance ::::>> ", amount)
        return amount
    }
}
