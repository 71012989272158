import React from "react";
import { Loader, Tooltip, Whisper,Button , Notification, Table, Panel } from "rsuite";
import axios from "../../../utilities/axios";
import _ from "lodash";
import {CustomModal} from "../../customModal/CustomModal"


const { Column, HeaderCell, Cell } = Table;

export class CreateLambdaComplement extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        onSelect: null,
        data :  [],
        isLoading: false,
        value: null,
        valueProps: this.props.value,
        isImportModalOmie: false,
        omieData: [],
        OmieItens: [],
        confirmCancelModal: false,
        omieSelectitem: [],
        omieDataSelectDelete: [],
        propsPsm: null,
    }

    componentDidMount = async() =>{

    }

    public select: any = {};

    onChange = (item: any, valuesOmie: any) => {

    };

    isExecuteQuery = () => {
        let props = this.props.values;
        if(props.development_module_name == ''){
            Notification.error({
                title: "error!",
                description: "Não foi informado nenhum valor!",
                duration: 5600,
            });
            return
        }
        this.setState({
            loading: true,
        })
        axios
            .post("/api/v1/dev/create-lambda",{
                crud: props.development_module_name
            })
            .then((res => {
                if(res.data.status == 200){
                    Notification.success({
                        title: "Sucesso!",
                        description: res.data.message,
                        duration: 6600,
                    });
                    setTimeout(this.props.history.go(0),7000)
                }
                if(res.data.status == 201){
                    Notification.success({
                        title: "Erro!",
                        description: res.data.message,
                        duration: 6600,
                    });
                }
                
            }))
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        // console.log('Props development ::: ',this.props)
        return (<>
            <Button color="violet" size={"lg"} loading={this.state.loading} style={{marginTop: 20, width: "100%"}} onClick={() => this.isExecuteQuery()}>
                <i className="fal fa-plus-circle"></i> Criar
            </Button>
        </>)
    }
}
