import dayjs from "dayjs";


interface IEntryExpenseReleasedHelpers {
    calcAmountValue({ data, value }: { data: Record<string, any>, value: number }): { amountValue: number }
}


export class EntryExpenseReleasedUnityHelpers implements IEntryExpenseReleasedHelpers {
    calcAmountValue({ data, value }: { data: Record<string, any>, value: number }) {
        let diffDaysCount = 1;
        if (!['', undefined, null].includes(data.entry_expense_released_initial_date) && !['', undefined, null].includes(data.entry_expense_released_final_date)) {
            const diffInDays = dayjs(data.entry_expense_released_final_date).diff(dayjs(data.entry_expense_released_initial_date), 'day')
            diffDaysCount = diffInDays;
        }

        if (diffDaysCount == 0) {
            diffDaysCount = 1;
        }

        return { amountValue: (value * diffDaysCount) };
    }
}