import { Event } from "../Event";

export class ConsignedCreditCustomInstallmentEvents extends Event {
    // public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
    //     this.setState("loadings", ["consigned_credit_outstanding_balance"])

    //     const customInstallment = this.globalProps.values.consigned_credit_custom_installment;
    //     if(customInstallment.length > 0) {
    //         let outstandingBalance = 0;
    
    //         for (const item of customInstallment) {
    //             if (item.consigned_credit_installment_installment_paid === false) {
    //                 outstandingBalance += Number(item.consigned_credit_installment_parcel_value)
    //             }    
    //         }
    
    //         this.setFieldValue("consigned_credit_outstanding_balance", outstandingBalance)
    
    //     }
    //     setTimeout(() => {
    //         this.setState("loadings", [])

    //     }, 300)
    // }
}