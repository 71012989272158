export class MethodsUtils {
    static getDateInformations(date: string) {
        const dateObj = new Date(date);

        if (isNaN(dateObj.getTime())) {
            return {
                status: 500,
                dayOfWeek: null,
                period: null
            }
        }

        const daysOfWeek = [
            'Domingo', 'Segunda-feira', 'Terça-feira',
            'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'
        ];

        const dayOfWeek = daysOfWeek[dateObj.getDay()];

        const hour = dateObj.getHours();

        let period = '';
        if (hour >= 5 && hour < 12) {
            period = 'Manhã';
        } else if (hour >= 12 && hour < 18) {
            period = 'Tarde';
        } else {
            period = 'Noite';
        }

        return {
            status: 200,
            dayOfWeek,
            period
        };
    }
}
