import {Event} from "../../../Event";
import dayjs from "dayjs";
import { EntryExpenseReleasedUnityHelpers } from "./EntryExpenseReleasedUnityHelpers";

export class EntryExpenseReleasedUnityValueEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        const instancedHelpers = new EntryExpenseReleasedUnityHelpers();
        const { values } = this.globalProps;
        this.setState('loadings',[
            'entry_expense_released_amount_value'
        ])
        const resultCalc = instancedHelpers.calcAmountValue(
            {
                data: values,
                value: value
            }
        )

        setField('entry_expense_released_amount_value', resultCalc.amountValue )


        this.setState('loadings',[])
    }
}