import axios from "../../utilities/axios";
import { Event } from "../Event";

export class RequestChangePositionFkCompanyIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {

        if(value) {
            this.setState("loadings", ["request_change_position_fk_base_id"])

            this.setField("request_change_position_fk_base_id", {
                readonly: false
            })

            this.setFieldValue("request_change_position_fk_base_id", "")

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }
    }
}