import { Event } from "../Event";
import axios from "../../utilities/axios";


export class RequestLayoffEvents extends Event {
    public onLoad = async () => {
        const isAdd = this.globalProps.match.url;

        if (isAdd.includes("/add")) {
            this.setState("loadings", ["request_layoff_employee_fk_user_id"])

            this.setFieldValue("request_layoff_employee_fk_user_id", '‎')

            setTimeout(() => {
                this.setFieldValue("request_layoff_employee_fk_user_id", "")
                this.setState("loadings", [])
            }, 250)

        } else {
            this.setState("loadings", ["request_layoff_fk_base_id", "request_layoff_manager_fk_user_id"])

            this.setField("request_layoff_fk_base_id", {
                readonly: false
            })

            this.setField("request_layoff_manager_fk_user_id", {
                readonly: false
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }

        const situation = this.values.request_layoff_fk_request_situation_id

        const situationsToCondition = [1, 2, 6]

        if (situationsToCondition.includes(situation)) {
            this.setState("loadings", ['request_layoff_reason_disapproval'])

            this.setField("request_layoff_reason_disapproval", {
                hidden: false,
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 300)
        }
    }
}