import classNames from "classnames";
import React, { useState } from "react";
import { Redirect, withRouter } from "react-router";
import { Button, ControlLabel, FormGroup, Input, InputGroup, Panel } from "rsuite";
import InputGroupAddon from "rsuite/lib/InputGroup/InputGroupAddon";
import InputGroupButton from "rsuite/lib/InputGroup/InputGroupButton";
import { AuthContext } from "../components/authProvider/main";
import { ConfirmModal } from "../components/modal/main";
import { LoginAnimationSvg } from "../svg/LoginAnimationSvg";
import axios from "../utilities/axios";
import qs from "query-string";
import { Helmet } from "react-helmet";

class LoginPage extends React.Component<any, {}> {
    static contextType = AuthContext;
    public state = {
        isPasswordVisible: false,
        isLoading: false,
        userCpfCnpj: "",
        userPassword: "",
        errorMessage: null,
    };

    doLogin = () => {
        this.setState({ isLoading: true, errorMessage: null });
        axios
            .post("/api/v1/auth", {
                user_cpf_cnpj: this.state.userCpfCnpj,
                user_password: this.state.userPassword,
            })
            .then((res) => {
                if (res && res.data && res.data.token) {
                    this.context.setAuth(res.data.token);
                }
            })
            .catch((err) => {
                if (err && err.response && err.response.status === 409) {
                    this.setState({ errorMessage: err.response.data.message });
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    };

    formatCPF = (value: string) => {
        const formattedValue = value.replace(/\D/g, '').substring(0, 11);
        const cpf = formattedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
        return cpf;
    };

    handleCPFChange = (value: string) => {
        this.setState({ userCpfCnpj: this.formatCPF(value) });
    };

    render() {
        if (this.context.isAuthenticated) {
            return <Redirect to="/dashboard" />;
        }
        var { isAuthDone, failedToConnect } = qs.parse(this.props.location.search);
        if (isAuthDone) {
            localStorage.removeItem("currentCompany")
        }
        return (
            <div className="login-page">
                <Helmet>
                    <title>Coxinha da Hora - Login</title>
                </Helmet>
                <LoginAnimationSvg />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <ConfirmModal show={isAuthDone || failedToConnect}>
                        {isAuthDone ? 'Parece que sua sessão terminou, será necessário fazer o login novamente!' : 'Houve uma falha ao conectar-se com o servidor!'}
                    </ConfirmModal>
                    <Panel style={{ width: '300px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
                        <div style={{ margin: '0 auto 20px' }}>
                            <img alt={"Logo"} src="https://i.imgur.com/VTi7R21.png" style={{ width: '150px' }} />
                        </div>
                        <form onSubmit={() => this.doLogin()}>
                            <FormGroup className="mb-4">
                                <ControlLabel>CPF</ControlLabel>
                                <InputGroup>
                                    <InputGroupAddon>
                                        <i className="fas fa-user"></i>
                                    </InputGroupAddon>
                                    <Input
                                        value={this.state.userCpfCnpj}
                                        onPressEnter={this.doLogin}
                                        onChange={(v) => this.handleCPFChange(v)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-6">
                                <ControlLabel>Senha</ControlLabel>
                                <InputGroup>
                                    <InputGroupAddon>
                                        <i className="fas fa-lock"></i>
                                    </InputGroupAddon>
                                    <Input
                                        value={this.state.userPassword}
                                        onPressEnter={this.doLogin}
                                        onChange={(v) => this.setState({ userPassword: v })}
                                        type={this.state.isPasswordVisible ? "text" : "password"}
                                    />
                                    <InputGroupButton onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}>
                                        <i className={classNames("fas fa-fw", "fa-" + (!this.state.isPasswordVisible ? "eye" : "low-vision"))}></i>
                                    </InputGroupButton>
                                </InputGroup>
                            </FormGroup>
                        </form>


                        <div style={{ width: '100%' }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
                                    <Button color="green" appearance="ghost" onClick={this.doLogin} loading={this.state.isLoading}>Fazer Login</Button>
                                    <small style={{ color: 'red' }}>{this.state.errorMessage}</small>
                                </div>
                                <a href="/resetpassword" style={{ color: "black" }}>Esqueci Minha Senha</a>
                            </div>
                        </div>
                    </Panel>
                </div>

            </div>
        );
    }
}

export default withRouter(LoginPage);
