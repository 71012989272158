import { Event } from "../Event";

export class GeneralParameterImportCompanyEvents extends Event {
    public onLoad = () => {
        const hasOvertime = this.values?.general_parameter_import_company_has_overtime

        if (hasOvertime) {
            this.setState("loadings", ["general_parameter_import_company_extra_hours_amount"])

            this.setField("general_parameter_import_company_extra_hours_amount", {
                hidden: false
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        } else {
            this.setState("loadings", ["general_parameter_import_company_extra_hours_amount"])

            this.setField("general_parameter_import_company_extra_hours_amount", {
                hidden: true
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }
    }
}