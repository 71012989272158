import { Event } from "../Event";

export class GeneralParameterImportCompanyHasOvertimeEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        const considerNightTime = this.values?.general_parameter_import_company_consider_night_time;

        if (value) {
            this.setState("loadings", ["general_parameter_import_company_extra_hours_amount", "general_parameter_import_company_consider_night_time"])

            this.setField("general_parameter_import_company_extra_hours_amount", {
                hidden: false
            })

            this.setFieldValue("general_parameter_import_company_extra_hours_amount", "30:00:00")
            this.setFieldValue("general_parameter_import_company_extra_hours_show_grid", "30:00:00")

            this.setFieldValue("general_parameter_import_company_consider_night_time", false)

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        } else {
            this.setState("loadings", ["general_parameter_import_company_extra_hours_amount", "general_parameter_import_company_consider_night_time"])

            this.setField("general_parameter_import_company_extra_hours_amount", {
                hidden: true
            })

            this.setFieldValue("general_parameter_import_company_consider_night_time", true)

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }
    }
}