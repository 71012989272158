import { Component, ReactNode } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Notification } from "rsuite";

interface RecaptchaComponentProps {
    onSubmit: () => void;
    loading: boolean;
}

interface RecaptchaComponentState {
    token: string | null;
    refreshReCaptcha: boolean;
}

export class RecaptchaComponent extends Component<RecaptchaComponentProps, RecaptchaComponentState> {
    reCaptchaRef: ReCAPTCHA | null = null;

    state = {
        token: null,
        refreshReCaptcha: false,
    }

    onVerify = (token: string | null) => {
        this.setState({ token });
    }

    onChange = (value: string | null) => {
        this.onVerify(value); // Atualiza o token no estado
    }

    resetRecaptcha = () => {
        if (this.reCaptchaRef) {
            this.reCaptchaRef.reset(); // Resetar o reCAPTCHA após a submissão
        }
        this.setState({ token: null });
    }

    handleSubmit = () => {
        const { token } = this.state;
        if (!token) {
            Notification.warning({
                title: 'Por favor, realize o teste do reCAPTCHA',
            });
            return;
        }

        this.props.onSubmit(); // Executa a lógica de submissão
        this.resetRecaptcha(); // Reseta o reCAPTCHA após o envio
    }

    render(): ReactNode {
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <div>
                        <ReCAPTCHA
                            ref={(el) => { this.reCaptchaRef = el; }} // Atribui a referência ao reCAPTCHA
                            sitekey="6LdY6VcqAAAAALdXOa_Ge6b2kfCYXi3Oq8BvlnUZ"
                            onChange={this.onChange}
                        />
                    </div>
                    <Button
                        color="green"
                        style={{
                            boxShadow: '0 3px 10px rgba(0,0,0,0.25)',
                        }}
                        onClick={this.handleSubmit}
                        loading={this.props.loading}
                    >
                        Enviar Documentos
                    </Button>
                </div>
            </>
        );
    }
}
