import React from "react";
import { withRouter } from "react-router-dom";
import { Event } from "../Event";
import { Button } from "rsuite";
import axios from "../../utilities/axios";
import { AxiosError, AxiosResponse } from "axios";
// import { UpdateStatusButton } from "../../components/formfields/UpdateStatusButton";
import { UpdateStatusButton } from "./UpdateStatusButton";
import { UpdateReproveStatusButton } from "./UpdateReproveStatusButton";
import { UpdateUnmakeStatusButton } from "./UpdateUnmakeStatusButton";
import { UserContext } from "../../components/authProvider/main";


type State = {
    loading: boolean;
    formValues: Record<string, any>;
}


class RequestLayoffFooterComponent extends React.Component<any, State> {
    static contextType?: React.Context<any> | undefined = UserContext;

    state = {
        loading: false,
        formValues: this.props.values.formValues
    }

    printRequestLayoff = () => {
        this.setState({ loading: true });
        axios
            .get('/api/v1/request_layoff/print', {
                params: {
                    request_layoff_id: this.state.formValues.request_layoff_id
                }
            })
            .then((res: AxiosResponse) => {
                if (res.status === 200) {
                    window.open('http://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                }
            })
            .catch((e: AxiosError) => {

            })
            .finally(() => {
                this.setState({ loading: false });
            })

    }


    reloadPage = () => {
        this.props.history.go(0);
    }

    reproving = () => {
        this.setState({ loading: true });
        axios
            .get('/api/v1/reproving/request_layoffs', {
                params: {
                    currentSituation: this.state.formValues.request_layoff_fk_request_situation_id,
                    foreignKey: this.state.formValues.request_layoff_id
                }
            })
            .then((res: AxiosResponse) => {
                if (res.status === 200) {
                    this.reloadPage();
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            })
    }


    render(): React.ReactNode {
        const user = this.context.data;
        const currentSituation = this.state.formValues.request_layoff_fk_request_situation_id;
        const userFunction = user ? user.user_management_fk_function_id : null;

        const reproveSituations = [6, 7, 8];
        const aproveReproveRH = [2, 6];
        const aproveReproveDiretor = [3, 7];
        const aproveReproveCEO = [4, 8];

        return (
            <div
                // className="ml-4 d-flex"
                style={
                    {
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 8,
                        marginLeft: 8
                    }
                }
            >
                <Button
                    color="blue"
                    style={
                        {
                            display: 'flex',
                            flexDirection: "row",
                            gap: 10,
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                        }
                    }
                    loading={this.state.loading}
                    onClick={this.printRequestLayoff}
                >
                    <i className="fas fa-print"></i>
                    Imprimir Requisição
                </Button>

                {userFunction === 1 && (
                    <>
                        <UpdateStatusButton
                            requestSituationId={this.state.formValues.request_layoff_fk_request_situation_id}
                            jobRequestId={this.state.formValues.request_layoff_id}
                            foreignKey={this.state.formValues.request_layoff_id}
                            reloadPage={this.reloadPage}
                            textButton="Aprovar"
                            userFunction={userFunction}
                            this={this}
                        />

                        <UpdateReproveStatusButton
                            requestSituationId={this.state.formValues.request_layoff_fk_request_situation_id}
                            jobRequestId={this.state.formValues.request_layoff_id}
                            foreignKey={this.state.formValues.request_layoff_id}
                            reloadPage={this.reloadPage}
                            textButton="Reprovar"
                            userFunction={userFunction}
                            userReproveDescription={this.state.formValues.request_layoff_reason_disapproval}
                            onSubmit={this.props.onSubmit}
                        />
                    </>
                )}

                {/* Validações de acordo com a função do usuário e situação atual */}
                {currentSituation === 1 && userFunction === 4 && !reproveSituations.includes(userFunction) && (
                    <>
                        <UpdateStatusButton
                            requestSituationId={this.state.formValues.request_layoff_fk_request_situation_id}
                            jobRequestId={this.state.formValues.request_layoff_id}
                            foreignKey={this.state.formValues.request_layoff_id}
                            reloadPage={this.reloadPage}
                            textButton="Aprovação RH"
                            userFunction={userFunction}
                            this={this}
                        />

                        <UpdateReproveStatusButton
                            requestSituationId={this.state.formValues.request_layoff_fk_request_situation_id}
                            jobRequestId={this.state.formValues.request_layoff_id}
                            foreignKey={this.state.formValues.request_layoff_id}
                            reloadPage={this.reloadPage}
                            textButton="Reprovação RH"
                            userFunction={userFunction}
                            userReproveDescription={this.state.formValues.request_layoff_reason_disapproval}
                            onSubmit={this.props.onSubmit}
                        />
                    </>
                )}

                {userFunction === 4 && aproveReproveRH.includes(currentSituation) && (
                    <UpdateUnmakeStatusButton
                        requestSituationId={this.state.formValues.request_layoff_fk_request_situation_id}
                        jobRequestId={this.state.formValues.request_layoff_id}
                        foreignKey={this.state.formValues.request_layoff_id}
                        reloadPage={this.reloadPage}
                        textButton="Desfazer RH"
                        userFunction={userFunction}
                    />
                )}

                {userFunction === 2 && currentSituation === 2 && (
                    <>
                        <UpdateStatusButton
                            requestSituationId={this.state.formValues.request_layoff_fk_request_situation_id}
                            jobRequestId={this.state.formValues.request_layoff_id}
                            foreignKey={this.state.formValues.request_layoff_id}
                            reloadPage={this.reloadPage}
                            textButton="Aprovação Diretor"
                            userFunction={userFunction}
                            this={this}
                        />

                        <UpdateReproveStatusButton
                            requestSituationId={this.state.formValues.request_layoff_fk_request_situation_id}
                            jobRequestId={this.state.formValues.request_layoff_id}
                            foreignKey={this.state.formValues.request_layoff_id}
                            reloadPage={this.reloadPage}
                            textButton="Reprovação Diretor"
                            userFunction={userFunction}
                            userReproveDescription={this.state.formValues.request_layoff_reason_disapproval}
                            onSubmit={this.props.onSubmit}
                        />
                    </>
                )}

                {userFunction === 2 && aproveReproveDiretor.includes(currentSituation) && (
                    <UpdateUnmakeStatusButton
                        requestSituationId={this.state.formValues.request_layoff_fk_request_situation_id}
                        jobRequestId={this.state.formValues.request_layoff_id}
                        foreignKey={this.state.formValues.request_layoff_id}
                        reloadPage={this.reloadPage}
                        textButton="Desfazer Diretor"
                        userFunction={userFunction}
                    />
                )}

                {userFunction === 3 && currentSituation === 3 && (
                    <>
                        <UpdateStatusButton
                            requestSituationId={this.state.formValues.request_layoff_fk_request_situation_id}
                            jobRequestId={this.state.formValues.request_layoff_id}
                            foreignKey={this.state.formValues.request_layoff_id}
                            reloadPage={this.reloadPage}
                            textButton="Aprovação CEO"
                            userFunction={userFunction}
                            this={this}
                        />

                        <UpdateReproveStatusButton
                            requestSituationId={this.state.formValues.request_layoff_fk_request_situation_id}
                            jobRequestId={this.state.formValues.request_layoff_id}
                            foreignKey={this.state.formValues.request_layoff_id}
                            reloadPage={this.reloadPage}
                            textButton="Reprovação CEO"
                            userFunction={userFunction}
                            userReproveDescription={this.state.formValues.request_layoff_reason_disapproval}
                            onSubmit={this.props.onSubmit}
                        />
                    </>
                )}

                {userFunction === 3 && aproveReproveCEO.includes(currentSituation) && (
                    <UpdateUnmakeStatusButton
                        requestSituationId={this.state.formValues.request_layoff_fk_request_situation_id}
                        jobRequestId={this.state.formValues.request_layoff_id}
                        foreignKey={this.state.formValues.request_layoff_id}
                        reloadPage={this.reloadPage}
                        textButton="Desfazer CEO"
                        userFunction={userFunction}
                    />
                )}
            </div>
        )
    }
}


const RequestLayoffFooterWithRouter = withRouter(RequestLayoffFooterComponent);

export class RequestLayoffFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <RequestLayoffFooterWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    };
}