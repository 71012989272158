import React from "react";
import { Route, withRouter } from "react-router-dom";




type TProtectRouterState = {
    loading: boolean;
    isValid: boolean;
}


class ProtectedRouterAdmission extends React.Component<any, TProtectRouterState> {
    state = {
        isValid: false,
        loading: true
    }
    
    componentDidMount(): void {
    }

    render() {
        return <Route {...this.props} />
    }
}

export default withRouter(ProtectedRouterAdmission)