import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { withRouter } from "react-router";
import Card from "./ui/Card";
import { CloseOutline } from '@rsuite/icons';
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import 'dayjs/locale/pt-br';
import { Divider, DateRangePicker, Button, CheckPicker, InputNumber, Notification, CheckboxGroup, Checkbox, RadioGroup, FormGroup, Radio, SelectPicker, CheckTreePicker } from "rsuite";
import axios from "../../utilities/axios";
dayjs.locale('pt-br');

interface GraphsAnalyticsPageState {
    filters: any;
    series: any;
    barChart: any;
    pieChart: any;
    data: any;
    totalInfraction: number;
    infractionPerMonth: number;
    graphsData: any;
    initialBarChart: any;
    initialPieChart: any;
    lineChart: any;
    lineChartFilterType: any;
    lineChartPerMonth: any;
}

const lineChartFilterOptions: any = ['1', '2', '3', '4']

interface MonthNode {
    label: string;
    value: string;
}

interface YearNode {
    label: string;
    value: string;
    children: MonthNode[];
}

class GraphsAnalyticsPage extends React.Component<any, GraphsAnalyticsPageState> {
    public state: any = {
        barChart: {
            series: [{
                data: [],
                name: 'Qt. Lançamentos'
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        borderRadiusApplication: 'end',
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [],
                },
            },
            employeeCategorieData: [],
            employeeSeriesData: [],
            employeeColorsData: []
        },
        pieChart: {
            series: [],
            options: {
                chart: {
                    width: '100%',
                    height: '100%',
                    type: 'pie',
                    toolbar: {
                        show: true,
                        tools: {
                            download: true
                        },
                    },
                },
                fill: {
                    colors: []
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: -5,
                        },
                    },
                },
                grid: {
                    padding: {
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    },
                },
                dataLabels: {
                    formatter(val: any, opts: any) {
                        const name = opts.w.globals.labels[opts.seriesIndex];
                        return [name, val.toFixed(1) + '%'];
                    },
                },
                legend: {
                    show: false,
                },
            },
            storeCategorieData: [],
            storeSeriesData: [],
            storeColorData: []
        },
        lineChart: {
            series: [],
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                colors: [],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    // text: 'Gráfico Semanal',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4'],
                },
            },
            filters: {
                weekSelect: {
                    indeterminate: true,
                    checkAll: true,
                    value: ['1', '2', '3', '4'],
                },
                employee: [],
                store: [],
                platform: [],
                lineChartFilterType: ""
            }
        },
        lineChartPerMonth: {
            series: [],
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                colors: [],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    // text: 'Gráfico Semanal',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4'],
                },
            },
            filters: {
                fullPeriod: ['2024'],
                employee: [],
                store: [],
                platform: [],
                lineChartFilterType: ""
            }
        },
        filters: {
            violationDateRange: [
                dayjs().startOf('month').toDate(),
                dayjs().endOf('month').toDate(),
            ],
            responsibleEmployee: null,
            author: null,
            platform: null,
            store: null,
            errorType: null,
            associatedOrder: 0
        },
        data: {
            employeeData: [],
            authorsData: [],
            platformData: [],
            storeData: [],
            errorTypeData: [],
            orderData: [],
            selectFullData: []
        },
        graphsData: [],
        currentMonth: `Período: ${dayjs().format('MMMM')}`,
        totalInfraction: 0,
        infractionPerMonth: 0,
        initialBarChart: [],
        initialPieChart: [],
        loadingButton: false,
    };

    async componentDidMount(): Promise<void> {
        await this.handleGetInputInformation()
        await this.handleGetGraphsInformation()
        await this.handleGetCardsInformation()
        this.generateData()
    }

    handleSetLoadingTrue = () => {
        this.setState({
            ...this.state,
            loadingButton: true
        })
    }

    handleSetLoadingFalse = () => {
        this.setState({
            ...this.state,
            loadingButton: false
        })
    }

    handleGetInputInformation = async () => {
        try {

            const response = await axios.get("/api/v1/dashboard/get_informations");
            if (response?.data) {
                const formattedUsers = Array.isArray(response.data.users)
                    ? response.data.users.map((user: any) => ({
                        value: user?.user_id || '',
                        label: user?.user_name || 'Unknown User',
                    }))
                    : [];

                const formattedAuthors = Array.isArray(response.data.authors)
                    ? response.data.authors.map((author: any) => ({
                        value: author?.user_id || '',
                        label: author?.user_name || 'Unknown Author',
                    }))
                    : [];

                const formattedPlatforms = Array.isArray(response.data.platforms)
                    ? response.data.platforms.map((platform: any) => ({
                        value: platform?.platform_id || '',
                        label: platform?.platform_name || 'Unknown Platform',
                    }))
                    : [];

                const formattedStores = Array.isArray(response.data.stores)
                    ? response.data.stores.map((store: any) => ({
                        value: store?.store_id || '',
                        label: store?.store_name || 'Unknown Store',
                    }))
                    : [];

                const formattedErrors = Array.isArray(response.data.errors)
                    ? response.data.errors.map((error: any) => ({
                        value: error?.error_id || '',
                        label: error?.error_name || 'Unknown Error',
                    }))
                    : [];

                this.setState({
                    data: {
                        employeeData: formattedUsers,
                        authorsData: formattedAuthors,
                        platformData: formattedPlatforms,
                        storeData: formattedStores,
                        errorTypeData: formattedErrors,
                    },
                });
            } else {
                this.setState({
                    data: {
                        employeeData: [],
                        authorsData: [],
                        platformData: [],
                        storeData: [],
                        errorTypeData: [],
                    },
                });
            }
        } catch (error) {
            Notification.error({
                title: "Erro!",
                description: "Erro ao buscar as informações dos filtros",
                duration: 3500
            })

            console.log("Erro ao buscar as informações para os selects ::::>> ", error)
        }
    }

    handleGetGraphsInformation = async () => {
        this.handleSetLoadingTrue()
        const { violationDateRange, responsibleEmployee, author, platform, store, associatedOrder } = this.state.filters;

        try {
            const graphsResponse = await axios.get("/api/v1/dashboard/graphs_informations", {
                params: {
                    filters: {
                        violationDateRange: violationDateRange,
                        responsibleEmployee: responsibleEmployee,
                        author: author,
                        platform: platform,
                        store: store,
                        associatedOrder: associatedOrder,
                    }
                }
            })

            const { barChart, pieChart } = graphsResponse.data;

            if (graphsResponse.data) {
                this.setState({
                    barChart: {
                        series: [
                            {
                                data: barChart.graphsEmployeeAmountInfringement || [],
                            },
                        ],
                        options: {
                            ...this.state.barChart.options,
                            plotOptions: {
                                ...this.state.barChart.options.plotOptions,
                                bar: {
                                    ...this.state.barChart.options.plotOptions.bar,
                                    distributed: true,
                                },
                            },
                            colors: barChart.setGraphsEmployeeColors || [],
                            xaxis: {
                                ...this.state.barChart.options.xaxis,
                                categories: barChart.setGraphsEmployeeNames || [],
                            },
                        },
                        employeeCategorieData: barChart.setGraphsEmployeeNames || [],
                        employeeSeriesData: barChart.graphsEmployeeAmountInfringement || [],
                        employeeColorsData: barChart.setGraphsEmployeeColors || []
                    },
                    pieChart: {
                        series: pieChart.graphsStoreAmountInfringement || [],
                        options: {
                            ...this.state.pieChart.options,
                            labels: pieChart.setGraphsStoreNames || [],
                            fill: {
                                colors: pieChart.setGraphsStoreColors || [],
                            },
                        },
                        storeCategorieData: pieChart.setGraphsStoreNames || [],
                        storeSeriesData: pieChart.graphsStoreAmountInfringement || [],
                        storeColorData: pieChart.setGraphsStoreColors || []
                    },
                    filters: {
                        ...this.state.filters,
                        responsibleEmployee: null,
                        store: null
                    }
                })
            }
        } catch (error) {
            Notification.error({
                title: "Erro!",
                description: "Erro ao buscar informações para o dashboard.",
                duration: 3500
            })

            console.log("Erro ao buscar informações do dashboard ::::>> ", error)
        }
        this.handleSetLoadingFalse()
    }

    handleGetCardsInformation = async () => {
        try {
            const { data } = await axios.get("/api/v1/dashboard/cards_informations");
            const { totalInfringement, totalInfringementCurrentMonth } = data;

            this.setState({
                totalInfraction: totalInfringement,
                infractionPerMonth: totalInfringementCurrentMonth
            })
        } catch (error) {
            Notification.error({
                title: "Erro!",
                description: "Erro ao buscar informações dos cards",
                duration: 3500
            })

            console.log("Erro ao buscar as informações dos cards :::::>> ", error)
        }
    }

    handleFilterChange = (key: string, value: any) => {
        this.setState(
            (prevState) => ({
                filters: {
                    ...prevState.filters,
                    [key]: value,
                },
            }),
            () => {
                if (key === "responsibleEmployee") {
                    const { employeeData } = this.state.data;
                    const selectedItems = employeeData.filter((item: any) => value.includes(item.value))
                    const selectedLabels = selectedItems.map((item: any) => item.label)
                    this.applyBarChartFilter(selectedLabels);
                } else if (key === "store") {
                    const { storeData } = this.state.data;
                    const selectedItems = storeData.filter((item: any) => value.includes(item.value))
                    const selectedLabels = selectedItems.map((item: any) => item.label)
                    this.applyPieChartFilter(selectedLabels);
                }
            }
        );
    };

    applyBarChartFilter = (selectedEmployeeNames: string[]) => {
        const { barChart } = this.state;
        const newSeries: number[] = [];
        const newCategories: string[] = [];
        const newColors: string[] = [];

        selectedEmployeeNames.forEach(employee => {
            const employeeIndex = barChart.employeeCategorieData.indexOf(employee)
            if (employeeIndex !== -1) {
                newSeries.push(barChart.employeeSeriesData[employeeIndex])
                newCategories.push(employee)
                newColors.push(barChart.employeeColorsData[employeeIndex])
            }
        })

        this.setState({
            barChart: {
                ...barChart,
                series: [
                    {
                        data: newSeries,
                    },
                ],
                options: {
                    ...barChart.options,
                    colors: newColors || [],
                    xaxis: {
                        ...barChart.options.xaxis,
                        categories: newCategories,
                    },
                },
            },
        });
    };

    applyPieChartFilter = (selectedStoreNames: string[]) => {
        const { pieChart } = this.state;
        const newSeries: number[] = [];
        const newColors: string[] = [];

        selectedStoreNames.forEach(store => {
            const storeIndex = pieChart.storeCategorieData.indexOf(store)
            if (storeIndex !== -1) {
                newSeries.push(pieChart.storeSeriesData[storeIndex])
                newColors.push(pieChart.storeColorData[storeIndex])
            }
        })

        this.setState({
            pieChart: {
                ...pieChart,
                series: newSeries,
                options: {
                    ...pieChart.options,
                    labels: selectedStoreNames,
                    fill: {
                        colors: newColors,
                    },
                },
            },
        });
    };

    clearFilters = async () => {
        this.handleSetLoadingTrue();

        await new Promise((resolve) => {
            this.setState(
                {
                    filters: {
                        violationDateRange: [
                            dayjs().startOf('month').toDate(),
                            dayjs().endOf('month').toDate(),
                        ],
                        responsibleEmployee: null,
                        author: null,
                        platform: null,
                        store: null,
                        errorType: null,
                        associatedOrder: 0
                    },
                },
                //@ts-ignore
                resolve
            );
        });

        await this.handleGetGraphsInformation();
        this.handleSetLoadingFalse();
    };

    handleCheckAll = (value: any, checked: any) => {
        const nextValue = checked ? lineChartFilterOptions : [];
        this.setState((prevState) => ({
            lineChart: {
                ...prevState.lineChart,
                filters: {
                    ...prevState.lineChart.filters,
                    weekSelect: {
                        value: nextValue,
                        indeterminate: false,
                        checkAll: checked,
                    },
                },
            },
        }));
    };

    handleChange = (value: any) => {
        this.setState((prevState) => ({
            lineChart: {
                ...prevState.lineChart,
                filters: {
                    ...prevState.lineChart.filters,
                    weekSelect: {
                        value,
                        indeterminate: value.length > 0 && value.length < lineChartFilterOptions.length,
                        checkAll: value.length === lineChartFilterOptions.length,
                    },
                },
            },
        }));
    };

    handleLineChartFilter = async () => {
        this.handleSetLoadingTrue()

        try {
            const { filters } = this.state.lineChart;

            if (!filters?.lineChartFilterType) {
                Notification.warning({
                    title: "Aviso!",
                    description: "Selecione Funcionário, Loja ou Plataforma para buscar as informações",
                    duration: 3500
                })
                this.handleSetLoadingFalse()

                return;
            }

            if (filters.weekSelect?.value.length === 0) {
                Notification.warning({
                    title: "Aviso!",
                    description: "Selecione o período para fazer a busca",
                    duration: 3500
                })
                this.handleSetLoadingFalse()

                return;
            }

            const sortedWeekSelect = filters.weekSelect?.value.sort((a: any, b: any) => Number(a) - Number(b))

            const lineChartResponse = await axios.get("/api/v1/dashboard/get_linechart_information", {
                params: {
                    weekSelect: sortedWeekSelect ? sortedWeekSelect : [],
                    lineChartFilterType: filters?.lineChartFilterType ? filters?.lineChartFilterType : "",
                    employee: filters?.employee ? filters?.employee : [],
                    store: filters?.store ? filters?.store : [],
                    platform: filters?.platform ? filters?.platform : [],
                }
            })

            const { series, categories, colors } = lineChartResponse.data;

            this.setState({
                lineChart: {
                    ...this.state.lineChart,
                    series: series,
                    options: {
                        ...this.state.lineChart.options,
                        colors: colors,
                        xaxis: {
                            categories: categories,
                        }
                    }
                }
            })

        } catch (error) {
            Notification.error({
                title: "Erro!",
                description: "Erro ao buscar informação para o gráfico de linha",
                duration: 3500
            })
            console.log("Erro ao pegar dados para o gráfico de linha", error)
        }

        this.handleSetLoadingFalse()
    }

    handleLineChartPerMonthFilter = async () => {
        this.handleSetLoadingTrue()

        try {
            const { filters } = this.state.lineChartPerMonth;
            if (!filters?.lineChartFilterType) {
                Notification.warning({
                    title: "Aviso!",
                    description: "Selecione Funcionário, Loja ou Plataforma para buscar as informações",
                    duration: 3500
                })
                this.handleSetLoadingFalse()

                return;
            }

            if (filters.fullPeriod.length === 0) {
                Notification.warning({
                    title: "Aviso!",
                    description: "Selecione um período para buscar as informações",
                    duration: 3500
                })
                this.handleSetLoadingFalse()

                return;
            }

            const { fullPeriod, employee, lineChartFilterType, platform, store } = filters;

            const lineChartPerMonthResponse = await axios.get("/api/v1/dashboard/get_linechartpermonth_information", {
                params: {
                    fullPeriod: fullPeriod ? fullPeriod : [],
                    lineChartFilterType: lineChartFilterType ? lineChartFilterType : "",
                    employee: employee ? employee : [],
                    store: store ? store : [],
                    platform: platform ? platform : [],
                }
            })

            const { series, categories, colors } = lineChartPerMonthResponse.data;

            const formattedCategories = categories.map((date: any) => {
                const [year, month] = date.split('-');
                return `${month}/${year}`;
            });

            this.setState({
                lineChartPerMonth: {
                    ...this.state.lineChartPerMonth,
                    series: series,
                    options: {
                        ...this.state.lineChartPerMonth.options,
                        colors: colors,
                        xaxis: {
                            categories: formattedCategories,
                        }
                    }
                }
            })

        } catch (error) {
            Notification.error({
                title: "Erro!",
                description: "Erro ao buscar informação para o gráfico de linha",
                duration: 3500
            })
            console.log("Erro ao pegar dados para o gráfico de linha", error)
        }

        this.handleSetLoadingFalse()
    }

    generateData = () => {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        const startYear = 2024;

        const data: YearNode[] = [];
        for (let year = startYear; year <= currentYear; year++) {
            const yearNode: YearNode = {
                label: `${year}`,
                value: `${year}`,
                children: [],
            };

            const monthsInYear = year === currentYear ? currentMonth : 12;
            for (let month = 1; month <= monthsInYear; month++) {
                yearNode.children.push({
                    label: new Date(year, month - 1).toLocaleString("default", {
                        month: "long",
                    }),
                    value: `${year}-${month.toString().padStart(2, "0")}`,
                });
            }

            data.push(yearNode);
        }

        this.setState({
            data: {
                ...this.state.data,
                selectFullData: data
            }
        })
    };

    render() {
        return (
            <>
                <div style={{ marginLeft: localStorage.getItem("hiddenSidebar") == "true" ? "0px" : "203px" }} className={classNames("container")}>
                    <div className={styles.mainContainer}>
                        <div className={styles.containerCards}>
                            <Card
                                title="Infrações Registradas no Mês"
                                value={this.state.infractionPerMonth}
                                subtitle={this.state.currentMonth}
                                icon={<CloseOutline style={{ fontSize: '1.32rem' }} color="#000" />}
                                backgroundColor="#FFA83E"
                                textColor="#333"
                            />

                            <Card
                                title="Infrações Acumuladas"
                                value={this.state.totalInfraction}
                                subtitle="Desde o início"
                                icon={<CloseOutline style={{ fontSize: '1.32rem' }} color="#000" />}
                                backgroundColor="#FFB957"
                                textColor="#333"
                            />
                        </div>


                        <Divider>Filtros Gerais</Divider>

                        <div className={styles.containerFilters}>
                            <div className={styles.filterLine}>
                                <div className={styles.filterDiv} style={{ width: '500px' }}>
                                    <span>Período</span>
                                    <DateRangePicker
                                        placeholder="Selecione uma data"
                                        format="DD/MM/YYYY"
                                        ranges={[]}
                                        onChange={(value) => this.handleFilterChange('violationDateRange', value)}
                                        value={this.state.filters.violationDateRange}
                                    />
                                </div>

                                <div className={styles.filterDiv} style={{ width: '320px' }}>
                                    <span>Pedido</span>
                                    <InputNumber
                                        value={this.state.filters.associatedOrder}
                                        onChange={(value) => this.handleFilterChange('associatedOrder', value)}
                                    />
                                </div>

                                <div className={styles.filterDiv}>
                                    <span>Autor</span>
                                    <CheckPicker
                                        data={this.state.data.authorsData}
                                        placeholder="Selecione um autor"
                                        onChange={(value) => this.handleFilterChange('author', value)}
                                        value={this.state.filters.author}
                                    />
                                </div>

                                <div className={styles.filterDiv}>
                                    <span>‎ </span>
                                    <div className={styles.filterButtons}>
                                        <Button
                                            appearance="primary"
                                            color="blue"
                                            style={{ width: '100%' }}
                                            onClick={this.handleGetGraphsInformation}
                                            loading={this.state.loadingButton}
                                        >
                                            Filtrar
                                        </Button>

                                        <Button
                                            appearance="primary"
                                            color="orange"
                                            style={{ width: '100%' }}
                                            onClick={this.clearFilters}
                                            loading={this.state.loadingButton}
                                        >
                                            Limpar Filtros
                                        </Button>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <Divider>Gráficos</Divider>

                        <div className={styles.chartsContainer}>
                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                    <div className={styles.filterDiv}>
                                        <span>Funcionário</span>
                                        <CheckPicker
                                            data={this.state.data.employeeData}
                                            placeholder="Selecione um funcionário"
                                            onChange={(value) => this.handleFilterChange('responsibleEmployee', value)}
                                            value={this.state.filters.responsibleEmployee}
                                        />
                                    </div>
                                    <div id="chart">
                                        <ReactApexChart
                                            options={this.state.barChart.options}
                                            series={this.state.barChart.series}
                                            type="bar"
                                            height={500}
                                        />
                                    </div>
                                    <div id="html-dist"></div>
                                </div>

                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                    <div className={styles.filterDiv}>
                                        <span>Loja</span>
                                        <CheckPicker
                                            data={this.state.data.storeData}
                                            placeholder="Selecione uma Loja"
                                            onChange={(value) => this.handleFilterChange('store', value)}
                                            value={this.state.filters.store}
                                        />
                                    </div>
                                    <div id="chart">
                                        <ReactApexChart
                                            options={this.state.pieChart.options}
                                            series={this.state.pieChart.series}
                                            type="pie"
                                            height={500}
                                        />
                                    </div>
                                    <div id="html-dist"></div>
                                </div>
                            </div>

                            <Divider style={{ margin: '0 -1rem' }}>Gráfico Semanal</Divider>

                            <div className={styles.lineChart}>
                                <div className={styles.lineChartFilters}>

                                    <div style={{ display: 'flex', gap: '1rem' }}>
                                        <FormGroup controlId="radioList">
                                            <span>‎ </span>
                                            <RadioGroup
                                                name="radioList"
                                                inline
                                                style={{ display: 'flex' }}
                                                value={this.state.lineChart.filters.lineChartFilterType}
                                                onChange={(value) =>
                                                    this.setState((prevState) => ({
                                                        lineChart: {
                                                            ...prevState.lineChart,
                                                            filters: {
                                                                ...prevState.lineChart.filters,
                                                                lineChartFilterType: value,
                                                            },
                                                        },
                                                    }))
                                                }>
                                                <Radio value="employee">Funcionário</Radio>
                                                <Radio value="store">Loja</Radio>
                                                <Radio value="platform">Plataforma</Radio>
                                            </RadioGroup>
                                        </FormGroup>

                                        <div style={{ width: '550px' }}>
                                            {this.state.lineChart.filters.lineChartFilterType === 'employee' && (
                                                <div className={styles.filterDiv}>
                                                    <span>Funcionários</span>
                                                    <CheckPicker
                                                        data={this.state.data.employeeData}
                                                        placeholder="Funcionários para filtro..."
                                                        style={{ width: '550px' }}
                                                        value={this.state.lineChart.filters.employee}
                                                        onChange={(value) =>
                                                            this.setState((prevState) => ({
                                                                lineChart: {
                                                                    ...prevState.lineChart,
                                                                    filters: {
                                                                        ...prevState.lineChart.filters,
                                                                        employee: value,
                                                                    },
                                                                },
                                                            }))
                                                        }
                                                    />
                                                    <span className={styles.informationText}>
                                                        *Se nenhum filtro for aplicado, todos os registros serão incluídos
                                                    </span>
                                                </div>
                                            )}

                                            {this.state.lineChart.filters.lineChartFilterType === 'store' && (
                                                <div className={styles.filterDiv}>
                                                    <span>Lojas</span>
                                                    <CheckPicker
                                                        data={this.state.data.storeData}
                                                        placeholder="Lojas para filtro..."
                                                        style={{ width: '550px' }}
                                                        value={this.state.lineChart.filters.store}
                                                        onChange={(value) =>
                                                            this.setState((prevState) => ({
                                                                lineChart: {
                                                                    ...prevState.lineChart,
                                                                    filters: {
                                                                        ...prevState.lineChart.filters,
                                                                        store: value,
                                                                    },
                                                                },
                                                            }))
                                                        }
                                                    />
                                                    <span className={styles.informationText}>
                                                        *Se nenhum filtro for aplicado, todos os registros serão incluídos
                                                    </span>
                                                </div>
                                            )}

                                            {this.state.lineChart.filters.lineChartFilterType === 'platform' && (
                                                <div className={styles.filterDiv}>
                                                    <span>Plataformas</span>
                                                    <CheckPicker
                                                        data={this.state.data.platformData}
                                                        placeholder="Plataformas para filtro..."
                                                        style={{ width: '550px' }}
                                                        value={this.state.lineChart.filters.platform}
                                                        onChange={(value) =>
                                                            this.setState((prevState) => ({
                                                                lineChart: {
                                                                    ...prevState.lineChart,
                                                                    filters: {
                                                                        ...prevState.lineChart.filters,
                                                                        platform: value,
                                                                    },
                                                                },
                                                            }))
                                                        }
                                                    />
                                                    <span className={styles.informationText}>
                                                        *Se nenhum filtro for aplicado, todos os registros serão incluídos
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className={styles.filterDiv}>
                                        <span>‎ </span>
                                        <div className={styles.filterButtons}>
                                            <Button
                                                appearance="primary"
                                                color="blue"
                                                style={{ width: '100%' }}
                                                loading={this.state.loadingButton}
                                                onClick={this.handleLineChartFilter}
                                            >
                                                Filtrar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.lineChartContainer}>
                                    <div style={{ width: '100%' }}>
                                        <div id="chart">
                                            <ReactApexChart options={this.state.lineChart.options} series={this.state.lineChart.series} type="line" height={350} />
                                        </div>
                                        <div id="html-dist"></div>
                                    </div>
                                    <div className={styles.checkboxContainer}>
                                        <Checkbox
                                            indeterminate={this.state.lineChart.filters.weekSelect.indeterminate}
                                            checked={this.state.lineChart.filters.weekSelect.checkAll}
                                            onChange={this.handleCheckAll}
                                        >
                                            Marcar tudo
                                        </Checkbox>
                                        <CheckboxGroup
                                            name="checkboxList"
                                            value={this.state.lineChart.filters.weekSelect.value}
                                            onChange={this.handleChange}
                                        >
                                            <Checkbox value="1">Semana 1</Checkbox>
                                            <Checkbox value="2">Semana 2</Checkbox>
                                            <Checkbox value="3">Semana 3</Checkbox>
                                            <Checkbox value="4">Semana 4</Checkbox>
                                        </CheckboxGroup>
                                    </div>
                                </div>
                            </div>

                            <Divider style={{ margin: '0 -1rem' }}>Gráfico Mensal</Divider>

                            <div className={styles.lineChart}>
                                <div className={styles.lineChartMonthFilters}>
                                    <div style={{ display: 'flex', gap: '1rem' }}>
                                        <FormGroup controlId="radioList">
                                            <span>‎ </span>
                                            <RadioGroup
                                                name="radioList"
                                                inline
                                                style={{ display: 'flex' }}
                                                value={this.state.lineChartPerMonth.filters.lineChartFilterType}
                                                onChange={(value) =>
                                                    this.setState((prevState) => ({
                                                        lineChartPerMonth: {
                                                            ...prevState.lineChartPerMonth,
                                                            filters: {
                                                                ...prevState.lineChartPerMonth.filters,
                                                                lineChartFilterType: value,
                                                            },
                                                        },
                                                    }))
                                                }>
                                                <Radio value="employee">Funcionário</Radio>
                                                <Radio value="store">Loja</Radio>
                                                <Radio value="platform">Plataforma</Radio>
                                            </RadioGroup>
                                        </FormGroup>

                                        <div style={{ width: '550px' }}>
                                            {this.state.lineChartPerMonth.filters.lineChartFilterType === 'employee' && (
                                                <div className={styles.filterDiv}>
                                                    <span>Funcionários</span>
                                                    <CheckPicker
                                                        data={this.state.data.employeeData}
                                                        placeholder="Funcionários para filtro..."
                                                        style={{ width: '550px' }}
                                                        value={this.state.lineChartPerMonth.filters.employee}
                                                        onChange={(value) =>
                                                            this.setState((prevState) => ({
                                                                lineChartPerMonth: {
                                                                    ...prevState.lineChartPerMonth,
                                                                    filters: {
                                                                        ...prevState.lineChartPerMonth.filters,
                                                                        employee: value,
                                                                    },
                                                                },
                                                            }))
                                                        }
                                                    />
                                                    <span className={styles.informationText}>
                                                        *Se nenhum filtro for aplicado, todos os registros serão incluídos
                                                    </span>
                                                </div>
                                            )}

                                            {this.state.lineChartPerMonth.filters.lineChartFilterType === 'store' && (
                                                <div className={styles.filterDiv}>
                                                    <span>Lojas</span>
                                                    <CheckPicker
                                                        data={this.state.data.storeData}
                                                        placeholder="Lojas para filtro..."
                                                        style={{ width: '550px' }}
                                                        value={this.state.lineChartPerMonth.filters.store}
                                                        onChange={(value) =>
                                                            this.setState((prevState) => ({
                                                                lineChartPerMonth: {
                                                                    ...prevState.lineChartPerMonth,
                                                                    filters: {
                                                                        ...prevState.lineChartPerMonth.filters,
                                                                        store: value,
                                                                    },
                                                                },
                                                            }))
                                                        }
                                                    />
                                                    <span className={styles.informationText}>
                                                        *Se nenhum filtro for aplicado, todos os registros serão incluídos
                                                    </span>
                                                </div>
                                            )}

                                            {this.state.lineChartPerMonth.filters.lineChartFilterType === 'platform' && (
                                                <div className={styles.filterDiv}>
                                                    <span>Plataformas</span>
                                                    <CheckPicker
                                                        data={this.state.data.platformData}
                                                        placeholder="Plataformas para filtro..."
                                                        style={{ width: '550px' }}
                                                        value={this.state.lineChartPerMonth.filters.platform}
                                                        onChange={(value) =>
                                                            this.setState((prevState) => ({
                                                                lineChartPerMonth: {
                                                                    ...prevState.lineChartPerMonth,
                                                                    filters: {
                                                                        ...prevState.lineChartPerMonth.filters,
                                                                        platform: value,
                                                                    },
                                                                },
                                                            }))
                                                        }
                                                    />
                                                    <span className={styles.informationText}>
                                                        *Se nenhum filtro for aplicado, todos os registros serão incluídos
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className={styles.filterDiv}>
                                        <div className={styles.filterButtons}>
                                            <Button
                                                appearance="primary"
                                                color="blue"
                                                style={{ width: '100%' }}
                                                loading={this.state.loadingButton}
                                                onClick={this.handleLineChartPerMonthFilter}
                                            >
                                                Filtrar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <CheckTreePicker
                                        data={this.state.data.selectFullData}
                                        style={{ width: '100%' }}
                                        placeholder="Período para consulta..."
                                        value={this.state.lineChartPerMonth.fullPeriod}
                                        onChange={(value) => {
                                            this.setState({
                                                lineChartPerMonth: {
                                                    ...this.state.lineChartPerMonth,
                                                    filters: {
                                                        ...this.state.lineChartPerMonth.filters,
                                                        fullPeriod: value
                                                    }
                                                }
                                            })
                                        }}
                                        locale={{
                                            ...CheckTreePicker.defaultProps?.locale,
                                            checkAll: 'Ano Todo',
                                            selectedValues: 'Tudo',
                                        }}
                                    />
                                </div>
                                <div className={styles.lineChartContainer}>
                                    <div style={{ width: '100%' }}>
                                        <div id="chart">
                                            <ReactApexChart options={this.state.lineChartPerMonth.options} series={this.state.lineChartPerMonth.series} type="line" height={350} />
                                        </div>
                                        <div id="html-dist"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}

export default withRouter(GraphsAnalyticsPage);