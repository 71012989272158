import React, { useState } from "react";
import { Alert, Button, DatePicker } from "rsuite";
// import Print from './../../../public/reportAsoControl.png';
import axios from "../../utilities/axios";
import dayjs from "dayjs";

const locale = {
    sunday: "DON",
    monday: "SEG",
    tuesday: "TER",
    wednesday: "QUA",
    thursday: "QUI",
    friday: "SEX",
    saturday: "SAB",
    ok: "Ok",
    today: "Hoje",
    yesterday: "Ontem",
    hours: "Horas",
    minutes: "Minutos",
    seconds: "Segundos",
};

const ReportAsoControlModal = (props: any) => {
    const [filterValues, setFilterValues] = useState<any>({})
    const [isLoading, setIsLoading] = useState(false)

    const onChange = (alias: any, field: any, op: any, value: any) => {
        setFilterValues({ ...filterValues, [alias]: { field: field, op: op, value: value } })
    };


    const onClickButton = () => {
        setIsLoading(true)

        if (props.searchProps) {
            let filterValuesClick = {};
            const { operators, search, browserable, data } = props.searchProps;
            for (const column in search) {
                if (search[column]) {
                    filterValuesClick =
                    {
                        ...filterValuesClick,
                        [column]: {
                            field: column,
                            op: operators[column],
                            value: search[column]
                        }
                    }
                }
            }

            axios.post("/api/v1/reports/generate", {
                crud: props.globals.table,
                params: filterValuesClick,
                type: null,
                showFieldsReport: browserable,
                data: data
            }).then((res) => {
                setTimeout(() => {
                    window.open('https://' + window.location.host.split('25565').join('25567') + res.data.outputUrl)
                    props.onComplete()
                }, 4500);

            }).catch(() => {
                Alert.error("Falha ao gerar arquivo XLSX");
            }).finally(() => {
                setTimeout(() => {
                    setIsLoading(false)
                }, 4500)
            })
        }
    }

    return (<>
        <div style={
            {
                display: "flex",
                flexDirection: 'column'
            }
        }>
            <p
                style={
                    {
                        fontWeight: '600'
                    }
                }
            >
                O relatório será realizado de acordo com os filtros aplicados na tela.
            </p>
            <div>
                <img
                    alt="imagem mostra o formato padrao para impressao"
                    src="/reportAsoControl.png"
                    style={{
                        width: '100%',
                        height: "40%",
                    }}
                />
            </div>
        </div>
        <div style={{ float: "right", marginTop: '1rem', marginBottom: '-1.5rem' }}>
            <Button loading={isLoading} onClick={(() => {
                onClickButton()
            })} appearance="primary" color="green">Gerar Excel</Button>
        </div>
    </>)
}

export { ReportAsoControlModal }