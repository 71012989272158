import { Event } from "../Event";

export class UserEvents extends Event {
    public onLoad = () => {
        this.setState("loadings", ["user_password", "user_fk_role_id"])

        const { url } = this.globalProps.match
        const isAdd = url.includes("/add")

        if (!isAdd) { // Caso seja EDIT
            const { user_password } = this.values

            if (user_password === null) {
                this.setFieldValue("user_password", "senhapadrão")
            }
        } else { // Caso seja ADD
            this.setFieldValue("user_fk_role_id", 1)
        }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 100)
    }
}