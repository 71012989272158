import axios from "../../utilities/axios";
import { Event } from "../Event";

export class UserFkProjectIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings',[
            'user_restricts'
        ])
        this.setFieldValue('user_restricts',[]);
        
        this.setState('loadings',[])
        
    }
}