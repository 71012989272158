// import * as _ from "lodash";

export class Conditionizer {
    static set(form: any) {
        return new Conditionizer(form);
    }

    public form: any;

    constructor(form: any) {
        this.form = form;
    }

    public isBeingUsedOrHasCondition(fieldName: string) {
        var isUsed = false;
        if (this.form[fieldName].conditions) {
            return true;
        }
        for (var i in this.form) {
            var field = this.form[i];
            if (field.conditions) {
                for (var x in field.conditions) {
                    if (field.conditions[x].field === fieldName) {
                        isUsed = true;
                    }
                }
            }
        }

        return isUsed;
    }

    public findCondition(field: any, name: string) {
        var found = [];
        if (field.conditions) {
            for (var i in field.conditions) {
                if (field.conditions[i].property === name) {
                    found.push(field.conditions[i]);
                }
            }
        }
        return found;
    }

    public replaceOperator(operator: string) {
        switch (operator) {
            case "null":
                return "empty";
            case "!null":
                return "notempty";
            case "==":
                return "equal";
            case "$eq":
                return "equal";
            default:
                return operator;
        }
    }

    public getProps(fieldName: string, values: any): any {
        let field = this.form[fieldName];
        const reference: any = this;
        var conditionResults: any = {};
        if(!field) {
            alert(fieldName);
            return null;
        }
        if (field?.conditions) {
            const { conditions } = field;
            for (const condition of conditions) {
                // //console.log(condition);
                if (condition.method && condition.property && condition.cases) {
                    const { cases }: any = condition;
                    for (const conditionCase of cases) {
                        const methodName = conditionCase.operator + "FieldCondition";
                        var fieldValue = values[conditionCase.field];
                        if (fieldValue && typeof fieldValue == "string") {
                            fieldValue = fieldValue.replace(/<[^>]*>?/gm, "");
                        }
                        if (!field.__conditions) {
                            field.__conditions = {};
                        }
                        field.__conditions[conditionCase.field] = fieldValue;
                        if (reference[methodName] && typeof reference[methodName] == "function") {
                            const result = reference[methodName](fieldValue, conditionCase.value ?? undefined);
                            if (typeof condition.value == "boolean") {
                                if (!conditionResults[condition.property]) {
                                    conditionResults[condition.property] = {};
                                }
                                if (!conditionResults[condition.property][condition.method]) {
                                    conditionResults[condition.property][condition.method] = [];
                                }
                                conditionResults[condition.property].value = condition.value;
                                conditionResults[condition.property][condition.method].push(result);
                            }
                        } else {

                            //alert(methodName);
                            // console.log(methodName)

                        }
                    }
                }
            }
        }
        for (var property in conditionResults) {
            var condition = conditionResults[property];
            if (condition.$or) {
                var isTrue = condition.$or.filter((item: any) => item === true).length > 0;
                // condition.isTrue = isTrue;
                // condition.prop = field[property];

                if (condition.value !== undefined && typeof condition.value == "boolean") {
                    // condition.tProp = isTrue ? condition.value : !condition.value;
                    field[property] = isTrue ? condition.value : !condition.value;
                }
            }
        }
        field.__conditions = conditionResults;
        return field;
    }

    // public getPropsOld(fieldName: string, values: any): any {
    //     let field = this.form[fieldName];
    //     var shouldRender = true;

    //     if (field.conditions) {
    //         // //(field.conditions);
    //         var conditions = field.conditions;
    //         if (conditions) {
    //             for (var i in conditions) {
    //                 if (shouldRender) {
    //                     var condition = conditions[i];
    //                     var objectOf: any = this;
    //                     if (condition.query && !condition.operator) {
    //                         var shouldRenderConditions = { $or: false, $and: true };
    //                         if (condition.operator) {
    //                             // //console.log("condition.operator",condition.operator)
    //                             // var methodName = this.replaceOperator(condition.operator) + _.startCase(condition.type) + "Condition";
    //                         } else {
    //                             if (!condition.query["$or"]) {
    //                                 condition.query["$or"] = condition.query;
    //                             }
    //                             for (var conditionType in condition.query) {
    //                                 var queries = condition.query[conditionType];
    //                                 // var conditionResult = false;
    //                                 for (var queryIndex in queries) {
    //                                     var query = queries[queryIndex];
    //                                     for (var fieldName in query) {
    //                                         // //({query,fieldName,queryIndex,conditionType});

    //                                         var conditionData = query[fieldName];
    //                                         var operatorType = Object.keys(conditionData)[0];
    //                                         var value = Object.values(conditionData)[0];
    //                                         // //({exists: (objectOf[operatorType] && typeof objectOf[operatorType])})
    //                                         if (objectOf[operatorType] && typeof objectOf[operatorType] !== undefined) {
    //                                             var result = objectOf[operatorType](values[fieldName], value ?? undefined);
    //                                             // //({qr: condition.query,conditionType,result});
    //                                             if (conditionType === "$or" && result === true) {
    //                                                 shouldRenderConditions.$or = true;
    //                                             }
    //                                             if (conditionType === "$and" && result === true) {
    //                                                 shouldRenderConditions.$and = true;
    //                                             }
    //                                         } else {
    //                                             console.warn("Operator " + operatorType + " not found!");
    //                                         }
    //                                     }
    //                                     // //(query);
    //                                 }
    //                             }
    //                         }
    //                         if (shouldRenderConditions.$and === true) {
    //                             field[condition.property] = condition.value;
    //                         } else {
    //                             field[condition.property] = !condition.value;
    //                         }
    //                         if (shouldRenderConditions.$or === true) {
    //                             // //("É TRUE!",condition.property);
    //                             field[condition.property] = condition.value;
    //                         } else {
    //                             field[condition.property] = !condition.value;
    //                         }
    //                         // //(condition.query);
    //                     } else {
    //                         var methodName = this.replaceOperator(condition.operator) + _.startCase(condition.type) + "Condition";
    //                         var tester = condition.query?.$or ?? condition.query;
    //                         var andConditionResult = false;
    //                         if (condition.query && tester) {
    //                             if (_.isArray(tester)) {
    //                                 for (var i in tester) {
    //                                     var row = tester[i];
    //                                     var fields = Object.keys(row)[0];
    //                                     if (fields) {
    //                                         var objectOf: any = this;
    //                                         if (objectOf[methodName] && typeof objectOf[methodName]) {
    //                                             if (condition.type === "field" && condition.query[fields]) {
    //                                                 if (condition.query[fields].$eq) {
    //                                                     var methodResult = objectOf[methodName](
    //                                                         values[fields],
    //                                                         condition.query[fields].$eq !== undefined ? condition.query[fields].$eq : undefined,
    //                                                     );
    //                                                 } else {
    //                                                     var methodResult = objectOf[methodName](values[fields], condition.condition !== undefined ? condition.condition : undefined);
    //                                                 }
    //                                                 if (typeof condition.value === "boolean") {
    //                                                     field[condition.property] = methodResult === true ? condition.value : !condition.value;
    //                                                 }
    //                                             } else {
    //                                                 // throw Error(JSON.stringify(tester));
    //                                             }
    //                                         }
    //                                     }
    //                                 }
    //                             } else {
    //                                 var fields = Object.keys(tester)[0];
    //                                 if (fields) {
    //                                     var objectOf: any = this;
    //                                     if (objectOf[methodName] && typeof objectOf[methodName]) {
    //                                         if (condition.type === "field" && condition.query[fields]) {
    //                                             if (condition.query[fields].$eq) {
    //                                                 var methodResult = objectOf[methodName](
    //                                                     values[fields],
    //                                                     condition.query[fields].$eq !== undefined ? condition.query[fields].$eq : undefined,
    //                                                 );
    //                                             } else {
    //                                                 var methodResult = objectOf[methodName](values[fields], condition.condition !== undefined ? condition.condition : undefined);
    //                                             }
    //                                             if (typeof condition.value === "boolean") {
    //                                                 field[condition.property] = methodResult === true ? condition.value : !condition.value;
    //                                             }
    //                                         } else {
    //                                             // throw Error(JSON.stringify(tester));
    //                                         }
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     return field;
    // }

    public isFieldCondition(fieldValue: any, equalValue: any) {
        if(typeof fieldValue == 'string') {
            fieldValue = fieldValue.toLocaleUpperCase();
        }
        if(typeof equalValue == 'string') {
            equalValue = equalValue.toLocaleUpperCase();
        }
        return fieldValue && fieldValue === equalValue ? true : false;
    }

    public notFieldCondition(fieldValue: any, equalValue: any) {
        if(typeof fieldValue == 'string') {
            fieldValue = fieldValue.toLocaleUpperCase();
        }
        if(typeof equalValue == 'string') {
            equalValue = equalValue.toLocaleUpperCase();
        }
        // console.log(fieldValue && fieldValue.toString().toLocaleLowerCase() !== equalValue.toString().toLocaleLowerCase());
        return fieldValue && fieldValue.toString().toLocaleLowerCase() !== equalValue.toString().toLocaleLowerCase() ? true : false;
    }

    public gtFieldCondition(fieldValue: string | number | boolean, gtValue: any) {
        if (typeof fieldValue == "string" || typeof fieldValue == "boolean") {
            fieldValue = fieldValue.toString().length;
        }
        try {
            if (typeof gtValue == "string") {
                gtValue = parseInt(gtValue);
            }
            return fieldValue && fieldValue > gtValue ? true : false;
        } catch (e) {
            return false;
        }
    }

    public equalFieldCondition(fieldValue: any, equalValue: any) {
        if (fieldValue) {
            if (typeof fieldValue !== "string") {
                fieldValue = fieldValue.toString();
            }
        }
        if(fieldValue && typeof fieldValue == 'string') {
            fieldValue = fieldValue.toLocaleUpperCase();
        }
        if(equalValue && typeof equalValue == 'string') {
            equalValue = equalValue.toLocaleUpperCase();
        }
        return fieldValue && fieldValue === equalValue ? true : false;
    }

    public notemptyFieldCondition(fieldValue: any) {
        return fieldValue && fieldValue.toString().length > 0 ? true : false;
    }

    public emptyFieldCondition(fieldValue: any) {
        return !this.notemptyFieldCondition(fieldValue);
    }

    public likeFieldCondition(fieldValue: any, parameter: any) {
        var matchRegex = "^" + parameter.split("%").join(".*") + "$";
        //(parameter,matchRegex);
        var regex = new RegExp(matchRegex);
        return regex.test(fieldValue);
    }

    public $eq(fieldValue: any, parameter: any) {
        return this.equalFieldCondition(fieldValue, parameter);
    }

    public eqFieldCondition(fieldValue: any, parameter: any) {
        return this.equalFieldCondition(fieldValue, parameter);
    }

    public $like(fieldValue: any, parameter: any) {
        return this.likeFieldCondition(fieldValue, parameter);
    }
}
