import { Event } from "../Event";

export class NationalHolidayLevelNEvents extends Event {

    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        const type: number = Number(value);
        this.setState("loadings", ["national_holiday_level"])
        switch (type) {
            case 1:
               this.setFieldValue("national_holiday_level", "NACIONAL"); 
               break;

            case 2:
                this.setFieldValue("national_holiday_level", "ESTADUAL");
                break;
                
            case 3:
                this.setFieldValue("national_holiday_level", "MUNICIPAL");
                break;
            
            default:
                this.setFieldValue("national_holiday_level", "erro");
                break
        }
        
        this.setState("loadings", ["national_holiday_level"])

        setTimeout(()=>{
            this.setState("loadings", [])
        },200)

    }

}