import { Table } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

const { Column, HeaderCell, Cell, Pagination } = Table;

interface CustomRedCellProps {
    rowData: any,
    dataKey: string,
    view: string
}

const CustomRedCell = ({ rowData, dataKey, view, ...props }: CustomRedCellProps) => {
    // Verifica o valor para cada dataKey
    const classification = rowData[dataKey];

    if (classification === 'yellow') {
        return (
            <Cell {...props} style={{ color: 'goldenrod', backgroundColor: '#FFF5C8' }}>
                {rowData[view]}
            </Cell>
        );
    } else if (classification === 'green') {
        return (
            <Cell {...props} style={{ color: 'green', backgroundColor: '#C8FFC8' }}>
                {rowData[view]}
            </Cell>
        );
    } else {
        return (
            <Cell {...props} style={{ color: 'red', backgroundColor: '#FFC8C8' }}>
                {rowData[view]}
            </Cell>
        );
    }
};

export default CustomRedCell;
