import { Event } from "../Event";
import axios from "../../utilities/axios";
import { CreateScreen } from "./utilities/CreateScreen"
import { DisciplinaryMeasuresTypes } from "../../core/enums/DisciplinaryMeasuresEnum"

export class DisciplinaryMeasureFkDisciplinaryMeasureTypeIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {

        this.setState("loadings", ["disciplinary_measure_type_article"])

        this.setFieldValue("disciplinary_measure_type_article", "Art. 482 – Constituem justa causa para rescisão do contrato de trabalho pelo empregador:")

        setTimeout(() => {
            this.setState("loadings", [])
        }, 300)


        const createScreen = new CreateScreen(
            {
                thisSet: this
            }
        )

        createScreen.clearScreen()

        const selectValue = Number(value);

        switch (selectValue) {
            case DisciplinaryMeasuresTypes.Article:
                createScreen.screenArticle();
                break;
            case DisciplinaryMeasuresTypes.Suspension:
                createScreen.screenSuspension();
                break;
            case DisciplinaryMeasuresTypes.Advertence:
                createScreen.screenAdvertence();
                break;
        }

        if (value == 2 || value == 3) {
            setState("loadings", ["disciplinary_measure_file_attachment"])
            this.setField("disciplinary_measure_file_attachment", {
                order: 200
            })

            setTimeout(() => {
                setState("loadings", [])
            }, 500)
        }

        if (value == 2) {
            setState("loadings", ["disciplinary_measure_suspension_text"])
            this.setField("disciplinary_measure_suspension_text", {
                order: 190
            })

            setTimeout(() => {
                setState("loadings", [])
            }, 500)
        }
    };
}