import React from "react";
import { TSizeTypes } from "../../pages/processAdmission/DefaultPageAdmission";

interface TopBarProps {
    sizeWindow: TSizeTypes
}

interface TopBarState {
    fontSizeTitle: number;
    imageWidth: number;
}

export class TopBar extends React.Component<TopBarProps, TopBarState> {
    constructor(props: TopBarProps) {
        super(props);
        this.state = {
            fontSizeTitle: 18,
            imageWidth: 70
        }
    }

    componentDidMount(): void {
        this.updateSize()
        window.addEventListener('resize', this.updateSize.bind(this));
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.updateSize.bind(this));
    }

    updateSize() {
        const width = window.innerWidth;
        if (width >= 1024) {
            this.setState({
                fontSizeTitle: 30,
                imageWidth: 150
            });
        } else if (width >= 768) {
            this.setState({
                fontSizeTitle: 24,
                imageWidth: 120
            });
        } else {
            this.setState({
                fontSizeTitle: 18,
                imageWidth: 70
            });
        }
    }

    render(): React.ReactNode {
        return (
            <div
                style={{
                    height: '20vh',
                    justifyContent: 'center',
                    paddingLeft: 20,
                    paddingRight: 20,
                    display: 'flex',
                    flexDirection: "row",
                    alignItems: 'center',
                    gap: 20
                }}
            >
                <p
                    style={{
                        fontSize: this.state.fontSizeTitle,
                        fontWeight: '600'
                    }}
                >
                    Processo de Admissão Digital
                </p>
                <img
                    alt="Logo"
                    src="https://i.imgur.com/VTi7R21.png"
                    height={this.state.imageWidth}
                />
            </div>
        )
    }
}
