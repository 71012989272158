import React, { ReactNode, useState } from "react";
import { Table, Modal, Button, Input, Notification } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { FileInput } from "../../formfields/FileInput";
import { AxiosError } from "axios";
import axios from "../../../utilities/axiosReport";
import CustomRedCell from "./CustomRedCell";
import TablePagination from "rsuite/lib/Table/TablePagination";
import { file } from "jszip";
import { IconButton, ButtonGroup, ButtonToolbar } from 'rsuite';

import styles from "./customStyles/styles.module.scss";

const { Column, HeaderCell, Cell, Pagination } = Table;

interface ModalImportAttachmentsProps {
    title?: string;
    crud: string;
    show: boolean;
    onCLoseModal: () => void;
    functionNameImport: string;
}

type State = {
    // showModal: boolean;
    uid: string;
    loading: boolean;
    importedData: any[];
    showDataModal: boolean;
    displayLength: number;
    page: number;
    filter: string;
    statusFilter: string;
    itemsData: any[];
    showConferenceModal: boolean;
    loadingButton: boolean;
    filterColor: string;
    conferenceItems: any[];
    sectors: any[];
    functions: any[];
    shouldForceUpdate: boolean;
    tableRendered: boolean;
    isAdjustmentColumn: boolean;
}


const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}


export class ModalImportAttachmentUser extends React.Component<ModalImportAttachmentsProps, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            uid: uuidv4(),
            loading: false,
            importedData: [],
            showDataModal: false,
            displayLength: 10,
            page: 1,
            filter: '',
            statusFilter: '',
            itemsData: [],
            showConferenceModal: false,
            loadingButton: false,
            filterColor: '',
            conferenceItems: [],
            sectors: [],
            functions: [],
            shouldForceUpdate: false,
            tableRendered: false,
            isAdjustmentColumn: false
        };
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeLength = this.handleChangeLength.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this)
        this.handleStatusFilterChange = this.handleStatusFilterChange.bind(this)
        this.handleCleanFilter = this.handleCleanFilter.bind(this)
    }

    handleChangePage(dataKey: any) {
        this.setState({
            page: dataKey
        });
    }

    handleChangeLength(dataKey: any) {
        this.setState({
            page: 1,
            displayLength: dataKey
        });
    }

    handleFixColumns() {
        // Função para ajustar colunas e forçar o update
        this.setState({ shouldForceUpdate: true });
    }

    handleFilterChange(value: string) {
        this.setState({ statusFilter: '' })
        this.setState({ filter: value })
    }

    handleStatusFilterChange(status: string) {
        this.setState({ statusFilter: status })
    }

    handleCleanFilter() {
        this.setState({ statusFilter: '', filter: '' })
    }

    handleFilterColor(color: string) {
        this.setState({ filterColor: color })
    }

    onChangeValues = ({ value, guid }: { value?: any, guid: string }) => {
        this.setState(
            {
                uid: guid
            }
        )
    }

    confirmImport = async () => {
        try {
            const res = await axios.get('/api/v1/import/confirm-import')

            Notification.success({
                title: "Sucesso!",
                description: "Importação realizada com sucesso!",
                placement: "topEnd",
                duration: 3500
            })

            this.setState({ showDataModal: false, loadingButton: false })
            this.onCLoseModal()
            window.location.reload()
        } catch (err) {
            console.error("Errooooo:::>> ", err)
            this.onCLoseModal()
        }
    }

    cancelImport = () => {
        axios.get('/api/v1/import/cancel-import')
        this.onCLoseModal()
    }

    onCLoseModal = () => {
        this.props.onCLoseModal();
    }


    onProcessArchive = () => {
        this.setState({ loading: true })
        axios
            .get(`/api/v1/import/${this.props.crud}/${this.state.uid}/${this.props.functionNameImport}`)
            .then((res: any) => {
                this.setState({
                    loading: false,
                    importedData: res.data?.result,
                    showDataModal: true,
                    showConferenceModal: false,
                    loadingButton: false
                })
            })
    }

    cleanConferenceSector = () => {
        axios.get("/api/v1/import/conference/cancel");
        this.onCLoseModal()
    }


    confirmConference = () => {
        axios.get("/api/v1/import/conference/confirm")
            .then((res: any) => {
                this.onProcessArchive();
            }).catch(() => {

                this.setState({
                    loadingButton: false
                })
            })
    }

    beforeRenderModal = () => {
        this.setState({
            loading: true
        })
        axios.get(`/api/v1/import/conference/users/${this.state.uid}/users`)
            .then((res: any) => {

                const functionsRegistered = res.data.functionsRegistered;
                const sectorsRegistered = res.data.sectorsRegistered;

                this.setState({
                    showConferenceModal: true,
                    showDataModal: false,
                    sectors: sectorsRegistered,
                    functions: functionsRegistered,
                    loading: false
                })
            })

    }


    componentDidUpdate(prevProps: any, prevState: any) {
        // Se necessário, podemos realizar mais ações aqui
        if (this.state.tableRendered && !prevState.tableRendered) {
            // A tabela foi renderizada pela primeira vez
        }
    }

    renderItemModal = () => {
        const { showConferenceModal, functions, sectors } = this.state


        return (
            <Modal
                full
                show={showConferenceModal}
                onHide={() => this.setState({ showDataModal: false })}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Conferência de novos setores e funções cadastrados</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Table
                        height={500}
                        data={functions}
                        locale={{ emptyMessage: "Nenhuma nova função cadastrada" }}
                    >
                        <Column flexGrow={1}>
                            <HeaderCell>Função</HeaderCell>
                            <Cell dataKey="function_description"></Cell>
                        </Column>
                    </Table>

                    {/* <div className={styles.line}></div>

                    <Table
                        height={250}
                        data={sectors}
                        locale={{ emptyMessage: "Nenhum novo setor cadastrado" }}
                    >
                        <Column flexGrow={1}>
                            <HeaderCell>Setor</HeaderCell>
                            <Cell dataKey="sector_description"></Cell>
                        </Column>
                    </Table> */}
                </Modal.Body>

                <Modal.Footer>
                    <Button loading={this.state.loadingButton} style={{ marginTop: '15px' }} appearance="primary" color="red" onClick={() => {
                        this.cleanConferenceSector()
                        this.setState({ loadingButton: false, showConferenceModal: false })
                    }}>Cancelar</Button>

                    <Button loading={this.state.loadingButton} style={{ marginTop: '15px' }} appearance="primary" color="green" onClick={() => {
                        this.confirmConference()
                        this.setState({ loadingButton: true })
                    }}>Cadastrar e Continuar Importação</Button>
                </Modal.Footer>
            </Modal>
        )
    }


    renderDataModal = () => {
        if (!this.state.isAdjustmentColumn) {
            setTimeout(() => {
                this.forceUpdate()
                this.setState({
                    isAdjustmentColumn: true
                })
            }, 1000)

        }

        const { importedData, showDataModal, displayLength, page, filter, statusFilter } = this.state;

        const verifyImportedData = importedData ? importedData : []

        const filteredData = verifyImportedData.filter((item: any) => {
            const matchesFilter = filter === '' || Object.values(item).some(value =>
                String(value).toLowerCase().includes(filter.toLowerCase())
            );
            const matchesStatus = statusFilter === '' || item.user_classification === statusFilter;
            return matchesFilter && matchesStatus;
        })

        const data = filteredData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        });

        return (
            <Modal
                full
                show={showDataModal}
                onHide={() => this.setState({ showDataModal: false })}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Conferência de Importação de Usuários
                        <br />
                        <span className={styles.redNotice}>*Registros em vermelho não serão importados!</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        placeholder="Filtrar..."
                        value={filter}
                        onChange={this.handleFilterChange}
                        style={{ marginBottom: '15px' }}
                    />
                    <ButtonToolbar style={{ marginBottom: '15px' }}>
                        <Button color="red" appearance="ghost" onClick={() => this.handleStatusFilterChange('red')}>Importação Incorreta</Button>
                        {/* <Button color="yellow" appearance="ghost" onClick={() => this.handleStatusFilterChange('yellow')}>Informações Faltando</Button> */}
                        {/* <Button color="yellow" appearance="ghost" onClick={() => this.handleStatusFilterChange('yellow')}>Informações Faltando</Button> */}
                        <Button color="green" appearance="ghost" onClick={() => this.handleStatusFilterChange('green')}>Corretos</Button>
                        <Button color="blue" appearance="ghost" onClick={() => this.handleCleanFilter()}>Limpar Filtros</Button>
                        {/* <Button appearance="subtle" onClick={() => this.handleFixColumns()}>Ajustar Colunas</Button> */}
                    </ButtonToolbar>
                    <Table
                        height={400}
                        // autoHeight
                        // defaultExpandAllRows
                        data={data}
                        locale={{ emptyMessage: "Nenhum registro novo/alterado" }}
                    >
                        <Column width={70} align="center" fixed>
                            <HeaderCell>Código</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_employee_code" />
                        </Column>

                        <Column width={200} resizable>
                            <HeaderCell>Situação</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_classification_description" />
                        </Column>

                        <Column width={300}>
                            <HeaderCell>Nome</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_name" />
                        </Column>

                        <Column width={170}>
                            <HeaderCell>CPF</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_cpf_cnpj" />
                        </Column>

                        <Column width={110}>
                            <HeaderCell>CBO</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_cbo" />
                        </Column>

                        <Column width={200}>
                            <HeaderCell>Categoria</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_category" />
                        </Column>

                        <Column width={125}>
                            <HeaderCell>Salário</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_custom_salary" />
                        </Column>

                        <Column width={200}>
                            <HeaderCell>Tipo Afastamento</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_removal_type" />
                        </Column>

                        <Column width={200}>
                            <HeaderCell>Empresa</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_company_description" />
                        </Column>

                        <Column width={325}>
                            <HeaderCell>Função</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_function_description" />
                        </Column>

                        <Column width={150}>
                            <HeaderCell>Admissão</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_admission_description" />
                        </Column>

                        <Column width={150}>
                            <HeaderCell>Demissão</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_inactive_description" />
                        </Column>

                        <Column width={200}>
                            <HeaderCell>Base</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_base" />
                        </Column>

                        <Column width={250}>
                            <HeaderCell>Setor</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_department_description" />
                        </Column>

                        <Column width={300}>
                            <HeaderCell>Gerente</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_management_description" />
                        </Column>

                        <Column width={125}>
                            <HeaderCell>Banco de Horas</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_has_time_bank_description" />
                        </Column>

                        <Column width={125}>
                            <HeaderCell>Jornada Flexivel</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_hour_flexibile_description" />
                        </Column>

                        <Column width={125}>
                            <HeaderCell>Status</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"user_classification"} view="user_situation" />
                        </Column>
                    </Table>

                    <TablePagination
                        lengthMenu={[
                            {
                                value: 10,
                                label: 10
                            },
                            {
                                value: 20,
                                label: 20
                            },
                            {
                                value: 50,
                                label: 50
                            },
                            {
                                value: 100,
                                label: 100
                            }
                        ]}
                        activePage={page}
                        displayLength={displayLength}
                        total={importedData.length}
                        onChangePage={this.handleChangePage}
                        onChangeLength={this.handleChangeLength}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ marginTop: '15px' }} appearance="primary" color="red" onClick={() => {
                        this.cancelImport()
                        this.setState({ showDataModal: false, isAdjustmentColumn: false })
                    }}>Cancelar Importação</Button>


                    <Button loading={this.state.loadingButton} style={{ marginTop: '15px' }} appearance="primary" color="green" onClick={() => {
                        this.setState({ loadingButton: true })
                        this.confirmImport()
                    }}>Confirmar Importação</Button>


                </Modal.Footer>
            </Modal>

        );
    }

    render(): ReactNode {
        return (
            <Modal
                show={this.props.show}
            >
                <Modal.Title>
                    Importação {this.props?.title}
                </Modal.Title>
                <Modal.Body>
                    <FileInput
                        multiple={false}
                        guid={this.state.uid}
                        showQuestions={false}
                        readonly={false}
                        name={`${this.props.functionNameImport}_uploader`}
                        relation={this.props.crud}
                    // onChangeCustom={({value,guid}) => this.onChangeValues({value,guid})}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={this.onCLoseModal}
                    >Fechar</Button>
                    {this.state.uid !== '' && (
                        <Button
                            color="green"
                            onClick={this.beforeRenderModal}
                            loading={this.state.loading}
                        >
                            Processar
                        </Button>
                    )}
                </Modal.Footer>
                {this.state.showDataModal && this.renderDataModal()}
                {this.state.showConferenceModal && this.renderItemModal()}
                {this.state.showConferenceModal && this.renderItemModal()}
            </Modal>
        )
    }
}