import { Component, ReactNode } from "react";
import { Toggle } from "rsuite";


interface ICheckedCellProps {
    value: boolean;
    handleActionOnChange: (value: boolean) => void;
    disabled: boolean;
}

interface ICheckedCellState {
    value: boolean;
}


export class CheckedCell extends Component<ICheckedCellProps, ICheckedCellState> {
    state = {
        value: this.props.value
    }


    handleOnChange = (value: boolean) => {
        this.setState({
            value
        })
        this.props.handleActionOnChange(value)
    }

    render(): ReactNode {
        return (
            <>
                <Toggle
                    checked={this.state.value}
                    size="md"
                    disabled={this.props.disabled}
                    onChange={this.handleOnChange}
                />
            </>
        )
    }
}