import React from "react";
import { Button, Notification } from "rsuite";
import axios from "../../utilities/axios";
import { AxiosResponse } from "axios";

interface UpdateStatusButtonProps {
    requestSituationId: number;
    jobRequestId: number;
    foreignKey: number;
    reloadPage?: () => void;
    textButton: string;
    userFunction: number | null;
    onSubmit: any
    this: any
}

type State = {
    textButton: string;
    loading: boolean;
    userFunction: number | null;
}

export class UpdateStatusButton extends React.Component<UpdateStatusButtonProps, State> {
    state = {
        textButton: this.props.textButton,
        loading: false,
        userFunction: this.props.userFunction
    }

    componentDidMount(): void {
        // this.selectIsText()
    }

    updateSituation = () => {
        this.setState(
            {
                loading: true
            }
        )

        const { job_request_wage: wage, job_request_previous_date: previousDate } = this.props.this.state.formValues;

        if (wage === null || previousDate === null) {
            Notification.error({
                title: "Erro",
                description: "Preencha os campos de Salário e Data Prevista de Admissão para aprovar!"
            })

            this.setState({ loading: false })
        } else {
            this.props.onSubmit(() => {
                axios
                    .get('/api/v1/situations/job_request/update', {
                        params: {
                            currentId: this.props.requestSituationId,
                            foreignKey: this.props.foreignKey,
                            userFunction: this.props.userFunction
                        }
                    })
                    .then((res: AxiosResponse) => {
                        if (res.status === 200) {
                            if (this.props.reloadPage) {
                                this.props.reloadPage()
                            }
                        }
                    })
                    .finally(async () => {
                        const jobRequestId = this.props?.this?.props?.values?.formValues?.job_request_id

                        if (jobRequestId) {
                            if (this.state.userFunction === 4) {
                                await axios.get("/api/v1/requests/job_request/send_mail/approvalRH/" + jobRequestId)
                            } else if (this.state.userFunction === 2) {
                                await axios.get("/api/v1/requests/job_request/send_mail/approvalDirector/" + jobRequestId)
                            }
                        }


                        this.setState({ loading: false })
                    })
            })
        }
    }

    render(): React.ReactNode {
        return (
            <>
                <Button
                    color="green"
                    onClick={this.updateSituation}
                    loading={this.state.loading}
                >
                    {this.state.textButton}
                </Button>
            </>
        )
    }
}