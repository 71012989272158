import dayjs from "dayjs";
import axios from "../../utilities/axios";
import { Event } from "../Event";
import { MethodsUtils } from "./utils/methodsUtils";

export class InfringementControlEvents extends Event {
    public onLoad = async () => {
        this.setState("loadings", ["infringement_control_date", "infringement_control_author_fk_user_id", "infringement_control_period", "infringement_control_day_of_the_week"])
        const { url } = this.globalProps.match
        const isAdd = url.includes("/add")

        if (isAdd) {
            const me = await axios.get("/api/v1/me")
            const user = me.data?.user
            const formattedToday = dayjs().format("YYYY-MM-DD HH:mm")
            const dateInformations = MethodsUtils.getDateInformations(formattedToday)

            if (user) {
                this.setFieldValue("infringement_control_author_fk_user_id", user.user_id)
            }

            if (dateInformations.status === 200) {
                this.setFieldValue("infringement_control_date", formattedToday)
                this.setFieldValue("infringement_control_period", dateInformations.period)
                this.setFieldValue("infringement_control_day_of_the_week", dateInformations.dayOfWeek)
            }
        }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 100)
    }
}