import React from "react";
import { Button, Notification } from "rsuite";
import axios from "../../utilities/axios";
import { AxiosResponse } from "axios";


interface UpdateStatusButtonProps {
    requestSituationId: number;
    jobRequestId: number;
    foreignKey: number;
    reloadPage?: () => void;
    textButton: string;
    userFunction: number | null;
    this: any
    onSubmit: any
}

type State = {
    textButton: string;
    loading: boolean;
    userFunction: number | null;
}

export class UpdateStatusButton extends React.Component<UpdateStatusButtonProps, State> {
    state = {
        textButton: this.props.textButton,
        loading: false,
        userFunction: this.props.userFunction
    }

    componentDidMount(): void {
        // this.selectIsText()
    }


    // selectIsText = () => {
    //     let textButton = ''
    //     switch (this.props.requestSituationId) {
    //         case 2: 
    //             textButton = "Aprovação Diretor"
    //         break
    //         case 3:
    //             textButton = "Aprovação CEO"
    //         break;
    //     }

    //     if(textButton !== '') {
    //         this.setState({ textButton })
    //     }

    // }

    updateSituation = () => {
        this.setState(
            {
                loading: true
            }
        )

        const {
            request_change_position_new_wage: newSalary,
            request_change_position_increase_percentage: increasePercentage,
            request_change_position_current_position: currentPosition,
            request_change_position_new_position: newPosition
        } = this.props.this.state.formValues

        // if (!newSalary || !increasePercentage || !currentPosition || !newPosition) {
        if (false) {
            Notification.error({
                title: "Erro!",
                description: "Os campos Novo Salário/Porcentagem de Aumento/Cargo Atual/Novo Cargo são obrigatório para esta ação!"
            })

            this.setState({ loading: false })

        } else {

            axios
                .get('/api/v1/situations/request_change_position/update', {
                    params: {
                        currentId: this.props.requestSituationId,
                        foreignKey: this.props.foreignKey,
                        userFunction: this.props.userFunction
                    }
                })
                .then((res: AxiosResponse) => {
                    if (res.status === 200) {
                        if (this.props.reloadPage) {
                            this.props.reloadPage()
                        }
                    }
                })
                .finally(async () => {
                    const requestChangePosition = this.props?.this?.props?.values?.formValues?.request_change_position_id

                    if (this.state.userFunction === 4) {
                        await axios.get("/api/v1/requests/request_change_position/send_mail/approvalRH/" + requestChangePosition)
                    } else if (this.state.userFunction === 2) {
                        await axios.get("/api/v1/requests/request_change_position/send_mail/approvalDirector/" + requestChangePosition)
                    }

                    this.setState({ loading: false })
                })

        }
    }

    render(): React.ReactNode {
        return (
            <>
                <Button
                    color="green"
                    onClick={this.updateSituation}
                    loading={this.state.loading}
                >
                    {this.state.textButton}
                </Button>
            </>
        )
    }
}