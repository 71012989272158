import { Event } from "../Event";

export class ControlQualificationFkCompanyIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState("loadings", ["control_qualification_fk_user_id"])

        if (value) {
            this.setField("control_qualification_fk_user_id", {
                readonly: false
            })

            this.setFieldValue("control_qualification_fk_user_id", "")
        } else {
            this.setField("control_qualification_fk_user_id", {
                readonly: true
            })
        }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 250)
    }
}