interface IinitialData {
    thisSet: any
}

interface FieldRequest {
    fieldName: string,
    hidden: boolean,
    required: boolean,
    width: number
}

interface ClearScreenRequest {
    fieldName: string,
    hidden: boolean
}

class CreateScreen {
    constructor(
        private initialData: IinitialData
    ) {

    }

    private renderScreen({ fields }: { fields: FieldRequest[] }) {
        const { thisSet } = this.initialData;
        fields.forEach((field: FieldRequest) => {
            thisSet.setField(field.fieldName, {
                hidden: field.hidden,
                required: field.required,
                width: field.width
            })
        })
    }

    clearScreen() {
        const clearScreen = [
            { fieldName: "disciplinary_measure_functionary_fk_user_id", hidden: true, required: false },
            { fieldName: "disciplinary_measure_file_attachment", hidden: true, required: false },
            { fieldName: "disciplinary_measure_type_article", hidden: true, required: false },
            { fieldName: "disciplinary_measure_advertence_text", hidden: true, required: false },
            { fieldName: "disciplinary_measure_suspension_text", hidden: true, required: false },
            { fieldName: "disciplinary_measure_company_fk_company_id", hidden: true, required: false },
            { fieldName: "disciplinary_measure_manager_fk_user_id", hidden: true, required: false },
            { fieldName: "disciplinary_measure_cpf", hidden: true, required: false },
            { fieldName: "disciplinary_measure_function_fk_function_id", hidden: true, required: false },
            { fieldName: "disciplinary_measure_quantity_day", hidden: true, required: false },
            { fieldName: "disciplinary_measure_return_date", hidden: true, required: false },
            { fieldName: "disciplinary_measure_name", hidden: true, required: false },
            { fieldName: "disciplinary_measure_fact_date", hidden: true, required: false },
            { fieldName: "disciplinary_measure_initial_date", hidden: true, required: false },
            { fieldName: "disciplinary_measure_final_date", hidden: true, required: false },
            { fieldName: "disciplinary_measure_paragraph", hidden: true, required: false },
            { fieldName: "disciplinary_measure_details", hidden: true, required: false },
            { fieldName: "disciplinary_measure_adequacy", hidden: true, required: false }
        ]

        clearScreen.forEach((field: ClearScreenRequest) => {
            this.initialData.thisSet.setField(field.fieldName, {
                hidden: field.hidden
            })
        })
    }

    screenArticle() {
        const propertiesScreen = [
            { fieldName: "disciplinary_measure_fk_disciplinary_measure_type_id", hidden: false, required: true, width: 2 },
            { fieldName: "disciplinary_measure_function_fk_function_id", hidden: false, required: true, width: 3 },
            { fieldName: "disciplinary_measure_functionary_fk_user_id", hidden: false, required: true, width: 3 },
            { fieldName: "disciplinary_measure_file_attachment", hidden: false, required: false, width: 12 },
            { fieldName: "disciplinary_measure_type_article", hidden: false, required: false, width: 12 },
            { fieldName: "disciplinary_measure_company_fk_company_id", hidden: false, required: true, width: 4 },
            { fieldName: "disciplinary_measure_advertence_text", hidden: false, required: true, width: 12 },
            { fieldName: "disciplinary_measure_suspension_text", hidden: false, required: true, width: 12 },
        ]

        this.renderScreen(
            {
                fields: propertiesScreen
            }
        )
    }

    screenSuspension() {
        const propertiesScreen = [
            { fieldName: "disciplinary_measure_fk_disciplinary_measure_type_id", hidden: false, required: true, width: 3 },
            { fieldName: "disciplinary_measure_company_fk_company_id", hidden: false, required: true, width: 3 },
            { fieldName: "disciplinary_measure_cpf", hidden: false, required: true, width: 2 },
            { fieldName: "disciplinary_measure_function_fk_function_id", hidden: false, required: true, width: 3 },
            { fieldName: "disciplinary_measure_manager_fk_user_id", hidden: false, required: false, width: 6 },
            // { fieldName: "disciplinary_measure_suspension_time", hidden: false, required: false, width: 2 },
            { fieldName: "disciplinary_measure_quantity_day", hidden: false, required: false, width: 1 },
            { fieldName: "disciplinary_measure_return_date", hidden: false, required: false, width: 2 },
            { fieldName: "disciplinary_measure_file_attachment", hidden: false, required: false, width: 12 },
            { fieldName: "disciplinary_measure_functionary_fk_user_id", hidden: false, required: true, width: 3 },
            { fieldName: "disciplinary_measure_initial_date", hidden: false, required: true, width: 2 },
            { fieldName: "disciplinary_measure_final_date", hidden: false, required: true, width: 2 },
            { fieldName: "disciplinary_measure_suspension_text", hidden: false, required: true, width: 12 }
        ]

        this.renderScreen(
            {
                fields: propertiesScreen
            }
        )
    }

    screenAdvertence() {
        const propertiesScreen = [
            { fieldName: "disciplinary_measure_fk_disciplinary_measure_type_id", hidden: false, required: true, width: 3 },
            { fieldName: "disciplinary_measure_company_fk_company_id", hidden: false, required: true, width: 3 },
            { fieldName: "disciplinary_measure_functionary_fk_user_id", hidden: false, required: true, width: 3 },
            { fieldName: "disciplinary_measure_manager_fk_user_id", hidden: false, required: false, width: 6 },
            { fieldName: "disciplinary_measure_cpf", hidden: false, required: true, width: 3 },
            { fieldName: "disciplinary_measure_function_fk_function_id", hidden: false, required: true, width: 3 },
            { fieldName: "disciplinary_measure_fact_date", hidden: false, required: true, width: 3 },
            { fieldName: "disciplinary_measure_paragraph", hidden: false, required: true, width: 12 },
            { fieldName: "disciplinary_measure_file_attachment", hidden: false, required: false, width: 12 },
            { fieldName: "disciplinary_measure_details", hidden: false, required: false, width: 12 },
            { fieldName: "disciplinary_measure_adequacy", hidden: false, required: false, width: 12 },
        ]

        this.renderScreen(
            {
                fields: propertiesScreen
            }
        )
    }

}

export { CreateScreen }