import React from 'react';

interface CardProps {
    title: string;
    value: string | number;
    subtitle?: string;
    icon?: React.ReactNode; // Para incluir ícones se necessário
    backgroundColor?: string; // Para personalizar o fundo
    textColor?: string; // Para personalizar o texto
}

const Card: React.FC<CardProps> = ({
    title,
    value,
    subtitle,
    icon,
    backgroundColor = '#ffffff',
    textColor = '#333',
}) => {
    return (
        <div
            style={{
                backgroundColor,
                color: textColor,
                borderRadius: '8px',
                padding: '16px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                textAlign: 'center',
            }}
        >
            {icon && <div style={{ marginBottom: '4px' }}>{icon}</div>}
            <h4 style={{ margin: '0 0', fontSize: '1.2rem', fontWeight: '600' }}>{title}</h4>
            <p style={{ margin: '0', fontSize: '2rem', fontWeight: 'bold' }}>{value}</p>
            {subtitle && <small style={{ marginTop: '4px', fontSize: '0.85rem', color: '#666' }}>{subtitle}</small>}
        </div>
    );
};

export default Card;
