import axios from "../../utilities/axios";
import React from "react";
import { Button, Notification, Input, SelectPicker, Col} from "rsuite";
import { Event } from "../Event";
import { withRouter } from "react-router";
import { ConfirmModal } from "../../components/confirmModal/ConfirmModal";
import { CustomModal } from "../../components/customModal/CustomModal"
import classNames from "classnames";
import { link } from "fs";


class DevelopmentFooterComponent extends React.Component<any, {}> {
    public state = {
        show: false,
        showEmail: false,
        loading: false,
        showSituation: false,
        situacaoModal: "",
        started: false,
        loadingClockIn: false,
        reproveShowSituation: false,
        reprovetionText: 'sem valor',
        showDisclaimModal: false
    };
    sendMail = () => {
        this.setState({ loading: true });
        axios
            .get("/api/mail/test")
            .then((res) => {
                if (res.status === 200 && res.data.status) {
                    Notification.success({
                        title: "Sucesso!",
                        description: "Email de (testes) enviado com sucesso!",
                        duration: 3500,
                    });
                    // if(window.location.host.includes('localhost')) {
                    //     window.open("http://localhost:25567"+res.data.output.path, "_blank");
                    // } else {
                    //     window.open(res.data.output.path, "_blank");
                    // }
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    onClick = (type: "tecnica" | "orcamento" = "orcamento") => {
        this.setState({ loading: true });
        axios
            .get("/api/v1/print/estimates/" + this.props.printId, {
                params: {
                    type,
                },
            })
            .then((res) => {
                if (res.status === 200 && res.data.status) {
                    // console.log("Olha o output:::::",res.data)
                    // console.log("Olha o document:::::",res)
                    if (window.location.host.includes("192.168.15.48")) {
                        window.open("http://192.168.15.48:25567" + res.data.output.path, "_blank");
                    } else if (window.location.host.includes("localhost")) {
                        window.open("http://localhost:25567" + res.data.output.path, "_blank");
                    } else {
                        window.open(res.data.output.path, "_blank");
                    }
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    gerarRevisao = () => {
        this.setState({ loading: true });
        // console.log("Meu console",this)
        axios
            .get("/api/v1/psm/save-revisao/" + this.props.printId)
            .then((res) => {
                if (res.status === 200 && res.data.status) {
                    // console.log("Save revisao",res)
                    this.props.onSubmit(
                        () => {
                            this.setState({ loading: true });
                            // alert("SAVED !");
                            var currentRouteSplited = this.props.history.location.pathname.split("/");
                            var currentRoute = currentRouteSplited.splice(0, currentRouteSplited.length - 1).join("/");

                            axios
                                .get("/api/v1/psm/revisao/" + this.props.printId)
                                .then((res) => {
                                    // console.log("Somente revisão", res)
                                    if (res.status === 200 && res.data.status) {
                                        // console.log(this.props.history);
                                        this.props.history.push(currentRoute + "/" + res.data.result + "?force=true");
                                        // window.open("http://localhost:25567" + res.data.output.path, "_blank");
                                        // alert(JSON.stringify(res.data.output.path));
                                    }
                                })
                                .finally(() => {
                                    this.setState({ loading: false });
                                });
                        },
                        () => {
                            this.setState({ loading: false });
                        },
                    );
                } else {
                    throw new Error("Error");
                }
            })
            .catch((err) => {
                this.setState({ loading: false });
            })
            .finally(() => { });
    };

    concluiPsm = () => {
        this.setState({
            loading: true,
        });
        axios
            .get("/api/v1/psm/revisaoUpdate/" + this.props.printId)
            .then((res => {
                if (res.status === 200 && res.data.status) {
                    // console.log("aaaaaaaaa",res.data.result);
                    this.props.history.go(0)
                }
            }))
            .finally(() => {
                this.setState({ loading: false });
            });

    }

    alteraSituacao = () => {
        this.setState({ showSituation: true })
        axios
            .get("/api/v1/psm/mudandoStatus/" + this.props.printId)
            .then((res => {
                if (res.status === 200 && res.data.status) {
                    this.setState({ situacaoModal: res.data.tiposLast });
                    // console.log(res.data.tiposLast);
                    //this.props.history.go(0)
                }
            }))
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    trocaSituacao = (value: any = null) => {
        this.setState({ loading: true });
        axios
            .get("/api/v1/estimates/status/" + value + "/" + this.props.printId)
            .then((res => {
                if (res.status === 200 && res.data.status) {
                    this.setState({ situacaoModal: res.data.statusNew });
                    // console.log(res.data.statusNew);

                    this.props.history.go(0)

                }
            }))
            .finally(() => {
                this.setState({ loading: false });
            });

    }
    aprovedAndReproved = (status: 'aprovar' | 'reprovar' = 'aprovar') => {
        // this.props.onSubmit(() => {
        // "/api/v1/estimates/status/"+status+"/" + this.props.printId
        // })
        this.setState({ loading: true });
        axios
            .get("/api/v1/estimates/statu/" + status + "/" + this.props.printId, { params: { text: this.state.reprovetionText } })
            .then((res => {
                if (res.status === 200 && res.data.status) {
                    this.props.history.go(0);
                }
            }))
            .finally(() => {
                this.setState({ loading: false });
            });

    }

    disclaimLabs = () => {
        let psmId = this.props.location.pathname.split("/").pop()
        // console.log(this.props)
        this.setState({
            loading: true
        });
        axios
            .post("/api/v1/lab/disclaim-lab/", {  
                id: psmId,
            })
            .then((res) => {
                // console.log(res.data)
                this.setState({ loading: false });
                window.location.assign('/dashboard/laboratorio/labs?page=1&perPage=10');
            })
        .finally(() =>{
            this.setState({
                loading: false
            });
        })
    }

    disclaimModal = () => {
        this.setState({
            showDisclaimModal: true
        });
    }


    clockIn = (id: any) => {
        this.setState({ loading: true });
        //TODO: chamar api ao em vez disso aqui né porra
        // setTimeout(() => {
        axios
            .get("/api/v1/clock-in/" + id)
            .then((response) => {
                Notification.success({
                    title: "Sucesso!",
                    description: "Horário apontado com sucesso!",
                });
                this.setState({ started: !this.state.started, loading: false });
                if (this.state.started == false) {
                    this.props.history.go(0);
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            })
            .catch(() => {
                Notification.error({
                    title: "Erro!",
                    description: "Não foi possível apontar horario da PSM",
                });
                this.setState({ loading: false });
            });
        // }, 2000);
    };

    componentDidUpdate(prevProps: any) {
        if (this.props.printId !== prevProps.printId && this.props.printId !== undefined) {
            this.loadClockInStatus();
        }
    }
    componentDidMount() {
        this.loadClockInStatus();
    }

    loadClockInStatus = () => {
        this.setState({ loadingClockIn: true })
        axios
            .get("/api/v1/clock-in/status/" + this.props.printId)
            .then((response) => {
                //alert(response.data.aprovado)
                this.setState({ started: response.data.aprovado });
                // this.loaded = true;
            })
            .catch((error) => { })
            .finally(() => {
                this.setState({ loadingClockIn: false });
            });
    }

    printChekList  = () => {
        axios
            .get("/api/v1/receipts/chek-list/" + this.props.printId)
            .then((res) => {
                if (res.data && res.data.url) {
                    if (res.data && res.data.url) {
                        window.open('http://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                    }
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    printEtiquetaReceipts = () => {
        var oldValues = this.props.values.oldValues
        var anexoEmbalagem = oldValues.psm_packaging_attachment[0]
        // console.log("anexos:::" , anexoEmbalagem)
        var anexoEquipamento = oldValues.psm_equipment_attachment[0]
        var anexoEtiqueta = oldValues.psm_label_attachments[0]
        // console.log("embalagem::::" , anexoEmbalagem)

        if (anexoEmbalagem === undefined || anexoEquipamento === undefined ||
            anexoEtiqueta === undefined) {
            // console.log("TODOS SÃO DIFERENTES DE UNDEFINED")
            Notification.error({
                title: "Erro",
                description: "Favor anexar fotos para Prosseguir.",
            });
            //   window.location.hash = '/dashboard/compras-estoque/receipts/' + this.props.values.oldValues.psm_id + "?tab=attachments"
            // this.props.history.push('/dashboard/compras-estoque/receipts/' + this.props.values.oldValues.psm_id + "?tab=attachments")
        } else {
            this.setState({ loading: true });
            axios
                .get("/api/v1/receipts/etiqueta/" + this.props.values.oldValues.psm_id)
                .then((res) => {
                    if (res.data && res.data.url) {
                        window.open('http://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                    }
                })
                .catch((e) => {
                    Notification.error({
                        title: "[" + e.response.status + "] Erro",
                        description: e.response.data.message ? e.response.data.message : e.message,
                    });
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        }
        // console.log("AQUII::::", this.props.values.oldValues)
    }

    render() {
        // if(this.props.match.params.crud == 'receipts'){
        //     return null
        // }
        // console.log("CRUDDDDDD",this.props.match.params )
        if (this.props.match.params.module == 'laboratorio') {
            return (<>
                <CustomModal
                    show={this.state.showDisclaimModal}
                    tableOmie={false}
                    title={<><i className="fas fa-exclamation-triangle" style={{ marginTop: "7px", marginRight: "10px" }}></i> Desatribuir Lab </>}
                    body={<>
                        <div>Quando clicado em desatribuir, a lab voltara ao seu estado inicial. Deseja desatribuir a lab?</div>
                    </>}
                    showConfirm={true}
                    confirmText={"Desatribuir"}
                    onConfirm={() => this.disclaimLabs()}
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({ showDisclaimModal: false, loading: false })
                    }}
                />
                <Button loading={this.state.loading} onClick={() => this.disclaimModal()} color={"red"} className="ml-4">
                    <i className="fas fa-exclamation-triangle fa-fw mr-2"></i>
                    Desatribuir
                </Button>
            </>)
        }
        return (
            <div>
                <ConfirmModal
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({ show: false });

                        this.props.onSubmit(() => {
                            this.setState({ show: false });
                        });
                    }}
                    showbotaoclose={true}
                    funcaoclose={() => {
                        this.setState({ show: false });
                    }}
                    onConfirm={this.gerarRevisao}
                    title={"Atenção!"}
                    cancelText={"Apenas gravar"}
                    cancelColor={"blue"}
                    confirmColor={"orange"}
                    confirmText={"Gravar e gerar revisão"}
                    content="Além de gravar, deseja gerar uma nova revisão?"
                    show={this.state.show}
                />
                <ConfirmModal
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({
                            showSituation: false,
                        })
                    }}
                    body={<>
                        <Col style={{display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
                            <SelectPicker data={[{value: 1, label: "orçamento"}]} style={{width: '100%' }}/>
                            <Button loading={this.state.loading} color='blue' style={{  marginTop: '15px' }} onClick={() => this.trocaSituacao()}>MUDAR SITUAÇÃO</Button>
                        </Col>
                    </>}
                    cancelColor={"red"}
                    showConfirm={false}
                    cancelText={"Fechar"}
                    onCloseButton={true}
                    content={""}
                    returnText={"Voltar Situação"}
                    advancedText={"Avançar Situação"}
                    title={"Avança situação de ordem de serviço"}

                    show={this.state.showSituation}
                />
                <ConfirmModal
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({
                            reproveShowSituation: false,
                        })
                    }}
                    body={
                        <Input componentClass="textarea" size="lg" onBlur={(event: any) => this.setState({ reprovetionText: event.target.value })} style={{ marginBottom: 20 }} />
                    }
                    onConfirm={() => this.aprovedAndReproved('reprovar')}
                    cancelColor={"red"}
                    showConfirm={true}
                    hiddenInput={true}
                    confirmText={"Gravar"}
                    onCloseButton={true}
                    content={""}
                    confirmColor={"green"}
                    title={"Informe o motivo da reprovação da PSM"}
                    show={this.state.reproveShowSituation}
                />
                <Button loading={this.state.loading} onClick={() => this.setState({ show: true })} color={"green"} className="ml-4">
                    <i className="fas fa-save fa-fw mr-2"></i>
                    Gravar
                </Button>
                {this.props.match.params.crud == "receipts" && (<>
                    <Button loading={this.state.loading} onClick={this.printEtiquetaReceipts} color={"blue"} className="ml-4">
                        <i className="fas fa-print fa-fw mr-2"></i>
                        Imprimir Etiqueta

                    </Button>
                    <Button style={{marginLeft: '10px'}} color="blue" onClick={this.printChekList}>
                        <i className="fas fa-print fa-fw mr-2"></i>
                        Imprimir Check-List
                    </Button>
                </>)}

                {/* <Button loading={this.state.loading} onClick={() => this.concluiPsm()} color={"orange"} className="ml-4">
                    <i className="fas fa-check-square fa-fw mr-2"></i>
                    Concluir
                </Button> */}

                    <>
                        <Button loading={this.state.loading} onClick={() => this.onClick("orcamento")} color={"blue"} className="ml-4">
                            <i className="fas fa-print fa-fw mr-2"></i>
                            Proposta comercial
                        </Button>
                        <Button loading={this.state.loading} color={"blue"} onClick={() => this.onClick("tecnica")} className="ml-4">
                            <i className="fas fa-print fa-fw mr-2"></i>
                            Proposta técnica
                        </Button>
                        {/* <Button loading={this.state.loading} color={"blue"} onClick={() => this.setState({ showEmail: true })} className="ml-4">
                        <i className="fas fa-envelope fa-fw mr-2"></i>
                        Enviar email (exemplo)
                    </Button> */}
                        <Button loading={this.state.loading} color={"blue"} onClick={() => this.alteraSituacao()} className="ml-4">
                            <i className="fas fa-tasks fa-fw mr-2"></i>
                            Mudar Situação
                        </Button>
                        <Button loading={this.state.loading} color={"green"} onClick={() => this.aprovedAndReproved('aprovar')} className="ml-4">
                            <i className="fas fa-check-circle fa-fw mr-2"></i>
                            Aprovar
                        </Button>
                        <Button loading={this.state.loading} color={"red"} onClick={() => this.setState({ reproveShowSituation: true })} className="ml-4">
                            <i className="fas fa-times-circle fa-fw mr-2"></i>
                            Reprovar
                        </Button>
                    </>
            </div>
        );
    }
}

const DevelopmentFooterWithRouter = withRouter(DevelopmentFooterComponent);

export class DevelopmentFooterEvnets extends Event {
    public onRenderFooter = (onSubmit: any) => {
        return (
            <>
                <DevelopmentFooterWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    };
}
