import { Event } from "../Event";

var loaded = false;
export class GeneralParameterEmailEvents extends Event {

    public state = {
        isValid: true
    };


    public onLoad = () => {
        const companyId = this.globalProps.parentValues?.general_parameter_fk_company_id;
        const baseId = this.globalProps.parentValues?.general_parameter_fk_base_id;

        let oldUses = this.globalState.originalForm?.general_parameter_email_sectors?.api?.uses

        oldUses[1].field = companyId
        oldUses[2].field = baseId

        // Usa a propriedade da classe para persistir o valor de isValid
        if (oldUses && loaded == false) {
            loaded = true; // Agora isValid só será alterado na primeira execução
            this.setField("general_parameter_email_sectors", {
                api: {
                    "method": "GET",
                    uses: [
                        ...oldUses
                    ],
                    "url": "/api/v1/sectors/select"
                }
            });
        }
    }
}
