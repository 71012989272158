interface EventProps {
    state: any;
    globalState: any;
    setGlobalState: Function | null;
    callback: Function | null;
    history: any | null;
    onChange: (value: any, setField: any, setState: any, formValues: any) => void;
    onChangeSelect: (valueFIeld: any, setField: any, setState: any, formValues: any) => void;
    onBlur: (value: any, setField: any, setState: any, formValues: any) => void;
    setState: (key: string, value: any) => void;
    setFieldState: (fieldName: string, state: any) => void;
    setFieldValue: (fieldName: string, value: any) => void;
    setFieldProperty: (fieldName: string, property: string, value: any) => void;
    route?: any;
    match?: any;
    props?: any;
    addTab?: (tabKey: string, tabLabel: string, jsxComponent: any) => void;
    setField: (fieldName: string, state: any) => void;
    setValue: (fieldName: string, value: any) => void;
    setProperties: (fieldName: string, properties: any, value: any) => void;
    onLoad: () => void;
}


export class Event implements EventProps{
    public setGlobalState: Function | null = null;
    public callback: Function | null = null;
    public state: any = null;
    public globalState: any = null;
    public history: any = null;
    public values: any = null;
    public onChange = (value: any, setField: any, setState: any, formValues: any) => { };
    public onChangeSelect = (valueFIeld: any, setField: any, setState: any, formValues: any) => { };
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => { };
    public addTab = (tabKey: string, tabLabel: string, jsxComponent: any) => { };
    public setState: (key: string, value: any) => void;
    public setFieldState: (fieldName: string, state: any) => void;
    public setFieldProperty: (fieldName: string, property: string, value: any) => void;
    public setFieldValue: (fieldName: string, value: any) => void;
    public globalProps: any = {};
    public onLoad: () => void;

    constructor(fieldName: string, state: any, setState: any, setFieldState: any, setFieldValue: any, setFieldProperty: any, route: any = null, match: any = null, props: any = {}, addTab: any = null) {

        this.globalProps = { ...props }
        this.state = state.fieldStates[fieldName] ?? {};
        this.globalState = state;
        this.setState = setState;
        this.setFieldState = setFieldState;
        this.setFieldValue = setFieldValue;
        this.values = state.formValues;
        this.setFieldProperty = setFieldProperty;
        this.addTab = addTab;
        this.history = { route, match };
        this.onLoad = () => {}
    }

    public setField = (fieldName: string, properties: any = {}) => {
        for (var i in properties) {
            this.setFieldProperty(fieldName, i, properties[i])
        }
    }


    public setValue = (key: string, value: any) => {
        // this.callback([key])
        if (this.setGlobalState) {
            this.setGlobalState((oldValues: any) => {
                var newFieldsUpdates = { ...oldValues.fieldsToUpdate };
                newFieldsUpdates[key] = true;
                var newValues = { ...oldValues.formValues };
                newValues[key] = value;
                return { formValues: newValues, fieldsToUpdate: newFieldsUpdates };
            });
        }
    };


    // public setState = (key: string, value: any) => {
    //     var obj: any = {};
    //     obj[key] = value;
    //     if (this.setGlobalState) {
    //         this.setGlobalState(obj);
    //     }
    // };

    public setProperties = (field: string, property: string, value: any) => {
        if (this.setGlobalState) {
            this.setGlobalState(function (oldValues: any) {
                var newData = { ...oldValues.formData };
                ////
                if (newData[field]) {
                    newData[field][property] = value;
                } else {
                    ////
                }
                return { formData: newData };
            });
        }
    };
}
