import { Event } from "../Event";

export class SectorEvents extends Event {
    public onLoad = () => {
        const url = this.globalProps.match.url;

        if(!url.includes("/add")) {
            this.setState("loadings", ["sector_id"])

            this.setField("sector_id", {
                hidden: false
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }
    }
}