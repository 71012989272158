import axios from "../../utilities/axios";
import { Event } from "../Event";

export class DisciplinaryMeasureFunctionaryFkUserIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            this.setState("loadings", ["disciplinary_measure_cpf", "disciplinary_measure_function_fk_function_id", "disciplinary_measure_manager_fk_user_id"])

            if (value) {
                const user = await axios.get("/api/v1/users/get_informations/" + value)

                const cpf = user.data?.user_cpf_cnpj ? user.data?.user_cpf_cnpj : '';
                const userFunction = user.data?.user_management_fk_function_id ? user.data?.user_management_fk_function_id : null;
                const management = user.data?.user_manager ? user.data?.user_manager : null;

                let managerName: any = ''

                if (management) {
                    managerName = await axios.get("/api/v1/users/get_manager_name/" + management)
                }


                if (cpf) {
                    this.setFieldValue("disciplinary_measure_cpf", cpf)
                }

                if (userFunction) {
                    this.setFieldValue("disciplinary_measure_function_fk_function_id", userFunction)
                }

                if (management) {
                    this.setFieldValue("disciplinary_measure_manager_fk_user_id", {
                        value: management,
                        label: managerName.data
                    })
                }


                setTimeout(() => {
                    this.setState("loadings", [])
                }, 500)
            }

        }
    }
}