import React from "react";
import { AxiosSelectPicker } from "../../formfields/AxiosSelectPicker";
import axios from "../../../utilities/axios";
import { HelpBlock } from "rsuite";


interface AutoTrackBodyProps {
    onChange: (value: string) => void;
}


interface AutoTrackBodyState {
    value: any;
    data: any[];
    isLoading: boolean;
}


export class AutoTrackBody extends React.Component<AutoTrackBodyProps, AutoTrackBodyState> {
    state = {
        value: [],
        data: [],
        isLoading: false,
    }

    render(): React.ReactNode {
        return (
            <>
                <div
                    style={
                        {
                            display: "flex",
                            flexDirection: "column",
                            gap: 2
                        }
                    }
                >
                    <label>
                        Selecione a Contabilidade
                    </label>
                    <div>
                        <AxiosSelectPicker
                            data={this.state.data}
                            api={
                                {
                                    method: "GET",
                                    url: "/api/v1/accounting_parameters/select"
                                }
                            }
                            onChange={(value: string, e: any) => {
                                if (value) {
                                    this.props.onChange(value)
                                }
                            }}
                            value={this.state.value}
                        />
                        <HelpBlock>Obrigatório*</HelpBlock>
                    </div>
                </div>
            </>
        )
    }
}