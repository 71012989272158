import React from "react";
import { UserContext } from "./authProvider/main";

export class Permission extends React.Component<any,{}> {
    static contextType = UserContext;

    static canUse(key: string,user: any) {
        // console.log("Estou entrando no canUse!")
        if(user?.role?.role_permissions) {
            // alert(user?.role?.role_id);
            if(user?.role?.role_id === 2) {
                // console.log("Entrei aqui")
                return true;
            }
            // console.log("Não entrei aqui")
            var perms : string[] = user?.role?.role_permissions.map((item : any) => {
                // console.log(item)
                return item.role_permission_value;
            })
            for(var index in perms) {
                var perm = perms[index].split('-').join('_');
                if(!perm.includes('-')) {
                    perms = [...perms,perm+'-browse',perm+'-read',perm+'-edit',perm+'-add',perm+'-delete']
                }
            }
            // //({perms});
            return perms.includes(key);
        }
        return false;
    }

    render() {
        // console.log("Estou entrando do render")
        if(this.props.name) {
            if(Permission.canUse(this.props.name ?? '',this.context.data)) {
                return this.props.children;
            }
            return null;
            
        }
        return this.props.children;
        
    }
}