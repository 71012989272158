import React from "react";
import { ReactNode } from "react";
import { withRouter } from "react-router-dom";
import { Content, Notification } from "rsuite";
import { TopBar } from "../../components/ProcessAdmission/TopBar";
import { FormSubmit, OnSubmitParams } from "../../components/ProcessAdmission/FormSubmit";
import axiosExternal from "../../utilities/axiosExternal";
import { AxiosError, AxiosResponse } from "axios";
import { Helmet } from "react-helmet";
import config from "../../globals";


export type TSizeTypes = 'lg' | 'md' | 'sm';


type TDefaultPageAdmissionState = {
    sizeType: TSizeTypes;
    loading: boolean;
    fields: Record<string, any>[];
    relation: string;
    documentsLabels: Record<string, any>[];
    initialLoading: boolean;
    errorsForm: Record<string, any>;
}


class DefaultPageAdmission extends React.Component<any, TDefaultPageAdmissionState> {
    constructor(props: any) {
        super(props);
        this.state = {
            sizeType: 'sm',
            initialLoading: true,
            loading: false,
            fields: [],
            relation: '',
            documentsLabels: [],
            errorsForm: {}
        }
        this.submitValues = this.submitValues.bind(this)
    }

    uuidv4() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    public guid = this.uuidv4();

    componentDidMount(): void {
        if (this.props.match.params.code) {
            localStorage.setItem('admissionCode', this.props.match.params.code)
        }
        this.updateSize()
        this.loadFields()
    }

    updateSize() {
        let size: TSizeTypes = 'sm'
        const sizeWindow = window.innerWidth;
        if (sizeWindow > 400) {
            size = 'lg'
        }
        this.setState(
            {
                sizeType: size
            }
        )

    }

    loadFields() {
        axiosExternal
            .get('/admission/fields/get')
            .then((response: AxiosResponse) => {
                this.setState(
                    {
                        fields: response.data.fields,
                        relation: response.data.relation,
                        documentsLabels: response.data.documentsRequired
                    }
                )
            })
            .catch((error) => {
                console.log("Errorrrrrrrrrrr :::>> ", error)
            })
            .finally(() => {
                this.setState({ initialLoading: false })
            })
    }


    submitValues(params: OnSubmitParams) {
        this.setState({ loading: true })
        const formValuesWithGuid = { ...params.formValues, guid: this.guid };
        axiosExternal
            .post('/admission/submit', {
                data: formValuesWithGuid
            })
            .then((response: AxiosResponse) => {
                Notification.success({
                    title: "Sucesso!",
                    description: 'Seus dados foram enviados com sucesso!',
                })

                setTimeout(() => {
                    window.location.reload()
                }, 1500)
            })
            .catch((error: AxiosError) => {
                if (error.response?.data.errors) {
                    const errorsResponse = error.response?.data.errors;
                    for (const i in errorsResponse) {
                        Notification.error({
                            title: "Erro!",
                            description: errorsResponse[i],
                            placement: "bottomEnd",
                        });
                    }
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }
    render(): ReactNode {
        return (
            <>
                <Helmet>
                    <title>{config.projectName} -  Admissão Digital </title>
                </Helmet>
                <div
                    style={
                        {
                            minHeight: '100vh',
                            alignContent: 'flex-end',
                        }
                    }
                >
                    {this.state.initialLoading === false && (
                        <TopBar
                            sizeWindow={this.state.sizeType}
                        />
                    )}
                    <Content
                        style={
                            {
                                display: 'flex',
                                width: '100%',
                                minHeight: '80vh',
                                boxShadow: '0 -5px 17px rgba(0,0,0,0.25)',
                                borderRadius: '10px 10px 0 0',
                                paddingTop: 20,
                                paddingBottom: 20,
                            }
                        }
                    >
                        <div
                            style={
                                {
                                    margin: 'auto',
                                    width: '80vw',
                                    // background: 'red',
                                    minHeight: '75vh',
                                    display: 'flex',
                                    flexDirection: "column",
                                    justifyContent: 'space-between',
                                    gap: 15
                                }
                            }
                        >
                            {this.state.initialLoading === false && (
                                <FormSubmit
                                    guid={this.guid}
                                    form={this.state.fields}
                                    onSubmit={this.submitValues}
                                    loading={this.state.loading}
                                    formLabelAttachment={this.state.documentsLabels}
                                    relation={this.state.relation}
                                    errors={this.state.errorsForm}
                                />
                            )}
                        </div>
                    </Content>
                </div>

            </>
        )
    }

}

export default withRouter(DefaultPageAdmission) 