import React from "react";
import { InputNumber } from "rsuite";

interface NumberInputOrderState {
    inputValue: number;
}

export class NumberInputOrder extends React.Component<any, NumberInputOrderState> {
    constructor(props: any) {
        super(props);
        this.state = {
            inputValue: 0
        }
    }

    componentDidMount(): void {
        const value = this.props.values?.infringement_control_order

        if (value) {
            this.setState({ inputValue: value });
        }
    }

    render() {
        return (
            <>
                <div style={{ width: '100%', display: "flex", flexDirection: "column", marginTop: "4px" }}>
                    <span>Pedido</span>
                    <InputNumber
                        value={this.state.inputValue}
                        onChange={(e) => {
                            this.setState({ inputValue: Number(e) });
                            if (this.props.values) {
                                this.props.values.infringement_control_order = String(e);
                            }
                        }}
                    />
                </div>
            </>
        )
    }
}