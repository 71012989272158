import React, { useRef, useEffect } from "react";
import { destroyCookie, parseCookies } from "nookies";
import { ArrowLeftLine } from "@rsuite/icons";
import styles from "./styles.module.scss";
import { Button, ButtonGroup, Icon, Input, Modal, Notification, Panel } from "rsuite";
import axios from "axios";
import SignatureCanvas from 'react-signature-canvas';
import ReactModal from 'react-modal';

const lockOrientation = () => {
    if (window.screen.orientation && (window.screen.orientation as any).lock) {
        (window.screen.orientation as any).lock("portrait").catch((err: any) => console.log("Erro ao bloquear a orientação:", err));
    }
};

const unlockOrientation = () => {
    if (window.screen.orientation && window.screen.orientation.unlock) {
        window.screen.orientation.unlock();
    }
};

type Question = {
    question: string;
    questionAnswer: string;
    questionID: number;
    questionType: number;
}

interface AnswerQuestionsState {
    step: number;
    hiddenReproveMotivation: boolean;
    isReproving: boolean;
    reprovingText: string;
    lastQuestionAnswered: boolean;
    questions: Question[];
    answers: any;
    checklistInformations: any;
    activeCamera: boolean;
    hasPicture: boolean;
    showActiveCamera: boolean;
    signatureImg: string;
    finishModalVisible: boolean;
    signatureModalIsOpen: boolean;
}

interface SignaturePadProps {
    handleSetSignature: (img: string) => void;
    handleChangeSignatureModal: () => void;
}

const SignaturePad: React.FC<SignaturePadProps> = ({ handleSetSignature, handleChangeSignatureModal }) => {
    const sigCanvas = useRef<SignatureCanvas | null>(null);

    const clear = () => {
        sigCanvas.current?.clear();
    };

    const save = () => {
        if (sigCanvas.current) {
            const dataUrl = sigCanvas.current.toDataURL("image/png"); // Removed getTrimmedCanvas()
            handleSetSignature(dataUrl); // Atualiza o estado da assinatura
        }
    };

    useEffect(() => {
        lockOrientation();
        return () => {
            unlockOrientation();
        };
    }, []);

    return (
        <ReactModal
            isOpen
            style={{
                content: {
                    // top: '50%',
                    // left: '50%',
                    // right: 'auto',
                    // bottom: 'auto',
                    // marginRight: '-50%',
                    // transform: 'translate(-50%, -50%) rotate(90deg)',
                    // padding: '1rem',
                    width: '550px',
                    height: '95%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    marginBottom: '1rem'
                },
            }}
        >
            <div className={styles.modalContainer} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: "100%" }}>

                <div className={styles.signatureButtons} style={{ position: 'absolute', top: '80px', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Button onClick={clear} appearance="primary" color="orange" style={{ transform: 'rotate(90deg)' }}>Limpar</Button>
                    <Button onClick={save} appearance="primary" color="green" style={{ top: '50px', transform: 'rotate(90deg)' }}>Salvar</Button>
                </div>
                <div>
                    <SignatureCanvas
                        ref={sigCanvas}
                        canvasProps={{
                            style: {
                                border: '.3px solid #000',
                                backgroundColor: '#f5f5f5',
                                marginRight: '80dvw',
                                width: '65dvw',
                                height: '80dvh',
                                // transform: 'translate(-50%, -50%) rotate(90deg)'
                            },
                        }}
                    />
                    <div className={styles.signatureButtons} style={{ position: 'absolute', top: '200px', left: '-40px', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <span className={styles.signatureTitle} style={{ top: '300px', transform: 'rotate(90deg)' }}><strong>Assinatura Eletrônica</strong></span>
                    </div>
                </div>


            </div>
        </ReactModal>

    );
};

class AnswerQuestions extends React.Component<any, AnswerQuestionsState> {
    videoRef = React.createRef<HTMLVideoElement>();
    canvasRef = React.createRef<HTMLCanvasElement>();
    stream: MediaStream | null = null;

    constructor(props: any) {
        super(props);
        this.state = {
            step: 0,
            hiddenReproveMotivation: true,
            isReproving: false,
            reprovingText: "",
            lastQuestionAnswered: false,
            questions: [],
            answers: [] as { question: string; questionAnswer: boolean; reason?: string; type: number }[],
            checklistInformations: null,
            activeCamera: false,
            hasPicture: false,
            showActiveCamera: true,
            signatureImg: "",
            finishModalVisible: false,
            signatureModalIsOpen: false
        };
    }

    handleChangeSignatureModal = () => {
        this.setState({
            signatureModalIsOpen: !this.state.signatureModalIsOpen
        })
    }

    onReturn = (): void => {
        if (this.state.hasPicture) {
            // Se há uma imagem, retorne para a câmera
            this.setState({
                hasPicture: false,
                activeCamera: true,
                showActiveCamera: true,
            });

            this.startCamera()
        } else if (this.state.isReproving) {
            // Retorna da reprovação se for o caso
            this.setState({
                isReproving: false,
                hiddenReproveMotivation: true,
            });
        } else {
            // Volta para a pergunta anterior se nenhuma imagem está ativa
            this.setState({ lastQuestionAnswered: false });
            this.onChangeSteps(this.state.step - 1);
        }
    };

    onChangeSteps(nextStep: number): void {
        if (nextStep === this.state.questions.length) {
            this.setState({ lastQuestionAnswered: true });
        } else {
            this.setState({
                step: nextStep < 0 ? 0 : nextStep > this.state.questions.length ? this.state.questions.length : nextStep,
            });
        }
    }

    handleFinish = async (): Promise<void> => {
        this.setState({
            finishModalVisible: false
        })

        const { answers, checklistInformations, signatureImg } = this.state;

        if (!signatureImg) {
            Notification.error({
                title: "Erro!",
                description: "É necessário assinar o documento antes de finalizar o checklist.",
                placement: "bottomEnd"
            })

            return;
        }

        const newObj = { ...checklistInformations, answers: { ...answers }, signature: signatureImg };

        try {
            await axios.post("/checklist/addnew", {
                newObj
            })
            Notification.success({ title: "Sucesso", description: "Checklist enviado com sucesso!", placement: "bottomEnd" });
            destroyCookie(null, '@checklist-informations', {
                path: '/',
            });

            setTimeout(() => {
                window.location.href = '/checklist/list';
            }, 1500)
        } catch (error) {
            Notification.error({ title: "Erro", description: "Erro ao enviar o checklist.", placement: "bottomEnd" });
        }
    };

    onAprove = (): void => {
        const currentQuestion = this.state.questions[this.state.step];

        if (this.state.questions[this.state.step + 1].questionType === 2) {
            this.startCamera()
        }

        this.setState(prevState => ({
            answers: [
                ...prevState.answers.filter((ans: any) => ans.questionID !== currentQuestion.questionID),
                { question: currentQuestion.question, questionAnswer: true, questionType: currentQuestion.questionType }
            ]
        }));
        this.onChangeSteps(this.state.step + 1);
    };

    onReprove = (): void => {
        const currentQuestion = this.state.questions[this.state.step];
        if (this.state.isReproving && this.state.reprovingText) {
            this.setState(prevState => ({
                answers: [
                    ...prevState.answers.filter((ans: any) => ans.questionID !== currentQuestion.questionID),
                    { question: currentQuestion.question, questionAnswer: false, questionType: currentQuestion.questionType, reason: this.state.reprovingText }
                ],
                hiddenReproveMotivation: true,
                isReproving: false,
                reprovingText: ""
            }));
            this.onChangeSteps(this.state.step + 1);
        } else if (!this.state.isReproving) {
            this.setState({ hiddenReproveMotivation: false, isReproving: true });
        } else {
            Notification.warning({
                title: "Aviso",
                description: "Preencha o motivo de reprovação para reprovar.",
                placement: "bottomEnd"
            });
        }
    };

    handleConfirmImage = () => {
        const { answers, step, questions } = this.state;
        const question = questions[step].question;

        const imageData = this.canvasRef.current?.toDataURL('image/jpeg');

        this.setState({
            answers: [
                ...answers.filter((answer: any) => answer.question !== question),
                { question, image: imageData, questionType: questions[step].questionType } // Salva a imagem associada à pergunta
            ],
            hasPicture: false,
            activeCamera: false,
            showActiveCamera: true
        }, () => this.onChangeSteps(step + 1));
    };

    handleSetSignature = (img: string) => {
        this.setState({ signatureImg: img });

        this.setState({
            finishModalVisible: true
        })
    };


    startCamera = async () => {
        this.setState({
            activeCamera: true,
            hasPicture: false
        });

        try {
            this.stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
            if (this.videoRef.current) {
                this.videoRef.current.srcObject = this.stream;
            }
        } catch (error) {
            console.error("Erro ao acessar a câmera:", error);
            Notification.error({
                title: "Erro",
                description: "Não foi possível acessar a câmera.",
                placement: "bottomEnd"
            });
        }
    }

    stopCamera = () => {
        this.setState({
            activeCamera: false
        });

        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
            this.stream = null;
            if (this.videoRef.current) {
                this.videoRef.current.srcObject = null;
            }
        }
    }

    captureImage = () => {
        if (!this.videoRef.current || !this.canvasRef.current) {
            Notification.error({
                title: "Erro",
                description: "Erro ao capturar imagem. Verifique se a câmera está ativa.",
                placement: "bottomEnd"
            });
            return;
        }

        const video = this.videoRef.current;
        const canvas = this.canvasRef.current;
        const context = canvas.getContext('2d');

        if (!context || !video.videoWidth || !video.videoHeight) {
            Notification.error({
                title: "Erro",
                description: "Aguarde a câmera inicializar completamente antes de capturar.",
                placement: "bottomEnd"
            });
            return;
        }

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        try {
            const imageData = canvas.toDataURL('image/jpeg');
            // console.log('Imagem capturada:', imageData);

            this.setState({
                hasPicture: true,
                activeCamera: false,
                showActiveCamera: false
            });

            this.stopCamera();

            Notification.success({
                title: "Sucesso",
                description: "Imagem capturada com sucesso!",
                placement: "bottomEnd"
            });
        } catch (error) {
            console.error('Erro ao capturar imagem:', error);
            Notification.error({
                title: "Erro",
                description: "Erro ao processar a imagem capturada.",
                placement: "bottomEnd"
            });
        }
    }

    retakeImage = () => {
        // Reinicia o estado e reativa a câmera
        this.setState({ hasPicture: false }, this.startCamera);
    }

    async componentDidMount(): Promise<void> {
        const cookies = parseCookies();
        const checklistInformations = cookies["@checklist-informations"];

        if (!checklistInformations) {
            window.location.href = '/checklist';
            return;
        }

        const checklistInformationsParse = JSON.parse(checklistInformations);
        this.setState({ checklistInformations: checklistInformationsParse });

        const questions = await axios.get("/checklist/getquestions/" + checklistInformationsParse?.checklistTableId);
        if (questions?.data?.questions) {
            this.setState({ questions: questions.data.questions });
        }
    }

    componentDidUpdate(prevProps: any, prevState: AnswerQuestionsState) {
        const currentQuestion = this.state.questions[this.state.step];
        if (currentQuestion?.questionType === 2 && !this.state.activeCamera && !this.state.hasPicture) {
            this.startCamera();
        }
    }

    handleReturnRegisterChecklists(): void {
        window.location.href = '/checklist/register';
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.headerContainer} onClick={this.handleReturnRegisterChecklists}>
                        <ArrowLeftLine className={styles.arrow} onClick={this.handleReturnRegisterChecklists} />
                        <span className={styles.returnPageText}>Voltar ao Início</span>
                    </div>
                    <h1 className={styles.userName}>Perguntas</h1>
                </div>
                <div className={styles.container}>
                    <div className={styles.mainContainer}>
                        <div>
                            {this.state.questions.length > 0 && (
                                <Panel header={`Pergunta ${this.state.step + 1}/${this.state.questions.length}`} key={this.state.step}>
                                    {this.state.questions[this.state.step]?.question}
                                </Panel>
                            )}
                            <hr />
                            {this.state.questions[this.state.step]?.questionType === 1 && (
                                <>
                                    <Button onClick={this.onReturn} disabled={this.state.step === 0 && !this.state.isReproving} className={styles.returnButton} appearance="primary" color="yellow">
                                        Voltar
                                    </Button>
                                    <ButtonGroup className={styles.buttonToolBar}>
                                        <Button onClick={this.onReprove} appearance="primary" color="red">
                                            {this.state.isReproving ? (<span>Confirmar Reprovado</span>) : (<span>Reprovado</span>)}
                                        </Button>
                                        {!this.state.isReproving && (
                                            <Button onClick={this.onAprove} disabled={this.state.step >= this.state.questions.length} appearance="primary" color="green">
                                                Aprovado
                                            </Button>
                                        )}
                                    </ButtonGroup>
                                </>
                            )}

                            {this.state.questions[this.state.step]?.questionType === 2 && (
                                <>
                                    <div className={styles.pictureButtons}>
                                        {/* {this.startCamera()} */}
                                        <Button onClick={this.onReturn} disabled={this.state.step === 0 && !this.state.isReproving} className={styles.returnButton} appearance="primary" color="yellow">
                                            Voltar
                                        </Button>

                                        {/* {this.state.showActiveCamera && (
                                            <Button onClick={this.startCamera} appearance="primary" color="blue" disabled={this.state.activeCamera && false} className={styles.cameraButton}>Ativar Câmera</Button>
                                        )} */}

                                        <Button onClick={this.captureImage} appearance="primary" color="violet" disabled={!this.state.activeCamera} className={styles.cameraButton}><Icon icon='camera-retro' size="lg" /></Button>

                                        {/* {!this.state.hasPicture && (
                                            <Button onClick={this.stopCamera} appearance="primary" color="red" className={styles.cameraButton}>Desativar Câmera</Button>
                                        )} */}

                                        {this.state.hasPicture && (
                                            <>
                                                <Button onClick={this.retakeImage} appearance="primary" color="orange" className={styles.cameraButton}>Tirar Novamente</Button>
                                                <Button onClick={this.handleConfirmImage} appearance="primary" color="green" className={styles.cameraButton}>Confirmar</Button>
                                            </>
                                        )}
                                    </div>

                                    {this.state.activeCamera && (
                                        <video
                                            ref={this.videoRef}
                                            autoPlay
                                            playsInline
                                            onLoadedMetadata={() => console.log('Vídeo pronto para captura')}
                                            className={styles.videoFrame}
                                        />
                                    )}

                                    <canvas
                                        ref={this.canvasRef}
                                        style={{
                                            display: this.state.hasPicture ? 'block' : 'none'
                                        }}
                                        className={styles.canvaFrame}
                                    ></canvas>
                                </>
                            )}
                        </div>

                        {!this.state.hiddenReproveMotivation && (
                            <div className={styles.reproveMotivationContainer}>
                                <Input
                                    componentClass="textarea"
                                    rows={3}
                                    placeholder="Motivo da reprovação..."
                                    value={this.state.reprovingText}
                                    onChange={(e) => this.setState({ reprovingText: e })}
                                />
                            </div>
                        )}
                        {this.state.lastQuestionAnswered && (
                            <div className={styles.finishButtonContainer}>
                                <SignaturePad
                                    handleSetSignature={this.handleSetSignature}
                                    handleChangeSignatureModal={this.handleChangeSignatureModal}
                                />
                            </div>
                        )}
                    </div>
                </div >
                <Modal
                    show={this.state.finishModalVisible}
                    size="sm"
                    onClose={() => this.setState({ finishModalVisible: false })}
                >
                    <Modal.Header>
                        <Modal.Title>Finalizar Checklist</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Você tem certeza que deseja finalizar este checklist?</p>
                        <p>Após finalizar não será possível fazer alterações.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => this.setState({ finishModalVisible: false })}
                            appearance="subtle"
                        >
                            Cancelar
                        </Button>
                        <Button
                            onClick={this.handleFinish}
                            appearance="primary"
                            color="cyan"
                        >
                            Confirmar e Finalizar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default AnswerQuestions;
