import { Component, ReactNode } from "react";
import { Button, Input, Notification, Panel, PanelGroup, Placeholder } from "rsuite";
import { HeaderInstallment } from "./HeaderInstallment";
import ICustomInstallmentProps from "../interfaces/CustomInterface";
import { TableInstallmentParcel } from "./TableInstallmentParcel";
import _ from "lodash";
import dayjs from "dayjs";
import { NumberInput } from "../../NumberInput";
import axios from "../../../../utilities/axios";



interface ICustomInstallmentState {
    originalValue: Record<string, any>[];
    EntryExpenseHaveToPay: Record<string, any>[]
    loading: boolean;
    valueFormate: Record<string, any>[];
    checkLastRegister: boolean;
}


export class CustomInstallment extends Component<ICustomInstallmentProps, ICustomInstallmentState> {
    state = {
        originalValue: this.props.customValues ? this.props.customValues : [],
        loading: true,
        valueFormate: [],
        EntryExpenseHaveToPay: [],
        checkLastRegister: false
    }

    componentDidMount(): void {
        const { originalValue } = this.state;
        this.mountValues(
            {
                data: originalValue,
                callback: (result) => {
                    this.setState(
                        {
                            valueFormate: result,
                            loading: false
                        }
                    )
                }
            }
        )
    }

    mountValues = ({ data, callback }: { data: Record<string, any>[], callback: (result: Record<string, any>[]) => void }) => {
        let grouped = {};
        if (data.length > 0) {
            grouped = data.reduce((acc, current) => {
                const key = current.entry_expense_installment_parcel_number_id;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(current);
                return acc;
            }, {});
        }
        callback(Object.values(grouped));
    }


    handleChecked = ({ value, entry_expense_installment_id }: { value: boolean, entry_expense_installment_id: number }) => {
        const { originalValue } = this.state;
        const remainingItems = _.reject(originalValue, { 'entry_expense_installment_id': entry_expense_installment_id });
        let itemChanged = _.filter(originalValue, { 'entry_expense_installment_id': entry_expense_installment_id })[0];
        itemChanged = {
            ...itemChanged,
            entry_expense_installment_installment_paid: value
        }
        const updatedArray = [...remainingItems, itemChanged];
        const sortedArray = _.orderBy(updatedArray, 'entry_expense_installment_id', 'asc');
        this.setState({
            originalValue: sortedArray
        })
        this.props.onChange(sortedArray)

    }

    isAddColumnHandleCheckd = async ({ value, entry_expense_installment_id }: { value: boolean, entry_expense_installment_id: number }) => {
        this.setState({
            loading: true
        })

        const { originalValue } = this.state;

        const remainingItems = _.reject(originalValue, { 'entry_expense_installment_id': entry_expense_installment_id });

        let itemChanged = _.filter(originalValue, { 'entry_expense_installment_id': entry_expense_installment_id })[0];

        itemChanged = {
            ...itemChanged,
            entry_expense_installment_move_paid: value
        }

        const lastPayment = originalValue[originalValue.length - 1];

        if (!lastPayment) {
            Notification.warning({
                title: "Aviso!",
                description: 'Salve o registro para fazer está ação!'
            })

            this.setState({
                loading: false
            })

            return
        }

        const newDate = dayjs(lastPayment?.entry_expense_installment_parcel_date).add((1), 'month').format('YYYY-MM-DD HH:mm:ss.SSS');


        let newItem: any = {
            entry_expense_installment_installment_paid: false,
            entry_expense_installment_installment_number: lastPayment.entry_expense_installment_installment_number + 1,
            entry_expense_installment_parcel_number_id: lastPayment.entry_expense_installment_parcel_number_id,
            entry_expense_installment_parcel_date: newDate,
            entry_expense_installment_parcel_value: itemChanged.entry_expense_installment_parcel_value,
            entry_expense_installment_move_paid: false,
            entry_expense_installment_id: lastPayment.entry_expense_installment_id + 1

        };


        if (!itemChanged?.entry_expense_installment_id || !this.props.values?.entry_expense_id) {
            Notification.warning({
                title: "Aviso!",
                description: 'Salve o registro para fazer está ação!'
            })

            this.setState({
                loading: false
            })

            return
        }

        const entryExpenseId = this.props.values.entry_expense_id

        const updatedArray = [...remainingItems, itemChanged];


        const updateArrayWithNewItem = [...updatedArray, newItem];


        const sortedArray = _.orderBy(updateArrayWithNewItem, 'entry_expense_installment_id', 'asc');


        this.setState({
            originalValue: sortedArray
        })

        this.mountValues(
            {
                data: sortedArray,
                callback: (result) => {
                    this.setState(
                        {
                            valueFormate: result,
                            loading: false
                        }
                    )
                }
            }
        )

        this.props.onChange(sortedArray)


        const databaseUpdateParcel = await axios.post("/api/v1/entry_expense/update_database_parcel", {
            params: {
                itemChangedId: itemChanged.entry_expense_installment_id,
                newItem: newItem,
                entryExpenseId: entryExpenseId
            }
        })

    }

    addParcelTotalValue = (params: { parcelNumberId: number, originalValues: Record<string, any>[] }): Record<string, any>[] => {
        const { values } = this.props;
        const { originalValues, parcelNumberId } = params;
        const updateArrayReturn = [...originalValues];
        let newItem = {
            entry_expense_have_to_pay_parcel_number_dady: parcelNumberId,
            entry_expense_have_to_pay_value: values.entry_expense_have_to_pay,
            entry_expense_have_to_pay_fk_entry_expense_id: null,
            guid: ''
        }
        if (values.entry_expense_id) {
            newItem = {
                ...newItem,
                entry_expense_have_to_pay_fk_entry_expense_id: values.entry_expense_id
            }
        } else {
            newItem = {
                ...newItem,
                guid: this.props.guid
            }
        }

        updateArrayReturn.push(newItem)

        return updateArrayReturn
    }

    addRowColumn = ({ updatedArray }: { updatedArray: Record<string, any>[] }) => {
        this.setState({
            loading: true
        })
        this.mountValues(
            {
                data: updatedArray,
                callback: (result) => {
                    this.setState(
                        {
                            valueFormate: result,
                            loading: false
                        }
                    )
                }
            }
        )
        this.props.onChange(updatedArray);
    };

    clearParcel = (params: { indexParcel: number }) => {
        const { indexParcel } = params;

        const { valueFormate, originalValue } = this.state;

        let arrayFormated = valueFormate;

        this.setState({
            loading: true
        });

        const lastIndex = indexParcel - 1;

        arrayFormated.splice(lastIndex);

        const remainingItems: any[] = [];

        for (const individualArray of arrayFormated) {
            for (const item of individualArray) {
                remainingItems.push(item)
            }
        }


        this.mountValues(
            {
                data: remainingItems,
                callback: (result) => {
                    this.setState(
                        {
                            valueFormate: result,
                            originalValue: remainingItems,
                            loading: false
                        }
                    )
                }
            }
        )

        this.props.onChange(remainingItems);
    }


    renderPanel = ({ itemInstallment, index }: { itemInstallment: Record<string, any>[], index: number }) => {
        const entryExpensePayUnit: any = _.filter(itemInstallment, { 'entry_expense_installment_installment_number': 1 });
        const lastIndex = this.state.valueFormate.length === index;

        const canShowRemove = itemInstallment.some((item: any) => item.entry_expense_installment_installment_paid === true);


        const status = this.props.values?.entry_expense_active

        let isActive: boolean;

        if (status) {
            isActive = true
        } else {
            isActive = false
        }

        return (
            <Panel
                header={
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        Parcelamento {itemInstallment[0].entry_expense_installment_parcel_number_id}

                        {lastIndex && !canShowRemove && isActive && (
                            <Button
                                color="red"
                                onClick={() => this.clearParcel({ indexParcel: index })}
                            >
                                Remover Parcelamento
                            </Button>
                        )}
                    </div>
                }
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "row",
                            width: '100%',
                            gap: 10,
                            justifyContent: "end",
                            alignItems: "center"
                        }}
                    >
                        {/* <h6>
                            Valor total do Parcelamento
                        </h6> */}
                        {/* <NumberInput
                            disabled={true}
                            preffix={"R$"}
                            value={entryExpensePayUnit.length > 0 ? entryExpensePayUnit[0].entry_expense_installment_have_value : 0}
                        /> */}
                    </div>
                    <TableInstallmentParcel
                        isActive={isActive}
                        data={itemInstallment}
                        actionTable={this.handleChecked.bind(this)}
                        actionAddColumnTable={this.isAddColumnHandleCheckd.bind(this)}
                        disabledAll={entryExpensePayUnit[0] ? entryExpensePayUnit[0].entry_expense_installment_created_at ? false : true : false}
                    />
                </div>
            </Panel>
        )
    }



    renderComponent = (): ReactNode => {
        const outputComponent = [];
        const { valueFormate } = this.state;
        for (const index in valueFormate) {
            const resultOutput = this.renderPanel({ itemInstallment: valueFormate[index], index: Number(index) + 1 });
            outputComponent.push(resultOutput)
        }
        return outputComponent
    }

    render(): ReactNode {
        return (
            <>
                {!this.state.loading ? (
                    <Panel
                        bordered
                        header={
                            <HeaderInstallment
                                title={this.props.label}
                                info={this.props.field.columnInfo}
                                generateColumn={this.addRowColumn.bind(this)}
                                parentValues={this.props.values}
                                dataValues={this.state.originalValue}
                                guid={this.props.guid}
                            />
                        }
                    >
                        <PanelGroup
                            bordered
                            accordion
                        >
                            {this.renderComponent()}
                        </PanelGroup>
                    </Panel>
                ) : (
                    <>
                        <Placeholder.Grid rows={5} columns={6} active />
                    </>
                )}
            </>

        )
    }
}