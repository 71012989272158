import React from "react";
import { Button, Modal, Notification, Table } from "rsuite";
import axios from "../../../../utilities/axios";
import { AxiosResponse } from "axios";
import ModalErrorImport from "../modalErrorImport/ModalErrorImport";

const { Cell, HeaderCell, Column } = Table;

interface Props {
    showModal: boolean;
    data: Record<string, any>[];
    guid: string;
    onClose: () => void;
}

interface FormFields {
    $GLOBALS: Record<string, any>;
    [key: string]: Record<string, any>;
}

interface State {
    formFields: FormFields;
    loading: boolean;
    showModalErrorImport: boolean
}

export class ModalBeforeImportAutoTrack extends React.Component<Props, State> {
    state: State = {
        formFields: {
            $GLOBALS: {},
        },
        loading: true,
        showModalErrorImport: false,
    };

    componentDidMount(): void {
        axios
            .get("/api/v1/import_hours/fields")
            .then((response: AxiosResponse<{ fields: FormFields }>) => {
                this.setState({
                    formFields: response.data.fields,
                    loading: false,
                });
            });
    }

    onConfirmImport() {
        this.setState(
            {
                loading: true
            }
        )
        axios
            .get(`/api/v1/confirm/autotrack-import`, {
                params: {
                    guid: this.props.guid
                }
            })
            .then((response: AxiosResponse<{ status: string, message: string }>) => {
                Notification.success(
                    {
                        title: response.data.message,
                    }
                )
                this.props.onClose()
                window.onbeforeunload = null;
                window.location.reload();  
            })
            .finally(() => {
                this.setState(
                    {
                        loading: false
                    }
                )
            })
    }


    onCancelImport() {
        this.setState(
            {
                loading: true
            }
        )
        axios
            .get('/api/v1/delete/autotrack-import', {
                params: {
                    guid: this.props.guid
                }
            })
            .then((response: AxiosResponse<{ status: string, message: string }>) => {
                Notification.success(
                    {
                        title: response.data.message,
                    }
                )
                this.props.onClose()
                window.onbeforeunload = null;
                window.location.reload();
            })
            .finally(() => {
                this.setState(
                    {
                        loading: false
                    }
                )
            })
    }

    renderColumns = () => {
        const { $GLOBALS, ...columns } = this.state.formFields;
        const outputHtml = [];
        // const colors = ["#FFDDC1", "#392F5A"]; // Adicione mais cores conforme necessário

        // let colorIndex = 0;

        for (const key in columns) {
            if (Object.prototype.hasOwnProperty.call(columns, key)) {
                const columnProps = columns[key];
                // const columnColor = colors[colorIndex % colors.length];
                // colorIndex++;

                 if(columnProps.type === 'checkbox') {
                    continue
                }

                // Crie uma coluna para cada propriedade
                outputHtml.push(

                    <Column
                        resizable
                        key={key}
                        width={120}
                        align="center"

                    >
                        <HeaderCell>{columnProps.label || key}</HeaderCell>
                        <Cell>
                            {(row: any) => {
                                if(columnProps.type === "select") {
                                    const nameColumn = key === "import_hour_fk_user_id" ?  "user_name" : "company_fantasy"
                                    return (
                                        <>
                                            {row[nameColumn]}
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            {row[key]}
                                        </>
                                    )
                                }
                            }}
                        </Cell>
                    </Column>

                );
            }
        }

        return outputHtml;
    };

    hasImportErrors = (): boolean => {
        return this.props.data.some(row => row.import_hour_exist_error);
    }

    openModalErrosImport = () => {
        this.setState({ showModalErrorImport: true });
    }

    closeModalErrosImport = () => {
        this.setState({ showModalErrorImport: false });
    }

    render(): React.ReactNode {
        const showImportEventsButton = this.hasImportErrors();
        return (
            <>
                <Modal show={this.props.showModal} className="rs-modal-full">
                    <Modal.Header>
                        <Modal.Title>Dados pré-importação <strong> AutoTrac</strong></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table
                            height={600}
                            data={this.props.data}
                            loading={this.state.loading}
                            renderEmpty={() => "Nenhum dado encontrado"}
                            cellBordered
                        >
                            {this.renderColumns()}
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        
                        <Button
                            color="red"
                            loading={this.state.loading}
                            onClick={this.onCancelImport.bind(this)}
                        >
                            Cancelar
                        </Button>
                        {showImportEventsButton && (
                            <Button
                                color="yellow"
                                loading={this.state.loading}
                                onClick={this.openModalErrosImport}
                            >
                                Erros
                            </Button>
                        )}
                        <Button
                            color="green"
                            loading={this.state.loading}
                            onClick={this.onConfirmImport.bind(this)}
                        >
                            Confirmar importação
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ModalErrorImport 
                    show={this.state.showModalErrorImport} 
                    uuid={this.props.guid} 
                    onClose={this.closeModalErrosImport} 
                />
            </>
        );
    }
}
