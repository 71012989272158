import axios from "../../../utilities/axios";
import { Event } from "../../Event";

export class UserHistoricalTimeBankDateEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        // console.log("Entrei aqui!") 
        // console.log(this)

        const isEdit = this.globalProps?.isEdit;

        //Se for igual a 0 quer dizer que é edit
        if (isEdit === 0) {
            this.setState("loadings", ["user_historical_time_bank_updated_name"])

            const user = await axios.get("/api/v1/me")

            if (user) {
                this.setFieldValue("user_historical_time_bank_updated_name", user.data.user?.user_name)
            }

            setTimeout(() => {
                this.setState("loadings", [])
            }, 300)
        }
    }
}