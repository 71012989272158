import { Event } from "../../Event";
import { EntryExpenseReleasedHelpers } from "./EntryExpenseReleasedHelpers";

export class EntryExpenseReleasedsEvents extends EntryExpenseReleasedHelpers {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        const { values } = this.globalProps;
        this.setState('loadings', [
            'entry_expense_amount',
            'entry_expense_have_to_pay'
        ])
        const resultAmount = this.getValueAmount(
            {
                data: value
            }
        )

        const resultHaveToPay = this.getOutstandingBalance(
            {
                data: values,
                valueGrid: value
            }
        )
        this.setFieldValue('entry_expense_amount', resultAmount);
        this.setFieldValue('entry_expense_have_to_pay', resultHaveToPay);
        this.setState('loadings', []);
    }
}
