import { Event } from "../Event";
import axios from "../../utilities/axios";

class EntryExpenseFkUserIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            const user = Number(value);

            this.setState("loadings", ["entry_expense_registration", "entry_expense_position"])

            const response = await axios.get("/api/v1/entry-expenses/get-user/" + user);

            this.setFieldValue("entry_expense_registration", response.data?.code)
            this.setFieldValue("entry_expense_position", response.data?.function)

            setTimeout(() => {
                this.setState("loadings", [])
            }, 500)

        }
    }
}

export { EntryExpenseFkUserIdEvents }
