import { Component, ReactNode } from "react";
import { Button, Notification, Panel, PanelGroup, Placeholder } from "rsuite";
import { HeaderInstallment } from "./HeaderInstallment";
import ICustomInstallmentProps from "../interfaces/CustomInterface";
import { TableInstallmentParcel } from "./TableInstallmentParcel";
import _ from "lodash";
import dayjs from "dayjs";



interface ICustomInstallmentState {
    originalValue: Record<string, any>[];
    loading: boolean;
    valueFormate: Record<string, any>[]
}


export class CustomInstallmentConsigned extends Component<ICustomInstallmentProps, ICustomInstallmentState> {
    state = {
        originalValue: this.props.customValues ? this.props.customValues : [],
        loading: true,
        valueFormate: []
    }

    componentDidMount(): void {
        const { originalValue } = this.state;
        this.mountValues(
            {
                data: originalValue,
                callback: (result) => {
                    this.setState(
                        {
                            valueFormate: result,
                            loading: false
                        }
                    )
                }
            }
        )
    }

    mountValues = ({ data, callback }: { data: Record<string, any>[], callback: (result: Record<string, any>[]) => void }) => {
        let grouped = {};
        if (data.length > 0) {
            grouped = data.reduce((acc, current) => {
                const key = current.consigned_credit_installment_parcel_number_id;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(current);
                return acc;
            }, {});
        }
        callback(Object.values(grouped));
    }


    // handleChecked = ({ value, consigned_credit_installment_id }: { value: boolean, consigned_credit_installment_id: number }) => {
    //     console.log("Entrei aqui handleChecked!")
    //     this.setState({
    //         loading: true
    //     })
    //     const { originalValue } = this.state;
    //     console.log({originalValue})
    //     const remainingItems = _.reject(originalValue, { 'consigned_credit_installment_id': consigned_credit_installment_id });
    //     console.log({remainingItems})
    //     let itemChanged = _.filter(originalValue, { 'consigned_credit_installment_id': consigned_credit_installment_id })[0];
    //     console.log({itemChanged})
    //     itemChanged = {
    //         ...itemChanged,
    //         consigned_credit_installment_installment_paid: value
    //     }
    //     const lastPayment = originalValue[originalValue.length - 1];
    //     console.log(lastPayment)
    //     const newDate = dayjs(lastPayment.consigned_credit_installment_parcel_date).add((1), 'month').format('YYYY-MM-DD HH:mm:ss.SSS');
    //     console.log({newDate})
    //     let newItem: any = {
    //         consigned_credit_installment_installment_paid: false,
    //         consigned_credit_installment_installment_number: lastPayment.consigned_credit_installment_installment_number + 1,
    //         consigned_credit_installment_parcel_number_id: lastPayment.consigned_credit_installment_parcel_number_id,
    //         consigned_credit_installment_parcel_date: newDate,
    //         consigned_credit_installment_parcel_value: lastPayment.consigned_credit_installment_parcel_value,

    //     };
    //     console.log({newItem})
    //     const updatedArray = [...remainingItems, itemChanged];
    //     console.log({updatedArray})
    //     const updateArrayWithNewItem = [...updatedArray, newItem];
    //     console.log({updateArrayWithNewItem})
    //     const sortedArray = _.orderBy(updateArrayWithNewItem, 'consigned_credit_installment_id', 'asc');
    //     this.setState({
    //         originalValue: sortedArray
    //     })
    //     this.mountValues(
    //         {
    //             data: sortedArray,
    //             callback: (result) => {
    //                 this.setState(
    //                     {
    //                         valueFormate: result,
    //                         loading: false
    //                     }
    //                 )
    //             }
    //         }
    //     )
    //     this.props.onChange(sortedArray)
    // }

    handleChecked = ({ value, consigned_credit_installment_id }: { value: boolean, consigned_credit_installment_id: number }) => {
        if (typeof consigned_credit_installment_id === 'number') {
            const { originalValue } = this.state;
            const remainingItems = _.reject(originalValue, { 'consigned_credit_installment_id': consigned_credit_installment_id });
            let itemChanged = _.filter(originalValue, { 'consigned_credit_installment_id': consigned_credit_installment_id })[0];
            itemChanged = {
                ...itemChanged,
                consigned_credit_installment_installment_paid: value
            }
            const updatedArray = [...remainingItems, itemChanged];
            const sortedArray = _.orderBy(updatedArray, 'consigned_credit_installment_id', 'asc');
            this.setState({
                originalValue: sortedArray
            })
            this.props.onChange(sortedArray)
        } else {
            Notification.warning(
                {
                    title: "Aviso",
                    description: "Você precisa gravar para marcar o parcelamento"
                }
            )
        }

    }


    addRowColumn = ({ numberRows }: { numberRows: number | string }) => {
        this.setState({
            loading: true
        });
        let number = parseInt(numberRows.toString());

        const { originalValue } = this.state;
        const { values } = this.props;
        const updatedArray = [...originalValue];
        const parcelNumberId = updatedArray.length > 0 ? updatedArray[updatedArray.length - 1].consigned_credit_installment_parcel_number_id + 1 : 1;
        const startDate = dayjs().format('YYYY-MM-DD HH:mm:ss.SSS');

        const totalAmount = Math.round(values.consigned_credit_amount * 100) / 100;

        let installments: number[] = [];

        if (number === 1) {
            installments = [totalAmount];
        } else {
            const baseAmount = Math.floor((totalAmount / number) * 100) / 100;
            const remainder = Math.round((totalAmount - (baseAmount * number)) * 100) / 100;

            // Preenche as parcelas com o valor base
            installments = Array(number).fill(baseAmount);

            // Adiciona a diferença na última parcela
            installments[number - 1] = Math.round((installments[number - 1] + remainder) * 100) / 100;
        }

        for (let i = 0; i < number; i++) {
            const newDate = dayjs(startDate).add((i + 1), 'month').format('YYYY-MM-DD HH:mm:ss.SSS');
            let newItem: any = {
                consigned_credit_installment_installment_paid: false,
                consigned_credit_installment_installment_number: (i + 1),
                consigned_credit_installment_parcel_number_id: parcelNumberId,
                consigned_credit_installment_parcel_date: newDate,
                consigned_credit_installment_parcel_value: installments[i],
            };
            if (values.consigned_credit_id) {
                newItem = {
                    ...newItem,
                    consigned_credit_installment_fk_consigned_credit_id: values.consigned_credit_id
                };
            } else {
                newItem = {
                    ...newItem,
                    guid: this.props.guid
                };
            }
            updatedArray.push(newItem);
        }

        this.setState({
            originalValue: updatedArray
        });

        this.mountValues({
            data: updatedArray,
            callback: (result) => {
                this.setState({
                    valueFormate: result,
                    loading: false
                });
            }
        });

        this.props.onChange(updatedArray);
    };

    clearParcels = () => {
        this.setState({
            loading: true
        });
        this.mountValues(
            {
                data: [],
                callback: (result) => {
                    this.setState(
                        {
                            valueFormate: result,
                            originalValue: [],
                            loading: false
                        }
                    )
                }
            }
        )
        this.props.onChange([]);
    }


    renderPanel = ({ itemInstallment }: { itemInstallment: Record<string, any>[] }) => {

        let canExclud = true

        for (const item of itemInstallment) {
            if (item.consigned_credit_installment_installment_paid) {
                canExclud = false
            }
        }

        return (
            <Panel
                header={

                    <div
                        style={
                            {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }
                        }
                    >
                        Parcelamento {itemInstallment[0].consigned_credit_installment_parcel_number_id}

                        {canExclud && (

                            <Button
                                color="red"
                                onClick={this.clearParcels}
                            >
                                Remover Parcelamento
                            </Button>
                        )}
                    </div>
                }
            >

                <TableInstallmentParcel
                    data={itemInstallment}
                    actionTable={this.handleChecked.bind(this)}
                    formValues={this.props.values}
                />
            </Panel>
        )
    }


    renderComponent = (): ReactNode => {
        const outputComponent = [];
        const { valueFormate } = this.state;
        for (const itemArray of valueFormate) {
            const resultOutput = this.renderPanel({ itemInstallment: itemArray });
            outputComponent.push(resultOutput)
        }
        return outputComponent
    }

    render(): ReactNode {
        return (
            <>
                {!this.state.loading ? (
                    <Panel
                        bordered
                        header={
                            <HeaderInstallment
                                title={this.props.label}
                                info={this.props.field.columnInfo}
                                generateColumn={this.addRowColumn.bind(this)}
                                dataLength={this.state.valueFormate.length}
                            />
                        }
                    >
                        <PanelGroup
                            bordered
                            accordion
                        >
                            {this.renderComponent()}
                        </PanelGroup>
                    </Panel>
                ) : (
                    <>
                        <Placeholder.Grid rows={5} columns={6} active />
                    </>
                )}
            </>

        )
    }
}