import { Event } from "../Event";

export class GeneralParameterImportCompanyConsiderNightTimeEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState("loadings", ["general_parameter_import_company_has_overtime", "general_parameter_import_company_extra_hours_amount"])

        if (value) {
            this.setFieldValue("general_parameter_import_company_has_overtime", false)

            this.setField("general_parameter_import_company_extra_hours_amount", {
                hidden: true
            })

            this.setFieldValue("general_parameter_import_company_extra_hours_amount", null)
            this.setFieldValue("general_parameter_import_company_extra_hours_show_grid", "")
        } else {
            this.setFieldValue("general_parameter_import_company_has_overtime", true)

            this.setField("general_parameter_import_company_extra_hours_amount", {
                hidden: false
            })
        }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 250)
    }
}