import React from "react";
import { Button, Col, FormGroup, Input, Row } from "rsuite";
import { CustomModal } from "../../../customModal/CustomModal";
import empty from '../../../../assets/empty-folder.png'
import axios from "../../../../utilities/axios";

export class TransferRequestDataArrivedVuupt extends React.Component<any, {}> {
    public state = {
        showModal: false,
        dataModal: [] as any[]
    }

    componentDidMount(): void {
        if (this.props?.values?.transfer_request_vuupt_checklist_key) {
            axios
                .get('/api/v1/vuupt/checklist', {
                    params: {
                        checklist_id: this.props.values.transfer_request_vuupt_checklist_key,
                        checklist_collect_id: this.props.values.transfer_request_checklist_collect_id
                    }
                })
                .then((response) => {
                    this.setState({ dataModal: [response.data.data_vuupt] })
                })
        }
    }

    // componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<{}>, snapshot?: any): void {

    // }

    renderBodyModal = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <>
                    {this.state.dataModal.length > 0 ? (
                        <div
                            style={
                                {
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(2,50%)'
                                }
                            }
                        >
                            <div
                            >
                                <div
                                    style={
                                        {
                                            display: 'flex',
                                            justifyContent: 'center',

                                        }
                                    }

                                >
                                    <h3>Dados de Coleta</h3>
                                </div>
                                <div>
                                    <div
                                        style={
                                            {
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',

                                            }
                                        }
                                    >
                                        <FormGroup style={{ marginLeft: 10, width: 250 }}>
                                            <label>Nome do Responsavel</label>
                                            <Input
                                                disabled={true}
                                            value={this.state.dataModal[0].collect_response_name.value}
                                            />
                                        </FormGroup>
                                        <FormGroup style={{ marginLeft: 10, width: 250 }}>
                                            <label>Hora de Coleta</label>
                                            <Input
                                                disabled={true}
                                            value={this.state.dataModal[0].collect_date}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div
                                        style={
                                            {
                                                marginTop: 30,
                                                padding: '0 0 0 10px'
                                            }
                                        }
                                    >
                                        <div>
                                            <h4>Fotos da NF</h4>
                                        </div>
                                        <div
                                            style={
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexWrap: 'wrap'
                                                }
                                            }
                                        >
                                            {this.state.dataModal[0].collect_images.data.map((value: any, index: number) => {
                                                if (value.checklist_form_field.name === 'FOTO DA NF') {
                                                    return (
                                                        <>
                                                            <img
                                                                alt={value.image_source}
                                                                src={value.file_url}
                                                                style={{
                                                                    objectFit: 'cover',
                                                                    borderRadius: '20px',
                                                                    width: '200px',
                                                                    height: '250px'
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                            })}
                                        </div>
                                        <div>
                                            <h4>Fotos do Volume </h4>
                                        </div>
                                        <div
                                            style={
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexWrap: 'wrap'
                                                }
                                            }
                                        >
                                            {this.state.dataModal[0].collect_images.data.map((value: any, index: number) => {
                                                if (value.checklist_form_field.name === 'FOTO VOLUME') {
                                                    return (
                                                        <>
                                                            <img
                                                                alt={value.image_source}
                                                                src={value.file_url}
                                                                style={{
                                                                    objectFit: 'cover',
                                                                    borderRadius: '20px',
                                                                    width: '200px',
                                                                    height: '250px'
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                            })}
                                        </div>
                                        <div>
                                            <h4>Foto da Assinatura </h4>
                                        </div>
                                        <div
                                            style={
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexWrap: 'wrap'
                                                }
                                            }
                                        >
                                            {this.state.dataModal[0].collect_images.data.map((value: any, index: number) => {
                                                if (value.checklist_form_field.name === 'ASSINATURA DO RESPONSÁVEL') {
                                                    return (
                                                        <>
                                                            <img
                                                                alt={value.image_source}
                                                                src={value.file_url}
                                                                style={{
                                                                    objectFit: 'cover',
                                                                    borderRadius: '20px',
                                                                    width: '500px',
                                                                    height: '154px'
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                            >
                                <div
                                    style={
                                        {
                                            display: 'flex',
                                            justifyContent: 'center',

                                        }
                                    }

                                >
                                    <h3>Dados de Entrega</h3>
                                </div>
                                <div>
                                    <div
                                        style={
                                            {
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',

                                            }
                                        }
                                    >
                                        <FormGroup style={{ marginLeft: 10, width: 250 }}>
                                            <label>Nome do Responsavel</label>
                                            <Input
                                                disabled={true}
                                            value={this.state.dataModal[0].delivery_response_name.value}
                                            />
                                        </FormGroup>
                                        <FormGroup style={{ marginLeft: 10, width: 250 }}>
                                            <label>Hora de entrega</label>
                                            <Input
                                                disabled={true}
                                            value={this.state.dataModal[0].delivery_date}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div
                                        style={
                                            {
                                                marginTop: 30,
                                                padding: '0 0 0 10px'
                                            }
                                        }
                                    >
                                        <div>
                                            <h4>Fotos da NF</h4>
                                        </div>
                                        <div
                                            style={
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexWrap: 'wrap'
                                                }
                                            }
                                        >
                                            {this.state.dataModal[0].delivery_images.data.map((value: any, index: number) => {
                                                if (value.checklist_form_field.name === 'FOTO DA NF') {
                                                    return (
                                                        <>
                                                            <img
                                                                alt={value.image_source}
                                                                src={value.file_url}
                                                                style={{
                                                                    objectFit: 'cover',
                                                                    borderRadius: '20px',
                                                                    width: '200px',
                                                                    height: '250px'
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                            })}
                                        </div>
                                        <div>
                                            <h4>Fotos do Volume </h4>
                                        </div>
                                        <div
                                            style={
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexWrap: 'wrap'
                                                }
                                            }
                                        >
                                            {this.state.dataModal[0].delivery_images.data.map((value: any, index: number) => {
                                                if (value.checklist_form_field.name === 'FOTO VOLUME') {
                                                    return (
                                                        <>
                                                            <img
                                                                alt={value.image_source}
                                                                src={value.file_url}
                                                                style={{
                                                                    objectFit: 'cover',
                                                                    borderRadius: '20px',
                                                                    width: '200px',
                                                                    height: '250px'
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                            })}
                                        </div>
                                        <div>
                                            <h4>Foto da Assinatura </h4>
                                        </div>
                                        <div
                                            style={
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexWrap: 'wrap'
                                                }
                                            }
                                        >
                                            {this.state.dataModal[0].delivery_images.data.map((value: any, index: number) => {
                                                if (value.checklist_form_field.name === 'ASSINATURA DO RESPONSÁVEL') {
                                                    return (
                                                        <>
                                                            <img
                                                                alt={value.image_source}
                                                                src={value.file_url}
                                                                style={{
                                                                    objectFit: 'cover',
                                                                    borderRadius: '20px',
                                                                    width: '500px',
                                                                    height: '154px'
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    ) : (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <div>
                                <h2>Opa parece que ainda não existe nenhum dado aqui</h2>
                            </div>
                            <img
                                width={100}
                                height={100}
                                alt="empty"
                                src={empty}
                            />
                        </div>
                    )}
                </>

            </div>
        )
    }


    render(): React.ReactNode {
        return (
            <>
                <CustomModal
                    show={this.state.showModal}
                    title={'Informações de entrega geradas pelo VUUPT'}
                    widthModal={'rs-modal-full'}
                    body={this.renderBodyModal()}
                    showConfirm={false}
                    cancelText={'Fechar'}
                    onCancel={() => this.setState({ showModal: !this.state.showModal })}

                />
                <FormGroup
                    style={
                        {
                            paddingTop: 24
                        }
                    }
                >
                    <Button
                        color="blue"
                        onClick={() => this.setState({ showModal: !this.state.showModal })}
                    >
                        <i className="fa-solid fa-truck-fast"></i> Dados de Entrega VUUPT
                    </Button>
                </FormGroup>
            </>
        )
    }
}