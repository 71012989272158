import { Button, Input, Modal, Table, Notification } from "rsuite";
import axios from "../../../../utilities/axios";
import { useEffect, useState } from "react";

interface ModalErrorsImportProps {
    show: boolean;
    uuid: string;
    onClose: () => void;
}

const ModalErrorImport: React.FC<ModalErrorsImportProps> = ({ show, uuid, onClose }) => {
    const [errors, setErrors] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [observation, setObservation] = useState("");

    const { Column, HeaderCell, Cell } = Table;

    useEffect(() => {
        if (show) {
            axios.get(`/api/v1/check/errors/${uuid}`)
                .then((response) => {
                    setErrors(response.data.errors);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log("erro ao buscar requisição: ", error);
                    setLoading(false);
                });
        }
    }, [show, uuid]);

    const handleSave = async () => {
        const response: boolean = await axios.post(`/api/v1/check/errors/justification/${uuid}`, {
            params: { observation }
        });

        if (response) {
            Notification.success({
                title: "Sucesso!",
                description: "As Observações foram salvas!",
                duration: 3000
            });
            onClose();
        } else {
            Notification.error({
                title: "Erro!",
                description: "Não foi possível salvar as observações",
                duration: 3000
            });
        }
    };

    return (
        <Modal show={show} onHide={onClose} size="lg">
            <Modal.Header>
                <Modal.Title>Eventos ocorridos na Importação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <p>Carregando...</p>
                ) : errors.length === 0 ? (
                    <p>Não foi possível carregar as informações.</p>
                ) : (
                    <div style={{ maxHeight: '300px', overflowX: 'auto', overflowY: 'auto' }}>
                    
                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                            {errors.map((error, index) => (
                                <li key={index} style={{ whiteSpace: 'nowrap' }}>
                                    {error.errorMsg}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div style={{ marginTop: '20px' }}>
                    <label>Observações</label>
                    <Input
                        as="textarea"
                        rows={5}
                        placeholder="Adicione suas observações aqui..."
                        value={observation}
                        onChange={(value) => setObservation(value)}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                {observation.trim() && (
                    <Button onClick={handleSave} appearance="primary">
                        Salvar
                    </Button>
                )}
                <Button onClick={onClose} color="red">
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalErrorImport;