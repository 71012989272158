import React from "react";

export class LoginAnimationSvg extends React.Component<any, {}> {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio={"xMidYMid"} viewBox="0 0 1920 927">
                <g transform="translate(960,463.5) scale(1,1) translate(-960,-463.5)">
                    <linearGradient id="lg-red" x1="0" x2="1" y1="0" y2="0">
                        <stop stopColor="#8B0000" offset="0"></stop> {/* Vermelho escuro */}
                        <stop stopColor="#FF6347" offset="1"></stop> {/* Tom de vermelho mais claro */}
                    </linearGradient>
                    
                    <path d="" fill="url(#lg-red)" opacity="0.15">
                        <animate
                            attributeName="d"
                            dur="33.333s"
                            repeatCount="indefinite"
                            keyTimes="0;0.333;0.667;1"
                            calcMode="spline"
                            keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                            begin="0s"
                            values="M0 0L 0 778.935Q 192 668.408  384 636.052T 768 465.459T 1152 354.997T 1536 225.568T 1920 104.115L 1920 0 Z;M0 0L 0 857.609Q 192 796.285  384 770.204T 768 507.114T 1152 337.441T 1536 274.266T 1920 63.845L 1920 0 Z;M0 0L 0 831.056Q 192 662.909  384 639.732T 768 604.381T 1152 520.174T 1536 377.833T 1920 177.349L 1920 0 Z;M0 0L 0 778.935Q 192 668.408  384 636.052T 768 465.459T 1152 354.997T 1536 225.568T 1920 104.115L 1920 0 Z"
                        ></animate>
                    </path>

                    <path d="" fill="url(#lg-red)" opacity="0.2">
                        <animate
                            attributeName="d"
                            dur="33.333s"
                            repeatCount="indefinite"
                            keyTimes="0;0.333;0.667;1"
                            calcMode="spline"
                            keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                            begin="-6.666s"
                            values="M0 0L 0 909.927Q 192 675.785  384 652.622T 768 524.163T 1152 430.395T 1536 332.597T 1920 197.201L 1920 0 Z;M0 0L 0 875.579Q 192 741.635  384 723.971T 768 610.631T 1152 408.586T 1536 305.571T 1920 267.982L 1920 0 Z;M0 0L 0 788.694Q 192 754.410  384 722.167T 768 555.987T 1152 331.029T 1536 200.099T 1920 63.848L 1920 0 Z;M0 0L 0 909.927Q 192 675.785  384 652.622T 768 524.163T 1152 430.395T 1536 332.597T 1920 197.201L 1920 0 Z"
                        ></animate>
                    </path>

                    <path d="" fill="url(#lg-red)" opacity="0.25">
                        <animate
                            attributeName="d"
                            dur="33.333s"
                            repeatCount="indefinite"
                            keyTimes="0;0.333;0.667;1"
                            calcMode="spline"
                            keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                            begin="-13.333s"
                            values="M0 0L 0 871.519Q 192 735.001  384 697.727T 768 508.824T 1152 443.988T 1536 341.133T 1920 192.292L 1920 0 Z;M0 0L 0 789.281Q 192 829.264  384 783.268T 768 613.848T 1152 492.669T 1536 306.167T 1920 222.157L 1920 0 Z;M0 0L 0 871.386Q 192 780.318  384 763.804T 768 617.280T 1152 421.279T 1536 274.173T 1920 155.398L 1920 0 Z;M0 0L 0 871.519Q 192 735.001  384 697.727T 768 508.824T 1152 443.988T 1536 341.133T 1920 192.292L 1920 0 Z"
                        ></animate>
                    </path>

                    <path d="" fill="url(#lg-red)" opacity="0.3">
                        <animate
                            attributeName="d"
                            dur="33.333s"
                            repeatCount="indefinite"
                            keyTimes="0;0.333;0.667;1"
                            calcMode="spline"
                            keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                            begin="-20s"
                            values="M0 0L 0 926.398Q 192 651.946  384 621.460T 768 528.790T 1152 506.903T 1536 256.503T 1920 190.613L 1920 0 Z;M0 0L 0 761.084Q 192 693.190  384 663.798T 768 561.527T 1152 427.630T 1536 365.776T 1920 238.021L 1920 0 Z;M0 0L 0 829.722Q 192 817.601  384 789.881T 768 479.058T 1152 352.056T 1536 339.472T 1920 92.761L 1920 0 Z;M0 0L 0 926.398Q 192 651.946  384 621.460T 768 528.790T 1152 506.903T 1536 256.503T 1920 190.613L 1920 0 Z"
                        ></animate>
                    </path>

                    <path d="" fill="url(#lg-red)" opacity="0.35">
                        <animate
                            attributeName="d"
                            dur="33.333s"
                            repeatCount="indefinite"
                            keyTimes="0;0.333;0.667;1"
                            calcMode="spline"
                            keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                            begin="-26.666s"
                            values="M0 0L 0 778.601Q 192 666.784  384 631.202T 768 548.031T 1152 332.643T 1536 300.702T 1920 119.833L 1920 0 Z;M0 0L 0 768.972Q 192 708.554  384 692.477T 768 603.278T 1152 380.195T 1536 336.318T 1920 103.012L 1920 0 Z;M0 0L 0 836.647Q 192 772.153  384 744.253T 768 472.046T 1152 337.481T 1536 209.695T 1920 78.796L 1920 0 Z;M0 0L 0 778.601Q 192 666.784  384 631.202T 768 548.031T 1152 332.643T 1536 300.702T 1920 119.833L 1920 0 Z"
                        ></animate>
                    </path>
                </g>
            </svg>
        );
    }
}
