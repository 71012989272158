import { Event } from "../Event";

export class DigitalAdmissionCandidateIndicatedEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState("loadings", ["digital_admission_candidate_contact_name"])

        if(value) {
            this.setField("digital_admission_candidate_contact_name", {
                required: true,
                readonly: false
            })
        } else {
            this.setField("digital_admission_candidate_contact_name", {
                required: false,
                readonly: true
            })

            this.setFieldValue("digital_admission_candidate_contact_name", "")

        }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 250)
    }
}