import React from "react";
import { withRouter } from "react-router-dom";
import { Event } from "../Event";
import { Button } from "rsuite";
import axios from "../../utilities/axios";
import { UserContext } from "../../components/authProvider/main";

type State = {
    loading: boolean;
    formValues: Record<string, any>;
}

class FunctionFooterComponent extends React.Component<any, State> {
    static contextType?: React.Context<any> | undefined = UserContext;

    state = {
        loading: false,
        formValues: this.props.values.formValues
    }

    reloadPage = () => {
        this.props.history.go(0);
    }


    render(): React.ReactNode {
        const user = this.context.data;

        return (
            <div
                style={
                    {
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 8,
                        marginLeft: 8
                    }
                }
            >

            </div>
        )
    }
}

const FunctionFooterWithRouter = withRouter(FunctionFooterComponent);

export class FunctiontFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <FunctionFooterWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    };
}