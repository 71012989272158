import { Event } from "../Event";
import axios from "../../utilities/axios";

import { Notification } from "rsuite";

export class DigitalAdmissionFkJobRequestIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => { 
        if(value) {
            const response = await axios.get("/api/v1/digital-admisison/check-code/" + Number(value))

            const isValidId = response.data;

            if(!isValidId){
                Notification.error({
                    title: "Erro",
                    description: "Ja existe um processo de admissão para esta vaga!"
                })
            }
        }
    }
}