import _, { size } from "lodash";
import React from "react";
import { Button, InputGroup, Input, Tag, Notification } from "rsuite";
import axios from "../../../../utilities/axios";

export class CompanyEmailTest extends React.Component<any, {}> {



    render() {
        return (
            <div>
                <Input
                    type="text"
                    placeholder="Digite os emails para mandar a notificação"
                    onChange={(value) => {
                        Notification.success({
                            ola: 'olá'
                        })
                    }}
                />
            </div>
        );
    }
}