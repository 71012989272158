import { Event } from "../Event";

export class UserSelectCompaniesEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {

        const isManager = this.values?.user_is_manager

        if (value) {
            this.setState("loadings", ["user_fk_base_id"])

            this.setField("user_fk_base_id", {
                readonly: false,
                required: true
            })

            if (!isManager) {
                this.setFieldValue("user_fk_base_id", undefined)
            }

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        } else {
            this.setState("loadings", ["user_fk_base_id"])

            this.setField("user_fk_base_id", {
                readonly: true,
                required: false
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }
    }
}