import { read } from "fs";
import { Event } from "../Event";
export class NationalHolidayEvents extends Event {

  public onLoad = async () => {
    const url = this.globalProps.match.url

    if (url.includes('/view')) {
        this.setField("national_holiday_type_n", { hidden: true })
        this.setField("national_holiday_level_n", { hidden: true })

        this.setField("national_holiday_type", { hidden: false, order: 2, readonly: true })
        this.setField("national_holiday_level", { hidden: false, order: 3, readonly: true })
        
        this.setField("national_holiday_name", {readonly: true})
        this.setField("national_holiday_date", {readonly: true})
        this.setField("national_holiday_active", {readonly: true});
    }

     setTimeout(() =>{
        this.setState("loadings", [])
     },200)
}

}