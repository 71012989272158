import { Event } from "../Event";
import dayjs from "dayjs";

export class DisciplinaryMeasureFinalDateEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        const initialDate = this.values.disciplinary_measure_initial_date;
        const finalDate = this.values.disciplinary_measure_final_date;

        if (initialDate && finalDate) {
            this.setState("loadings", ["disciplinary_measure_quantity_day"])

            const initial = dayjs(initialDate);
            const final = dayjs(finalDate);

            const daysDiference = final.diff(initial, 'day');

            let fieldText = ''

            if(daysDiference == 1) {
                fieldText = String(daysDiference) + " DIA";
            } else {
                fieldText = String(daysDiference) + " DIAS";
            }
            
            this.setFieldValue("disciplinary_measure_quantity_day", fieldText)

            setTimeout(() => {
                this.setState("loadings", [])
            }, 300)
        }
    }

}