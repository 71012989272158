import { Event } from "../Event";

export class HolidaySolicitationFuncionaryFkUserIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {

        if (value) {

            this.setState("loadings", ["holiday_solicitation_acquisition_periods"])

            // console.log("Entrei aqui!!!!!!!!!!!!!!!!!!!!!!!!!!!")
            this.setField("holiday_solicitation_acquisition_periods", {
                hidden: false
            })

            setTimeout(() => { this.setState("loadings", []) }, 350)
        }
    }
}